import React, { useEffect } from "react";
import HireFromUsHero from "../hireFromUsPage/HireFromUsHero";
import WhyHireFromKodu from "../hireFromUsPage/WhyHireFromKodu";
import OurStudents from "../hireFromUsPage/OurStudents";
import Ecosystem from "../hireFromUsPage/Ecosystem";
import RecruitmentSpecialist from "../hireFromUsPage/RecruitmentSpecialist";
import Unmatched from "../hireFromUsPage/Unmatched";

const HireFromUsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <HireFromUsHero />
      <WhyHireFromKodu />
      <Unmatched />
      <OurStudents />
      <Ecosystem />
      <RecruitmentSpecialist />
    </div>
  );
};

export default HireFromUsPage;
