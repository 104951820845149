import React from "react";

const RecruitmentSpecialist = () => {
  return (
    <div className="py-5">
      <div className="container">
        <h3 className=" text-center fs_37 fw-bold ff_heebo color_blue">
          <span className=" color_yellow">
            Talk to our<br></br>
          </span>
          Recruitment Specialist
        </h3>
        <p className=" text-center fs_17 ff_heebo text-black mt-2">
          If you're looking for guidance on the recruitment manner,
          <br className="d-xl-block d-none"></br> kindly share your phone number
          and email . Our committed Recruitment Specialist will
          <br className="d-xl-block d-none"></br> connect to you to offer
          complete support and techniques tailor-made to attract{" "}
          <br className="d-xl-block d-none"></br>top expertise in your
          technology organisation.
        </p>
        <div className=" d-flex justify-content-center mt-4">
          <button className=" fs_15 px-5 ff_heebo fw-medium color_lightwhite Free_btn border-0 mt-sm-1">
            Apply Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default RecruitmentSpecialist;
