import React, { useEffect, useState } from "react";
import Navbar from "../common/Navbar";
import { Link, useLocation, useParams } from "react-router-dom";
import makeApiCall from "../callApi";
import { Button, Modal } from "react-bootstrap";
import logo from "../../assets/images/svg/logo.svg";

const QuizTest = () => {
  const [answers, setAnswers] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [scholarshipShowModal, setScholarshipShowModal] = useState(false);
  const { type } = useParams();
  const [allQuestion, setAllQuestion] = useState([]);
  const [testId, setTestId] = useState();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);

  const { id } = useParams();

  const handlePlayNow = (id) => {
    makeApiCall({ type: type, id }, "post", "questions", (response) => {
      if (response.status === 200) {
        setAllQuestion(response.data.data);
        setTestId(response.data.test_id);
        console.log(response.data.test_id);
        console.log(response.data.data);
      } else {
        console.log("Error fetching questions");
      }
    });
  };
  useEffect(() => {
    console.log(type);
    handlePlayNow(id);
  }, [id]);

  const location = useLocation();
  const questions = location.state?.questions || allQuestion;
  console.log(questions);

  const handleNext = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  const handlePrev = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handalOpneModal = () => {
    // alert(questions);
    // alert(JSON.stringify(answers));
    let newScore = 0;
    questions.forEach((questionData, index) => {
      if (answers[index] === questionData.answer) {
        newScore++;
      }
    });
    setScore(newScore);
    // alert(newScore);
    setShowModal(!showModal);
  };

  const handleAnswerSelect = (questionIndex, selectedAnswer) => {
    setAnswers({
      ...answers,
      [questionIndex]: selectedAnswer,
    });
  };
  // ---------------
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    const storedPhoneNumber = localStorage.getItem("phoneNumber");
    if (storedPhoneNumber) {
      setPhoneNumber(storedPhoneNumber);
    }
  }, []);

  const submitScholler = () => {
    // alert("Submitting test...");
    // // Make API call with phone number and score
    let newScore = 0;
    questions.forEach((questionData, index) => {
      if (answers[index] === questionData.answer) {
        newScore++;
      }
    });
    setScore(newScore);
    console.log(newScore);
    setScholarshipShowModal(true);
    makeApiCall(
      {
        phone: phoneNumber,
        result: newScore,
        test_id: testId,
      },
      "post",
      "submit_test",
      (response) => {
        if (response.status === 200) {
          // Handle successful response, if needed
          console.log(response.data);
        } else {
          console.log("Error submitting test");
        }
      }
    );
  };

  return (
    <div>
      <Navbar />
      <div className="container py-4">
        {questions.length < 1 ? (
          <div className="text-center">
            <strong className="fs-4 color_yellow">No data found</strong>
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-between align-items-center">
              <strong className="fs-4 color_yellow">
                Question {currentQuestion + 1}
              </strong>
              <button
                onClick={
                  type == "scholarship" ? submitScholler : handalOpneModal
                }
                className="px-4 py-2 bg_darkyellow border-0 rounded-1 text-white"
              >
                Submit Test
              </button>
            </div>
            <div className="border my-3"></div>

            <div>
              <div className="d-flex align-items-center gap-3">
                <p className="mb-0 fw-bold">
                  {questions[currentQuestion]?.question}
                </p>
              </div>
              <div className="mt-4">
                <label className="radio_container mt-4">
                  &nbsp; <span>{questions[currentQuestion]?.option_a}</span>
                  <input
                    type="radio"
                    name="type"
                    onChange={() =>
                      handleAnswerSelect(
                        currentQuestion,
                        questions[currentQuestion]?.option_a
                      )
                    }
                    checked={
                      answers[currentQuestion] ===
                      questions[currentQuestion]?.option_a
                    }
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="radio_container mt-4">
                  &nbsp; <span>{questions[currentQuestion]?.option_b}</span>
                  <input
                    type="radio"
                    name="type"
                    onChange={() =>
                      handleAnswerSelect(
                        currentQuestion,
                        questions[currentQuestion]?.option_b
                      )
                    }
                    checked={
                      answers[currentQuestion] ===
                      questions[currentQuestion]?.option_b
                    }
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="radio_container mt-4">
                  &nbsp; <span>{questions[currentQuestion]?.option_c}</span>
                  <input
                    type="radio"
                    name="type"
                    onChange={() =>
                      handleAnswerSelect(
                        currentQuestion,
                        questions[currentQuestion]?.option_c
                      )
                    }
                    checked={
                      answers[currentQuestion] ===
                      questions[currentQuestion]?.option_c
                    }
                  />
                  <span className="checkmark"></span>
                </label>
                <label className="radio_container mt-4">
                  &nbsp; <span>{questions[currentQuestion]?.option_d}</span>
                  <input
                    type="radio"
                    name="type"
                    onChange={() =>
                      handleAnswerSelect(
                        currentQuestion,
                        questions[currentQuestion]?.option_d
                      )
                    }
                    checked={
                      answers[currentQuestion] ===
                      questions[currentQuestion]?.option_d
                    }
                  />
                  <span className="checkmark"></span>
                </label>
                {/* <label className="radio_container mt-4">
                  &nbsp; <span>{questions[currentQuestion]?.option_e}</span>
                  <input
                    type="radio"
                    name="type"
                    onChange={() =>
                      handleAnswerSelect(
                        currentQuestion,
                        questions[currentQuestion]?.option_e
                      )
                    }
                    checked={
                      answers[currentQuestion] ===
                      questions[currentQuestion]?.option_e
                    }
                  />
                  <span className="checkmark"></span>
                </label> */}
              </div>
            </div>

            <div className="d-flex gap-4 mt-5 justify-content-between align-items-center">
              {currentQuestion > 0 && (
                <button
                  onClick={handlePrev}
                  className="px-4 py-2 bg_darkyellow border-0 rounded-1 text-white"
                >
                  Previous
                </button>
              )}
              {currentQuestion === 0 && (
                <button
                  disabled
                  className="px-4 py-2 bg_darkyellow border-0 rounded-1 text-white"
                >
                  Previous
                </button>
              )}
              {currentQuestion < questions.length - 1 && (
                <button
                  className="px-4 py-2 bg_darkyellow border-0 rounded-1 text-white"
                  onClick={handleNext}
                >
                  Next
                </button>
              )}
              {currentQuestion === questions.length - 1 && (
                <button
                  disabled
                  className="px-4 py-2 bg_darkyellow border-0 rounded-1 text-white"
                >
                  Next
                </button>
              )}
            </div>
          </>
        )}
      </div>
      <div className="bg_darkblue">
        <Modal centered show={showModal} onHide={() => setShowModal(false)}>
          <div
            style={{ maxWidth: "500px", margin: "0 auto" }}
            className="bg_darkblue rounded-3 w-100"
          >
            <Modal.Header className="border-0 p-4 ">
              <Modal.Title className="text-white w-100 d-flex justify-content-between align-items-center">
                <img src={logo} alt="logo" />
                <h4 className="mb-0"> Result</h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="border-2 py-4 border-start-0 border-end-0 border w-100">
              <div className="d-flex justify-content-between  align-items-center px-4">
                <h4 className=" color_yellow  mb-0">Your score is : </h4>
                <h4 className=" text-white mb-0">
                  {score}/{questions.length}
                </h4>
              </div>
            </Modal.Body>
            <Modal.Footer className="border-0 p-4 mt-3">
              <Link to={"/quiz-page"}>
                <button
                  className="bg_yellow px-4 rounded-2 py-2 text-white fw-semibold ff_heebo border-0"
                  onClick={() => setShowModal(false)}
                >
                  Close Test
                </button>
              </Link>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
      <div className="bg_darkblue">
        <Modal centered show={scholarshipShowModal}>
          <div
            style={{ width: "500px", margin: "0 auto" }}
            className="bg_darkblue rounded-3"
          >
            <Modal.Header className="border-0 p-4">
              <Modal.Title className="text-white w-100 d-flex justify-content-between align-items-center">
                <img src={logo} alt="logo" />
                <h4 className="mb-0">
                  <span className=" color_yellow">Scholarship </span> Result
                </h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="border-2 py-4  border-start-0 border-end-0 border w-100">
              <div className=" px-2">
                <h4 className="color_yellow mb-2 fs-5 ">
                  Thank you for taking the Scholarship Test!
                </h4>
                <p className="text-white mb-1">
                  Your submission has been recorded.
                </p>
                <p className="text-white mb-0">
                  Our Counsellor's will reach you soon.
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer className="border-0 p-4 mt-3">
              <Link to={"/scholarshipPage-page"}>
                <button
                  className="bg_yellow px-4 rounded-2 py-2 text-white fw-semibold ff_heebo border-0"
                  onClick={() => setScholarshipShowModal(false)}
                >
                  Close Test
                </button>
              </Link>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default QuizTest;
