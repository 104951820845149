import React, { useEffect } from "react";
import WebinarsHero from "../webinarsPage/WebinarsHero";
import UpcomingLive from "../webinarsPage/UpcomingLive";
import PastWebinar from "../webinarsPage/PastWebinar";

const WebinarsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <WebinarsHero />
      <UpcomingLive />
      <PastWebinar />
    </div>
  );
};

export default WebinarsPage;
