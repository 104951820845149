import React, { useEffect } from "react";
import AboutHero from "../aboutPage/AboutHero";
import AboutSlider from "../aboutPage/AboutSlider";

const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <AboutHero />
      <AboutSlider />
    </div>
  );
};

export default AboutPage;
