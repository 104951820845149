import React, { useCallback, useEffect, useState } from "react";
import makeApiCall from "../callApi";

const LeadsTab = () => {
  const [leadsList, setLeadsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalLeads, setTotalLeads] = useState(0);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const leadPerPage = 10;

  async function getLead(page) {
    await makeApiCall(
      {
        page: page,
        limit: leadPerPage,
      },
      "post",
      "admin/leads",
      leadsListCallback,
      false
    );
  }

  const leadsListCallback = useCallback((response) => {
    console.log(response);
    if (response.status === 200) {
      setLeadsList(response.data.data);
      setTotalLeads(response.data.total);
      // setTotalPages(Math.ceil(response.data.total / leadPerPage));
      //   setLimit(response.data.limit);
      //   setPage(response.data.page);
      console.log(response);
    } else {
      console.log("Failed to fetch users");
    }
  }, []);

  useEffect(() => {
    getLead(currentPage);
  }, [currentPage]);
  const convertDate = (date) => {
    const res = new Date(date).toDateString();
    const res2 = new Date(date).toLocaleTimeString();
    return { date: res, time: res2 };
  };
  return (
    <div>
      <h2 className="fs_37 fw-semibold ff_heebo">Leads Data</h2>
      <div className="border my-4"></div>
      <div className="border-0 ff_heebo pb-4">
        <div
          className="card rounded-3"
          style={{ maxWidth: "1300px", overflowX: "auto" }}
        >
          <table className="w-100">
            <thead>
              <tr>
                <th className="py-3 text-white text-nowrap ff_heebo px-3 bg_darkblue">
                  No.
                </th>
                <th className="py-3 text-white text-nowrap ff_heebo px-3 bg_darkblue">
                  Name
                </th>
                <th className="py-3 text-white text-nowrap ff_heebo px-3 bg_darkblue">
                  Phone Number
                </th>
                <th className="py-3 text-white text-nowrap ff_heebo px-3 bg_darkblue">
                  City
                </th>
                <th className="py-3 text-white text-nowrap  ff_heebo px-3 bg_darkblue">
                  Nearest Center
                </th>
                <th className="py-3 text-white text-nowrap ff_heebo px-3 bg_darkblue">
                  Email
                </th>
                <th className="py-3 text-white text-nowrap ff_heebo px-3 bg_darkblue">
                  Course
                </th>
                <th className="py-3 text-white text-nowrap ff_heebo px-3 bg_darkblue">
                  Qualification
                </th>
                <th className="py-3 text-white text-nowrap ff_heebo px-3 bg_darkblue">
                  Date/Time
                </th>
                <th className="py-3 text-white ff_heebo px-3 bg_darkblue">
                  BatchType
                </th>
              </tr>
            </thead>
            <tbody>
              {leadsList.length === 0 ? (
                <tr>
                  <td className="px-3 py-4" colSpan="5">
                    No data found
                  </td>
                </tr>
              ) : (
                leadsList.map((user, index) => {
                  const converteddate = convertDate(user.createdAt);
                  return (
                    <>
                      <tr key={user.id}>
                        <td className="px-3 py-3">
                          {(currentPage - 1) * leadPerPage + index + 1}
                        </td>
                        <td className="px-3 py-3 text-nowrap">{user.name}</td>
                        <td className="px-3 py-3 text-nowrap">{user.phone}</td>
                        <td className="px-3 py-3 text-nowrap">{user.topic}</td>
                        <td className="px-3 py-3 text-nowrap">
                          {user.nearestCenter}
                        </td>
                        <td className="px-3 py-3 text-nowrap">{user.email}</td>
                        <td className="px-3 py-3 text-nowrap">{user.course}</td>
                        <td className="px-3 py-3 text-nowrap">
                          {user.qualification}
                        </td>
                        <td className="px-3 py-3 text-nowrap">
                          {converteddate?.date} {converteddate?.time}
                        </td>
                        <td className="px-3 py-3 text-nowrap">
                          {user.batchType}
                        </td>
                      </tr>
                    </>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default LeadsTab;
