import React, { useEffect } from "react";
import ContactUsHero from "../ContactUsPage/ContactUsHero";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import { OurCentres } from "../ContactUsPage/OurCentres";

const ContactUsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <ContactUsHero />
      {/* <OurCentres/> */}
    </div>
  );
};

export default ContactUsPage;
