import React from "react";
import star_img from "../../assets/images/png/star1.png";

const AboutComponent = () => {
  return (
    <div className="py-5 bg_lightpink">
      <div className="container py-4">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-2 col-md-3 col-sm-6 mt-4">
            <div className="d-flex align-items-center gap-3">
              <img width={50} src={star_img} alt="star_img" />
              <div>
                <h5 className=" mb-0">4.8</h5>
                <p className=" mb-0">rating</p>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-6 mt-4">
            <div className="d-flex align-items-center gap-3">
              <img width={50} src={star_img} alt="star_img" />
              <div>
                <h5 className=" mb-0">4000+</h5>
                <p className=" mb-0">students</p>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-6 mt-4">
            <div className="d-flex align-items-center gap-3">
              <img width={50} src={star_img} alt="star_img" />
              <div>
                <h5 className=" mb-0">1 time</h5>
                <p className=" mb-0">payment</p>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-6 mt-4">
            <div className="d-flex align-items-center gap-3">
              <img width={50} src={star_img} alt="star_img" />
              <div>
                <h5 className=" mb-0">Anyone</h5>
                <p className=" mb-0">can do (IT/NonIT)</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-md-5 align-items-center justify-content-center">
          <div className="col-lg-2 col-md-3 col-sm-6 mt-4">
            <div className="d-flex align-items-center gap-3">
              <img width={50} src={star_img} alt="star_img" />
              <div>
                <h5 className=" mb-0">Simple English</h5>
                <p className=" mb-0">Language</p>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-6 mt-4">
            <div className="d-flex align-items-center gap-3">
              <img width={50} src={star_img} alt="star_img" />
              <div>
                <h5 className=" mb-0">55 hrs</h5>
                <p className=" mb-0">content duration</p>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-6 mt-4">
            <div className="d-flex align-items-center gap-3">
              <img width={50} src={star_img} alt="star_img" />
              <div>
                <h5 className=" mb-0">Lifetime</h5>
                <p className=" mb-0">course access</p>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-6 mt-4">
            <div className="d-flex align-items-center gap-3">
              <img width={50} src={star_img} alt="star_img" />
              <div>
                <h5 className=" mb-0">Self- paced</h5>
                <p className=" mb-0">recorded lectures</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutComponent;
