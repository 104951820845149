import React from "react";
import Slider from "react-slick";
import teacherImage1 from "../../assets/images/png/top_mentor_image1.png";
import teacherImage2 from "../../assets/images/png/top_mentor_image2.png";
import teacherImage3 from "../../assets/images/png/top_mentor_image3.png";
import teacherImage4 from "../../assets/images/png/top_mentor_image4.png";
import teacherImage5 from "../../assets/images/png/top_mentor_image5.png";
import teacherImage6 from "../../assets/images/png/top_mentor_image6.png";
import teacherImage7 from "../../assets/images/png/top_mentor_image7.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function AboutSlider() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className="mt-134">
      <div className="my-5">
        <div className="container">
          <h1 className="ff_heebo fs_24 mb-5 fw-bold">Learn from Top Mentor</h1>
        </div>

        <div className="py-3 overflow-hidden">
          <Slider {...settings}>
            <div className="w-100 custom_slider_hight px-lg-3 px-1">
              <div className="centerSiderItem p-4 w-100">
                <div className="rounded p-3 ps-0 d-flex gap-2">
                  <div>
                    <img
                      src={teacherImage1}
                      width={50}
                      height={50}
                      className="rounded"
                      alt="teacher"
                    />
                  </div>
                  <div>
                    <div>
                      <span className=" color_yellow ff_heebo fs_17 fw-bolder">
                        Deepanshu Sir
                      </span>
                    </div>
                    <div>
                      <span className="text-dark ff_heebo fs_12">CEO</span>
                    </div>
                  </div>
                </div>
                <div>
                  {/* <h1 className="ff_heebo fs_34 fw-bold color_blue">
                    About Mentors
                  </h1> */}
                  <p className="ff_heebo fs_14">
                    As the CEO of the company, Deepanshu has steered the
                    organization towards remarkable growth and innovation,
                    leveraging his visionary leadership and strategic acumen.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-100 custom_slider_hight px-lg-3 px-1">
              <div className="centerSiderItem p-4 w-100">
                <div className="rounded p-3 ps-0 d-flex gap-2">
                  <div>
                    <img
                      src={teacherImage2}
                      width={50}
                      height={50}
                      className="rounded"
                      alt="teacher"
                    />
                  </div>
                  <div>
                    <div>
                      <span className=" color_yellow ff_heebo fs_17 fw-bolder">
                        Ankita Verma
                      </span>
                    </div>
                    <div>
                      <span className="text-dark ff_heebo fs_12">
                        Sr. Academic Trainer
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  {/* <h1 className="ff_heebo fs_34 fw-bold color_blue">
                    About Mentors
                  </h1> */}
                  <p className="ff_heebo fs_14">
                    Ankita Verma serves as a dedicated Sr. Academic Trainer,
                    bringing extensive experience and expertise to our
                    educational programs. With a passion for nurturing young
                    minds, Ankita plays a crucial role in shaping the academic
                    journey of our students.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-100 custom_slider_hight px-lg-3 px-1">
              <div className="centerSiderItem p-4 w-100">
                <div className="rounded p-3 ps-0 d-flex gap-2">
                  <div>
                    <img
                      src={teacherImage3}
                      width={50}
                      height={50}
                      className="rounded"
                      alt="teacher"
                    />
                  </div>
                  <div>
                    <div>
                      <span className=" color_yellow ff_heebo fs_17 fw-bolder">
                        Ajay Sir
                      </span>
                    </div>
                    <div>
                      <span className="text-dark ff_heebo fs_12">
                        Sr. Academic Trainer
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  {/* <h1 className="ff_heebo fs_34 fw-bold color_blue">
                    About Mentors
                  </h1> */}
                  <p className="ff_heebo fs_14">
                    Ajay Sir brings his wealth of experience as a Sr. Academic
                    Trainer, enriching our educational programs with his
                    expertise. His dedication to student success and his
                    innovative teaching methods make him an invaluable asset to
                    our team.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-100 custom_slider_hight px-lg-3 px-1">
              <div className="centerSiderItem p-4 w-100">
                <div className="rounded p-3 ps-0 d-flex gap-2">
                  <div>
                    <img
                      src={teacherImage4}
                      width={50}
                      height={50}
                      className="rounded"
                      alt="teacher"
                    />
                  </div>
                  <div>
                    <div>
                      <span className=" color_yellow ff_heebo fs_17 fw-bolder">
                        Surbhi Ma'am
                      </span>
                    </div>
                    <div>
                      <span className="text-dark ff_heebo fs_12">
                        App Developer
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  {/* <h1 className="ff_heebo fs_34 fw-bold color_blue">
                    About Mentors
                  </h1> */}
                  <p className="ff_heebo fs_14">
                    Surbhi Ma'am is a proficient App Developer, contributing her
                    expertise and creativity to our projects. With her keen eye
                    for detail and passion for innovation, Surbhi enhances the
                    user experience of our applications, ensuring their success
                    in the digital landscape.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-100 custom_slider_hight px-lg-3 px-1">
              <div className="centerSiderItem p-4 w-100">
                <div className="rounded p-3 ps-0 d-flex gap-2">
                  <div>
                    <img
                      src={teacherImage5}
                      width={50}
                      height={50}
                      className="rounded"
                      alt="teacher"
                    />
                  </div>
                  <div>
                    <div>
                      <span className=" color_yellow ff_heebo fs_17 fw-bolder">
                        Pradeep Sir
                      </span>
                    </div>
                    <div>
                      <span className="text-dark ff_heebo fs_12">
                        App Developer
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  {/* <h1 className="ff_heebo fs_34 fw-bold color_blue">
                    About Mentors
                  </h1> */}
                  <p className="ff_heebo fs_14">
                    Pradeep Sir is a skilled App Developer, bringing years of
                    experience and innovation to our team. His expertise in app
                    development ensures that our projects are built with
                    precision and excellence, meeting the highest standards of
                    quality.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-100 custom_slider_hight px-lg-3 px-1">
              <div className="centerSiderItem p-4 w-100">
                <div className="rounded p-3 ps-0 d-flex gap-2">
                  <div>
                    <img
                      src={teacherImage6}
                      width={50}
                      height={50}
                      className="rounded"
                      alt="teacher"
                    />
                  </div>
                  <div>
                    <div>
                      <span className=" color_yellow ff_heebo fs_17 fw-bolder">
                        Seema Ma’am
                      </span>
                    </div>
                    <div>
                      <span className="text-dark ff_heebo fs_12">
                        Sr. Academic Trainer
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  {/* <h1 className="ff_heebo fs_34 fw-bold color_blue">
                    About Mentors
                  </h1> */}
                  <p className="ff_heebo fs_14">
                    Seema Ma'am is a dedicated Sr. Academic Trainer, leveraging
                    her extensive experience and passion for teaching to guide
                    our students towards academic excellence. Her commitment to
                    student success and innovative teaching methods make her an
                    invaluable asset to our educational programs.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-100  custom_slider_hight px-lg-3 px-1">
              <div className="centerSiderItem p-4 w-100">
                <div className="rounded p-3 ps-0 d-flex gap-2">
                  <div>
                    <img
                      src={teacherImage7}
                      width={50}
                      height={50}
                      className="rounded"
                      alt="teacher"
                    />
                  </div>
                  <div>
                    <div>
                      <span className=" color_yellow ff_heebo fs_17 fw-bolder">
                        Rohit Sir
                      </span>
                    </div>
                    <div>
                      <span className="text-dark ff_heebo fs_12">
                        Software Engineer
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  {/* <h1 className="ff_heebo fs_34 fw-bold color_blue">
                    About Mentors
                  </h1> */}
                  <p className="ff_heebo fs_14">
                    Rohit Sir is a proficient Software Engineer, bringing
                    extensive experience and technical expertise to our
                    projects. His dedication to software development and
                    problem-solving skills ensure the success of our endeavors.
                    With his keen attention to detail and innovative approach,
                    Rohit contributes significantly to the development and
                    implementation of robust software solutions, meeting the
                    highest standards of quality and efficiency.
                  </p>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default AboutSlider;
