import React, { useEffect } from "react";
import SuccessStoriesDetailsHero from "../successstoriesdetalisPage/SuccessStoriesDetailsHero";
import Navbar from "../common/Navbar";
import MoreStories from "../successstoriesdetalisPage/MoreStories";

const SuccessStoriesDetailsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <SuccessStoriesDetailsHero />
      <MoreStories />
    </div>
  );
};

export default SuccessStoriesDetailsPage;
