import React, { useState } from "react";
import CareerServices_image from "../../assets/images/png/CareerServices_image.png";
import head_phone from "../../assets/images/png/head_phone.png";

const CareerServices = () => {
  const [selectedButton, setSelectedButton] = useState(1);
  const [selectedData, setSelectedData] = useState(null);

  const handleButtonClick = (buttonId, newData) => {
    setSelectedButton(buttonId);
    setSelectedData(newData);
  };
  return (
    <div className="py-5 bg_lightblue">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-7">
            <h4 className=" fs_29 ff_heebo fw-medium text-black">
              Career services By Kodu
            </h4>
            <div className="row">
              <div className="col-sm-4 mt-4">
                <button
                  className={`w-100 border-1 py-2 rounded-2 fs_16 ff_heebo fw-medium ${
                    selectedButton === 1
                      ? "bg_darkblue color_lightwhite"
                      : "bg-transparent color_darkgray"
                  }`}
                  onClick={() => handleButtonClick(1)}
                >
                  Job Assistance
                </button>
              </div>
              <div className="col-sm-4 mt-4">
                <button
                  className={`w-100 border-1 py-2 rounded-2 fs_16 ff_heebo fw-medium ${
                    selectedButton === 2
                      ? "bg_darkblue color_lightwhite"
                      : "bg-transparent color_darkgray"
                  }`}
                  onClick={() => handleButtonClick(2)}
                >
                  Interview Preparation
                </button>
              </div>
              <div className="col-sm-4 mt-4">
                <button
                  className={`w-100 border-1 py-2 rounded-2 fs_16 ff_heebo fw-medium ${
                    selectedButton === 3
                      ? "bg_darkblue color_lightwhite"
                      : "bg-transparent color_darkgray"
                  }`}
                  onClick={() => handleButtonClick(3)}
                >
                  Profile Building
                </button>
              </div>
            </div>
            {selectedButton == 1 ? (
              <>
                <div className="d-flex align-items-start gap-3 mt-5">
                  <img src={head_phone} alt="head_phone" />
                  <div>
                    <p className=" fs_15 ff_heebo color_darkgray">
                      Kodu understands that securing a job after completing a
                      course is crucial for students' career advancement. That's
                      why we offer comprehensive job assistance services. This
                      includes helping students identify job opportunities that
                      align with their skills and career goals, providing
                      guidance on crafting effective resumes and cover letters
                      tailored to specific job roles, and facilitating
                      connections with potential employers through job fairs,
                      networking events, and industry partnerships. Kodu's
                      dedicated team works closely with students to support them
                      throughout the job search process, from identifying
                      suitable job openings to preparing for interviews and
                      negotiating job offers.
                    </p>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            <div>
              {selectedButton == 2 ? (
                <>
                  <div className="d-flex align-items-start gap-3 mt-5">
                    <img src={head_phone} alt="head_phone" />
                    <div>
                      <p className=" fs_15 ff_heebo color_darkgray">
                        Interviews can be nerve-wracking, but with Kodu's
                        interview preparation services, students can approach
                        them with confidence. Kodu offers personalized interview
                        coaching sessions where students receive guidance on
                        commonly asked interview questions, learn effective
                        communication and presentation techniques, and practice
                        mock interviews to refine their skills. By simulating
                        real interview scenarios and providing constructive
                        feedback, Kodu helps students build confidence and
                        improve their performance during actual job interviews,
                        increasing their chances of success.
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
            <div>
              {selectedButton == 3 ? (
                <>
                  <div className="d-flex align-items-start gap-3 mt-5">
                    <img src={head_phone} alt="head_phone" />
                    <div>
                      <p className=" fs_15 ff_heebo color_darkgray">
                        In today's competitive job market, having a strong
                        online presence is essential for standing out to
                        employers. Kodu assists students in building
                        professional profiles that showcase their skills,
                        accomplishments, and experiences effectively. This
                        includes creating and optimizing LinkedIn profiles,
                        developing professional portfolios or personal websites
                        to showcase projects and achievements, and managing
                        online presence across relevant professional platforms.
                        By guiding students in presenting themselves
                        authentically and professionally online, Kodu helps them
                        enhance their visibility to potential employers and
                        build a strong personal brand in their field.
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-md-5 mt-4 mt-md-0">
            <img
              className="w-100"
              src={CareerServices_image}
              alt="CareerServices_image"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerServices;
