import React from "react";
import Imge1 from "../../assets/images/png/Rectangle 55 (1).png";
import Imge2 from "../../assets/images/png/Rectangle 57 (1).png";
import Imge3 from "../../assets/images/png/Rectangle 56.png";
import Imge4 from "../../assets/images/png/Rectangle 51.png";
import Imge5 from "../../assets/images/png/Rectangle 53.png";
import Imge6 from "../../assets/images/png/Rectangle 54.png";

function CenterPlacement() {
  return (
    <div className="centerPlacementheight bg_yellow">
      <div className="container pt-3 pb-5">
        <h1 className="py-4 ff_heebo fs_29 fw-bolder">
          Top Placements from these programs
        </h1>
        <div className="row ">
          <div className="col-lg-6 col-xxl-6 col-md-6">
            <div className="row">
              <div className="col-xl-6">
                <div className="position-relative image_position overflow-hidden rounded-4 cursor_pointer">
                  <div className="overlay_images d-flex flex-column justify-content-center position-absolute p-3">
                    <div>
                      <div className="d-md-flex justify-content-between gap-2 align-items-center text-white ff_heebo">
                        <strong className="fs_12">Name :</strong>
                        <p className="mb-0 fs_12">Rahul Jangra</p>
                      </div>
                      <div className="d-md-flex gap-2 mt-md-2 mt-1 justify-content-between align-items-center text-white ff_heebo">
                        <strong className="fs_12">Department :</strong>
                        <p className="mb-0 fs_12">( Web Developer )</p>
                      </div>
                    </div>
                  </div>
                  <img
                    src={Imge1}
                    className="w-100 h-100 centerImageShandow centerImage"
                  />
                </div>
                <div className="position-relative image_position overflow-hidden rounded-4 cursor_pointer pt-4">
                  <div className="overlay_images d-flex flex-column justify-content-center position-absolute p-3">
                    <div>
                      <div className="d-md-flex justify-content-between gap-2 align-items-center text-white ff_heebo">
                        <strong className="fs_12">Name :</strong>
                        <p className="mb-0 fs_12">Shubham Berwal</p>
                      </div>
                      <div className="d-md-flex gap-2 mt-md-2 mt-1 justify-content-between align-items-center text-white ff_heebo">
                        <strong className="fs_12">Department :</strong>
                        <p className="mb-0 fs_12">( Full Stack Developer )</p>
                      </div>
                    </div>
                  </div>
                  <img
                    src={Imge3}
                    className="w-100 h-100 centerImageShandow centerImage"
                  />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="position-relative image_position overflow-hidden rounded-4 cursor_pointer h-100 pt-4 pt-xl-0">
                  <div className="overlay_images d-flex flex-column justify-content-center position-absolute p-3">
                    <div>
                      <div className="d-md-flex justify-content-between gap-2 align-items-center text-white ff_heebo">
                        <strong className="fs_12">Name :</strong>
                        <p className="mb-0 fs_12">Mandeep Lamba</p>
                      </div>
                      <div className="d-md-flex gap-2 mt-md-2 mt-1 justify-content-between align-items-center text-white ff_heebo">
                        <strong className="fs_12">Department :</strong>
                        <p className="mb-0 fs_12">( Full Stack Developer )</p>
                      </div>
                    </div>
                  </div>
                  <img
                    src={Imge2}
                    className="w-100 h-100 centerImageShandow centerImage"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xxl-6 col-md-6 mt-3 mt-lg-0">
            <div className="row">
              <div className="col-xl-6">
                <div className="position-relative image_position overflow-hidden rounded-4 cursor_pointer">
                  <div className="overlay_images d-flex flex-column justify-content-center position-absolute p-3">
                    <div>
                      <div className="d-md-flex justify-content-between gap-2 align-items-center text-white ff_heebo">
                        <strong className="fs_12">Name :</strong>
                        <p className="mb-0 fs_12">Ajay Duhan</p>
                      </div>
                      <div className="d-md-flex gap-2 mt-md-2 mt-1 justify-content-between align-items-center text-white ff_heebo">
                        <strong className="fs_12">Department :</strong>
                        <p className="mb-0 fs_12">( App Developer )</p>
                      </div>
                    </div>
                  </div>
                  <img
                    src={Imge4}
                    className="w-100 h-100 centerImageShandow centerImage"
                  />
                </div>
                <div className="position-relative pt-4 image_position overflow-hidden rounded-4 cursor_pointer">
                  <div className="overlay_images d-flex flex-column justify-content-center position-absolute p-3">
                    <div>
                      <div className="d-md-flex justify-content-between gap-2 align-items-center text-white ff_heebo">
                        <strong className="fs_12">Name :</strong>
                        <p className="mb-0 fs_12">Lokesh Sharma</p>
                      </div>
                      <div className="d-md-flex gap-2 mt-md-2 mt-1 justify-content-between align-items-center text-white ff_heebo">
                        <strong className="fs_12">Department :</strong>
                        <p className="mb-0 fs_12">( Web Developer )</p>
                      </div>
                    </div>
                  </div>
                  <img
                    src={Imge5}
                    className="w-100 h-100 centerImageShandow centerImage"
                  />
                </div>
              </div>
              <div className="col-xl-6">
                <div className="position-relative h-100 image_position overflow-hidden rounded-4 cursor_pointer pt-4 pt-xl-0">
                  <div className="overlay_images d-flex flex-column justify-content-center position-absolute p-3">
                    <div>
                      <div className="d-md-flex justify-content-between gap-2 align-items-center text-white ff_heebo">
                        <strong className="fs_12">Name :</strong>
                        <p className="mb-0 fs_12">Harshit Pahuja</p>
                      </div>
                      <div className="d-md-flex gap-2 mt-md-2  mt-1 justify-content-between align-items-center text-white ff_heebo">
                        <strong className="fs_12">Department :</strong>
                        <p className="mb-0 fs_12">( Full Stack Developer )</p>
                      </div>
                    </div>
                  </div>
                  <img
                    src={Imge6}
                    className="w-100 h-100 centerImageShandow centerImage"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CenterPlacement;
