import React, { useEffect } from "react";
import RefundPolicy from "../refundpolicy/RefundPolicy";
import Navbar from "../common/Navbar";

const RefundPolicyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <RefundPolicy />
    </div>
  );
};

export default RefundPolicyPage;
