import React from "react";
import hero_job_line from "../../assets/images/png/hero_job_line.png";
import hero_job_line1 from "../../assets/images/png/job_line.png";
import winr_img from "../../assets/images/png/winr_img.png";

const Job = () => {
  return (
    <div className="job_bg py-5">
      <div className="container pb-5 d-xl-block d-none job_section">
        <h2 className=" color_darkgray mb-0 ff_heebo">
          Here’s how you can upgrade <br></br>
        </h2>
        <p className=" fs_37 fw-semibold ff_heebo color_yellow">
          yourself within 12months.
        </p>
        <div className="row">
          <div className="custom_col_witdh h-100 job_section_custom_top_space1">
            <div>
              <p className="mb-1 fs_14 fw-medium ff_heebo">Phase</p>
              <button className="px-2 rounded-5 bg_yellow border-0 text-white">
                1
              </button>
            </div>
            <img className="w-100" src={hero_job_line} alt="hero_job_line" />
            <div className=" px-3 pt-2">
              <h5 className=" fs_14 fw-bolder ff_heebo color_yellow">
                Introduction to Kodu Platform
              </h5>
              <p>
                Master the basics of C, C++, and Core Java to understand
                fundamental programming concepts and syntax.
              </p>
            </div>
          </div>
          <div className="custom_col_witdh h-100 job_section_custom_top_space2">
            <div>
              <p className="mb-1 fs_14 fw-medium ff_heebo">Phase</p>
              <button className="px-2 rounded-5 bg_yellow border-0 text-white">
                2
              </button>
            </div>
            <img className="w-100" src={hero_job_line} alt="hero_job_line" />
            <div className=" px-3 pt-2">
              <h5 className=" fs_14 fw-bolder ff_heebo color_yellow">
                Front End Development
              </h5>
              <p>
                Explore HTML, CSS, JavaScript, Bootstrap, jQuery, and ReactJS to
                craft engaging and responsive user interfaces.
              </p>
            </div>
          </div>
          <div className="custom_col_witdh h-100 job_section_custom_top_space3">
            <div>
              <p className="mb-1 fs_14 fw-medium ff_heebo">Phase</p>
              <button className="px-2 rounded-5 bg_yellow border-0 text-white">
                3
              </button>
            </div>
            <img className="w-100" src={hero_job_line} alt="hero_job_line" />
            <div className=" px-3 pt-2">
              <h5 className=" fs_14 fw-bolder ff_heebo color_yellow">
                Introduction to Programming
              </h5>
              <p>
                Dive into Python, Node.js, and Express.js to build robust
                server-side applications and RESTful APIs.
              </p>
            </div>
          </div>
          <div className="custom_col_witdh h-100 job_section_custom_top_space4">
            <div>
              <p className="mb-1 fs_14 fw-medium ff_heebo">Phase</p>
              <button className="px-2 rounded-5 bg_yellow border-0 text-white">
                4
              </button>
            </div>
            <img className="w-100" src={hero_job_line} alt="hero_job_line" />
            <div className=" px-3 pt-2">
              <h5 className=" fs_14 fw-bolder ff_heebo color_yellow">
                Back End Development
              </h5>
              <p>
                Gain familiarity with Kodu Platform's coding programs, setting
                the foundation for the journey into Full Stack Development.
                Phase 1: Introduction to Programming Master the basics of C,
                C++, and.
              </p>
            </div>
          </div>
          <div className="custom_col_witdh h-100 job_section_custom_top_space5">
            <div>
              <p className="mb-1 fs_14 fw-medium ff_heebo">Phase</p>
              <button className="px-2 rounded-5 bg_yellow border-0 text-white">
                5
              </button>
            </div>
            <img className="w-100" src={hero_job_line} alt="hero_job_line" />
            <div className=" px-3 pt-2">
              <h5 className=" fs_14 fw-bolder ff_heebo color_yellow">
                Database
              </h5>
              <p>
                Learn MongoDB, SQL, and MySQL for efficient data storage and
                retrieval in web applications.
              </p>
            </div>
          </div>
          <div className="custom_col_witdh h-100 job_section_custom_top_space6">
            <div>
              <p className="mb-1 fs_14 fw-medium ff_heebo">Phase</p>
              <button className="px-2 rounded-5 bg_yellow border-0 text-white">
                6
              </button>
            </div>
            <img className="w-100" src={hero_job_line} alt="hero_job_line" />
            <div className=" px-3 pt-2">
              <h5 className=" fs_14 fw-bolder ff_heebo color_yellow">
                Mobile App Development
              </h5>
              <p>
                Discover React Native to create cross-platform mobile
                applications with JavaScript and React.
              </p>
            </div>
          </div>
          <div className="custom_col_witdh h-100 job_section_custom_top_space7">
            <div className="mb-4">
              <p className="mb-1 fs_14 fw-medium ff_heebo">Phase</p>
              <button className="px-2 rounded-5 bg_yellow border-0 text-white">
                7
              </button>
            </div>
            <img className="w-100 " src={hero_job_line1} alt="hero_job_line" />
            <div className=" px-3 pt-2">
              <h5 className=" fs_14 fw-bolder ff_heebo color_yellow">
                Training Part
              </h5>
              <p>
                Prepare for interviews, enhance personality development, and
                collaborate on live projects to refine skills and confidence.
              </p>
            </div>
          </div>
          <div className="custom_col_witdh position-relative ">
            <div className="mb-4">
              <p className="mb-1 fs_14 fw-medium color_blue ff_heebo">Phase</p>
              <button className="px-2 rounded-5 h-100  bg_darkblue border-0 text-white">
                8
              </button>
            </div>
            <img
              className=" position-absolute custom_position_image end-0"
              width={100}
              src={winr_img}
              alt="winr_img"
            />
            <div className=" bg_darkblue rounded-3 job_section_custom_top_space8 h-100">
              <div className="px-3 pb-2 pt-4">
                <h5 className=" fs_17 fw-bolder ff_heebo text-white fw-bold">
                  Internship & Job in IT Companies
                </h5>
                <p className=" text-white">
                  apply acquired knowledge and skills through internships, job
                  placements, and networking opportunities in the IT industry.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container d-xl-none d-block">
        <h2 className=" color_darkgray mb-0 ff_heebo">
          Here’s how you can upgrade <br></br>
        </h2>
        <p className=" fs_37 fw-semibold ff_heebo color_yellow">
          yourself within 12months.
        </p>
        <div className="row mt-4">
          <div className="col-md-4 col-sm-6 mt-4">
            <div className="border p-3 rounded-3 h-100">
              <div>
                <p className="mb-1 fs_14 fw-medium ff_heebo">Phase</p>
                <button className="px-2 rounded-5 bg_yellow border-0 text-white">
                  1
                </button>
              </div>

              <div className=" pt-2">
                <h5 className=" fs_14 fw-bolder ff_heebo color_yellow">
                  Introduction to Kodu Platform
                </h5>
                <p>
                  Master the basics of C, C++, and Core Java to understand
                  fundamental programming concepts and syntax.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mt-4">
            <div className="border p-3 rounded-3 h-100">
              <div>
                <p className="mb-1 fs_14 fw-medium ff_heebo">Phase</p>
                <button className="px-2 rounded-5 bg_yellow border-0 text-white">
                  2
                </button>
              </div>
              <div className=" pt-2">
                <h5 className=" fs_14 fw-bolder ff_heebo color_yellow">
                  Front End Development
                </h5>
                <p>
                  Explore HTML, CSS, JavaScript, Bootstrap, jQuery, and ReactJS
                  to craft engaging and responsive user interfaces.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mt-4">
            <div className="border p-3 rounded-3 h-100">
              <div>
                <p className="mb-1 fs_14 fw-medium ff_heebo">Phase</p>
                <button className="px-2 rounded-5 bg_yellow border-0 text-white">
                  3
                </button>
              </div>
              <div className=" pt-2">
                <h5 className=" fs_14 fw-bolder ff_heebo color_yellow">
                  Introduction to Programming
                </h5>
                <p>
                  Dive into Python, Node.js, and Express.js to build robust
                  server-side applications and RESTful APIs.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mt-4">
            <div className="border p-3 rounded-3 h-100">
              <div>
                <p className="mb-1 fs_14 fw-medium ff_heebo">Phase</p>
                <button className="px-2 rounded-5 bg_yellow border-0 text-white">
                  4
                </button>
              </div>
              <div className=" pt-2">
                <h5 className=" fs_14 fw-bolder ff_heebo color_yellow">
                  Back End Development
                </h5>
                <p>
                  Gain familiarity with Kodu Platform's coding programs, setting
                  the foundation for the journey into Full Stack Development.
                  Phase 1: Introduction to Programming Master the basics of C,
                  C++, and.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mt-4">
            <div className="border p-3 rounded-3 h-100">
              <div>
                <p className="mb-1 fs_14 fw-medium ff_heebo">Phase</p>
                <button className="px-2 rounded-5 bg_yellow border-0 text-white">
                  5
                </button>
              </div>
              <div className=" pt-2">
                <h5 className=" fs_14 fw-bolder ff_heebo color_yellow">
                  Database
                </h5>
                <p>
                  Learn MongoDB, SQL, and MySQL for efficient data storage and
                  retrieval in web applications.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mt-4">
            <div className="border p-3 rounded-3 h-100">
              <div>
                <p className="mb-1 fs_14 fw-medium ff_heebo">Phase</p>
                <button className="px-2 rounded-5 bg_yellow border-0 text-white">
                  6
                </button>
              </div>
              <div className=" pt-2">
                <h5 className=" fs_14 fw-bolder ff_heebo color_yellow">
                  Mobile App Development
                </h5>
                <p>
                  Discover React Native to create cross-platform mobile
                  applications with JavaScript and React.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mt-4">
            <div className="border p-3 rounded-3 h-100">
              <div className="mb-4">
                <p className="mb-1 fs_14 fw-medium ff_heebo">Phase</p>
                <button className="px-2 rounded-5 bg_yellow border-0 text-white">
                  7
                </button>
              </div>

              <div className=" pt-2">
                <h5 className=" fs_14 fw-bolder ff_heebo color_yellow">
                  Training Part
                </h5>
                <p>
                  Prepare for interviews, enhance personality development, and
                  collaborate on live projects to refine skills and confidence.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mt-4">
            <div className="border p-3 rounded-3 h-100">
              <div className="d-flex mb-4 justify-content-between">
                <div className="mb-4">
                  <p className="mb-1 fs_14 fw-medium color_blue ff_heebo">
                    Phase
                  </p>
                  <button className="px-2 rounded-5 bg_darkblue border-0 text-white">
                    8
                  </button>
                </div>
                <img width={80} src={winr_img} alt="winr_img" />
              </div>

              <div className=" bg_darkblue rounded-3">
                <div className="px-3 pb-2 pt-4">
                  <h5 className=" fs_17 fw-bolder ff_heebo text-white fw-bold">
                    Internship & Job in IT Companies
                  </h5>
                  <p className="text-white">
                    apply acquired knowledge and skills through internships, job
                    placements, and networking opportunities in the IT industry.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Job;
