import React from "react";
import { Multiselect } from "multiselect-react-dropdown";

const MultiySelectComponent = ({ selectedValues, setSelectedValues }) => {
  const options = [
    { name: "Programming Language’s", id: 1 },
    { name: "Web Development", id: 2 },
    { name: "Android Development", id: 3 },
    { name: "Assistance Program", id: 4 },
  ];

  const onSelect = (selectedList) => {
    const selectedNames = selectedList.map((item) => item.name);
    setSelectedValues(selectedNames);
  };

  const onRemove = (selectedList) => {
    const remainingNames = selectedList.map((item) => item?.name);
    setSelectedValues(remainingNames);
  };

  return (
    <div>
      <Multiselect
        options={options}
        selectedValues={selectedValues?.map((name) =>
          options.find((o) => o.name === name)
        )}
        onSelect={onSelect}
        onRemove={onRemove}
        displayValue="name"
      />
    </div>
  );
};

export default MultiySelectComponent;
