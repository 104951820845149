import React from "react";
import support_img from "../../assets/images/png/support.png";

const Support = () => {
  return (
    <div className="py-5">
      <div className="container">
        <div className="text-center">
          <h2 className=" fs_37 fw-bold ff_heebo color_blue">
            Instant Doubt Support
          </h2>
          <div className="d-flex justify-content-center">
            <div className="col-md-3 col-9 ">
              <img className="mt-3 w-100" src={support_img} alt="support_img" />
            </div>
          </div>
        </div>
        <div className="row mt-4 justify-content-center gap-5">
          <div className="col-md-3 col-sm-5">
            <h3 className=" fs_24 fw-bold ff_heebo  mb-0">
              Doubts Solved in 5-10 min
            </h3>
            <p className="mb-0 ff_heebo fs_16 fw-normal">
              Ask your doubts when you want, and get it solved within 5-10
              minutes
            </p>
          </div>
          <div className="col-md-3 col-sm-5">
            <h3 className=" fs_24 fw-bold ff_heebo  mb-0">
              Doubts Solved in 5-10 min
            </h3>
            <p className="mb-0 ff_heebo fs_16 fw-normal">
              Ask your doubts when you want, and get it solved within 5-10
              minutes
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
