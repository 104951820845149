import React, { useState } from "react";
import coding_tabs from "../../assets/images/png/coding_tabs_imagr.png";
import image from "../../assets/images/png/amico.png";

const Ecosystem = () => {
  const [selectedCourse, setSelectedCourse] = useState("Practice");

  const handleCourseClick = (Practice) => {
    setSelectedCourse(Practice);
  };
  return (
    <div className="py-5 custom_bg">
      <div className="container">
        <h3 className=" fs_37 text-center fw-bold ff_heebo color_blue">
          Enter Our Career
          <span className="color_yellow">Pathway</span>
        </h3>
        <div className=" mx-auto col-sm-6">
          <div className="mt-md-4 mt-2 row position-relative align-items-center">
            <img
              height={30}
              className=" position-absolute opacity d-md-block d-none"
              src={coding_tabs}
              alt="coding_tabs"
            />
            <div className="col-md-4  col-6 mt-md-0 mt-4 d-flex justify-content-center">
              <span
                onClick={() => handleCourseClick("Learn")}
                className={
                  selectedCourse === "Learn"
                    ? "selected-course fs_18 text-white fw-medium text-center "
                    : "cursor_pointer py-3 ff_heebo  fs_16 "
                }
              >
                Learn
              </span>
            </div>
            <div className="col-md-4  col-6 mt-md-0 mt-4 d-flex justify-content-center">
              <span
                onClick={() => handleCourseClick("Practice")}
                className={
                  selectedCourse === "Practice"
                    ? "selected-course fs_18 text-white fw-medium text-center "
                    : "cursor_pointer py-3 ff_heebo  fs_16"
                }
              >
                Practice
              </span>
            </div>
            <div className="col-md-4  col-6 mt-md-0 mt-4 d-flex justify-content-center">
              <span
                onClick={() => handleCourseClick("Get Hired")}
                className={
                  selectedCourse === "Get Hired"
                    ? "selected-course fs_18 text-white fw-medium text-center "
                    : "cursor_pointer py-3 ff_heebo  fs_16"
                }
              >
                Get Hired
              </span>
            </div>
          </div>
        </div>
        <div className="row gap-5 pt-xl-5 justify-content-center align-items-center mt-5">
          {selectedCourse === "Learn" ? (
            <>
              <div className="col-md-5">
                <h4 className=" fs_37 fw-bold ff_heebo color_yellow mb-0">
                  Learn
                </h4>
                <p className=" mt-2 fs_17 ff_heebo color_blue mb-0 fw-medium">
                  Cutting-Edge Curriculum: Our curriculum is carefully crafted
                  to cover the latest tools, technologies, and best practices in
                  the industry. You'll learn from industry experts who bring
                  real-world experience into the classroom.
                </p>
                <p className=" fs_17 ff_heebo color_blue mt-2">
                  Interactive Learning: Say goodbye to boring lectures. Our
                  interactive learning environment keeps you engaged and
                  motivated, with hands-on projects, coding challenges, and
                  group discussions.
                </p>
                <p className=" fs_17 ff_heebo color_blue mt-2">
                  Flexible Learning Options: Whether you prefer in-person
                  classes, online learning, or a combination of both, we offer
                  flexible options to suit your schedule and learning style.
                </p>

                <div className="d-flex align-items-center gap-2">
                  <button className="w-100 border-0 mt-3 bg_darkblue px-5 py-2 fw-medium ff_heebo fs_16 text-white rounded-1">
                    Offline Classes
                  </button>
                  <button className="w-100 border-0 mt-3 bg_yellow px-5 py-2 fw-medium ff_heebo fs_16 text-white rounded-1">
                    Online
                  </button>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {selectedCourse === "Practice" ? (
            <>
              <div className="col-md-5">
                <h4 className=" fs_37 fw-bold ff_heebo color_yellow mb-0">
                  PRACTICE
                </h4>
                <p className="mt-2 fs_17 ff_heebo color_blue mb-0 fw-medium">
                  Real-World Projects: Put your skills to the test with hands-on
                  projects that mirror real-world scenarios. You'll build a
                  portfolio of work that showcases your abilities to potential
                  employers.
                </p>
                <p className=" fs_17 ff_heebo color_blue mt-2">
                  Coding Challenges: Sharpen your coding skills with our
                  challenging exercises and coding competitions. Practice makes
                  perfect, and we provide plenty of opportunities for you to
                  hone your craft.
                </p>
                <p className=" fs_17 ff_heebo color_blue mt-2">
                  Collaborative Environment: Learning is more fun when you do it
                  together. Collaborate with fellow students, share ideas, and
                  learn from each other's experiences in our supportive
                  community.
                </p>
                <button className="w-100 border-0 mt-3 bg_darkblue px-5 py-2 fw-medium ff_heebo fs_16 text-white rounded-1">
                  Explore
                </button>
              </div>
            </>
          ) : (
            <></>
          )}
          {selectedCourse === "Get Hired" ? (
            <>
              <div className="col-md-5">
                <h4 className=" fs_37 fw-bold ff_heebo color_yellow mb-0">
                  Get Hired
                </h4>
                <p className=" mt-2 fs_17 ff_heebo color_blue mb-0 fw-medium">
                  Career Services Support: Our dedicated career services team is
                  here to help you land your dream job. From resume writing
                  workshops to mock interviews, we'll equip you with the tools
                  and resources you need to succeed.
                </p>
                <p className=" fs_17 ff_heebo color_blue mt-2">
                  Industry Connections: We have strong partnerships with top IT
                  companies, providing you with exclusive access to job
                  opportunities, internships, and networking events.
                </p>
                <p className=" fs_17 ff_heebo color_blue mt-2">
                  Alumni Success Stories: Join a community of successful alumni
                  who have gone on to work at leading tech companies around the
                  world. Their success stories are a testament to the quality of
                  education and support you'll receive here.
                </p>
                <button className="w-100 border-0 mt-3 bg_darkblue px-5 py-2 fw-medium ff_heebo fs_16 text-white rounded-1">
                  Apply Now
                </button>
              </div>
            </>
          ) : (
            <></>
          )}

          <div className="col-md-5">
            <img className="w-100" src={image} alt="hello" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ecosystem;
