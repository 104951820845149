import React, { useEffect } from "react";
import RecordedCoursesHero from "../recordedCoursesPage/RecordedCoursesHero";
import Navbar from "../common/Navbar";
import AboutComponent from "../recordedCoursesPage/AboutComponent";
import Support from "../recordedCoursesPage/Support";
import RecordedCurriculum from "../recordedCoursesPage/RecordedCurriculum";
import Reviews from "../homePage/Reviews";
import AboutCourse from "../recordedCoursesPage/AboutCourse";
import WhyChoose from "../recordedCoursesPage/WhyChoose";
import Opportunity from "../recordedCoursesPage/Opportunity";

const RecordedCoursesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <RecordedCoursesHero />
      <AboutComponent />
      <Opportunity />
      <Support />
      <WhyChoose />
      <RecordedCurriculum />
      <Reviews />
      <AboutCourse />
    </div>
  );
};

export default RecordedCoursesPage;
