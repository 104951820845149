import React from "react";

const RefundPolicy = () => {
  return (
    <div className="py-5">
      <div className="container">
        <h1 className="fw-bold ff_heebo color_yellow">Refund Policy</h1>
        <p className="mt-4 ff_heebo fs_18">
          We don’t have any refund policy as we enroll students in our courses
          only after 3 free demo sessions, once students feel satisfied then
          only registration is done. For any other info you can connect with us
          at - <a href="mailto:support@koduapp.com">support@koduapp.com</a>
        </p>
        {/* <p className="mt-4 ff_heebo fs_18">
          To be eligible for a return, you’ll also need the receipt or proof of
          purchase.
        </p>
        <p className="mt-4 ff_heebo fs_18">
          To start a return, you can contact us at koduapp7@gmail.com.
        </p> */}
        <h4 className="mt-5 fs_26 ff_heebo color_blue">
          Cancellation and Refund Policy
        </h4>
        <p className="fs_18 fw-normal ff_heebo">
          If you wish to cancel a confirmed enrolment made via the
          Application/Website, after enrolment to the Course, you may do so by
          contacting us via the contact link in the Contact Section. All
          courses, products and services listed on the application are
          Non-Refundable and once enrolled in/obtained/subscribed to/ordered
          shall not be subject to any refunds or returns. A refund shall be made
          to the user only in the event a repeat payment (payment made twice for
          the same course) has been made by the user. Such refund shall be made
          for the amount paid in excess of the fee of the course only and not
          the entire amount paid. The refund shall be credited to the bank
          account of the User within 7 business days. If payment is made via
          credit card, refunds shall be issued to the original credit card
          provided at the time of purchase.
        </p>
        <h4 className="mt-5 fs_26 ff_heebo color_blue">Refunds</h4>
        <p className="fs_18 fw-normal ff_heebo">
          We will notify you once we’ve received and inspected your return to
          let you know if the refund was approved or not. If approved, you’ll be
          automatically refunded on your original payment method within 10
          business days. Please remember it can take some time for your bank or
          credit card company to process and post the refund too.
        </p>

        <p className="fs_18 fw-normal ff_heebo">
          If more than 15 business days have passed since we’ve approved your
          return, please contact us at{" "}
          <a
            href="mailto:support@koduapp.com
"
          >
            support@koduapp.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default RefundPolicy;
