import React from "react";
import { Container } from "react-bootstrap";
import logo from "../../assets/images/svg/logo.svg";

const MasterNavbar = () => {
  return (
    <div className=" bg_darkblue py-3">
      <Container>
        <img className=" cursor_pointer" src={logo} alt="logo" />
      </Container>
    </div>
  );
};

export default MasterNavbar;
