import React, { useCallback, useContext, useState } from "react";
import makeApiCall from "../callApi";
import admin_login_image from "../../assets/images/png/admin_login_img.png";
import { AppContext } from "../../context/Context";

function AdminLoginPage() {
  const context = useContext(AppContext);
  const { handleLogin } = context;

  const [value, setValue] = useState();
  const handleChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };
  async function handleSubmit() {
    const { email, password } = value;
    if (email === "" && password === "") {
      alert("All input requiret");
    } else {
      const data = {
        email,
        password,
      };

      await makeApiCall(data, "post", "admin/login", adminLogin);
    }
  }

  const adminLogin = useCallback((response) => {
    console.log(response.data.data.token);
    localStorage.setItem("admtoken", response.data.data.token);
    window.location.reload();
  });

  return (
    <div className=" min-vh-100 container-fluid">
      <div className="row justify-content-between align-items-center">
        <div className="col-xl-5 mt-5 mt-xl-0" style={{ height: "100%" }}>
          <div className="col-9">
            <div className="rounded-3 px-md-5 position-relative">
              <h3 className="mb-0 fs_29 ff_heebo fw-bolder text-black">
                Login
              </h3>
              <input
                className="px-3 mt-4 custom_input py-2 w-100 rounded-2"
                type="email"
                placeholder="Enter Email"
                name="email"
                onChange={handleChange}
              />
              <input
                className="px-3 mt-4 custom_input py-2 w-100 rounded-2 mt-3"
                type="password"
                placeholder="Enter Password"
                name="password"
                onChange={handleChange}
              />
              <div className=" mt-4">
                <button
                  className=" py-2 px-5 mt-2 bg_yellow text-white rounded-2 border-0 fs_17 fw-medium ff_heebo"
                  onClick={() => handleLogin(value?.email, value?.password)}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 mt-5 mt-xl-0 px-0" style={{ height: "100vh" }}>
          <div className=" h-100">
            <img
              className="w-100 h-100"
              src={admin_login_image}
              alt="admin_login_image"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminLoginPage;
