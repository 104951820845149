import React, { useCallback, useState } from "react";
import form_img from "../../assets/images/png/form_img.png";
import form_position_img from "../../assets/images/png/form_position_img.png";
import { Accordion } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import makeApiCall from "../callApi";

const Counselling = () => {
  const [counsellingData, setCounsellingData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  // const [value, setValue] = useState("");
  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setCounsellingData((prevData) => ({
        ...prevData,
        [name]: files?.length > 0 ? files[0] : value,
      }));
    } else {
      setCounsellingData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const createForm = async (e) => {
    e.preventDefault();

    const counsellingdata = {
      name: counsellingData.name,
      email: counsellingData.email,
      phone: counsellingData.phone,
    };

    try {
      const res = await makeApiCall(
        counsellingdata,
        "post",
        "new_lead",
        createFormCallBack,
        false
      );
      // createFormCallBack(res);x
    } catch (error) {
      toast.error("Failed to create lead. Please try again later.");
    }
  };

  const createFormCallBack = useCallback((res) => {
    console.log(res);
    if (res.data.status === "success") {
      toast.success("Lead created successfully!");
      setCounsellingData({
        name: "",
        email: "",
        phone: "",
      });
    } else {
      toast.error(res.data.message);
    }
  });

  return (
    <div className="py-5">
      <ToastContainer />
      <div className="bg_yellow custom_y_space"></div>
      <div className="container">
        <div className="box rounded-2">
          <div className="row  position-relative align-items-center">
            <img
              className="form_position_img d-md-block d-none position-absolute"
              src={form_position_img}
              alt="form_position_img"
            />
            <div className="col-md-6 pt-5 pt-md-0">
              <div className="ps-5">
                <h3 className=" fs_26 fw-bold mb-0 ff_heebo color_blue">
                  Need a career path or<br></br>
                  counselling with our Mentors?
                </h3>
              </div>
            </div>
            <div className="col-md-6 mt-5 mt-md-0 d-flex justify-content-center  position-relative">
              <img
                className="h-100 px-3 px-md-2 w-100 position-absolute"
                src={form_img}
                alt="hello"
              />
              {/* <div className="bg_image p-5 w-100">
                <p className="mb-0">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Minima odio vitae voluptate quam eaque qui iusto quis!
                  Voluptas sequi nihil repellat voluptate dolor eos ducimus
                  itaque atque quos, laboriosam iste.
                </p>
              </div> */}
              <div className="col-md-6 py-5 position-relative z_index1">
                <form action="" onSubmit={createForm}>
                  <div className="d-flex flex-column pt-0">
                    <label
                      className=" fs_17 ff_heebo fw-medium text-white"
                      htmlFor=""
                    >
                      Your Full Name
                    </label>
                    <input
                      className="input py-2 px-3 rounded-2 mt-2"
                      type="text"
                      placeholder="Enter Full Name"
                      value={counsellingData.name}
                      onChange={handleInputChange}
                      name="name"
                    />
                  </div>
                  <div className="d-flex flex-column mt-4">
                    <label
                      className=" fs_17 ff_heebo fw-medium text-white"
                      htmlFor=""
                    >
                      Email
                    </label>
                    <input
                      className="input py-2 px-3 rounded-2 mt-2"
                      type="email"
                      placeholder="Enter your email"
                      name="email"
                      value={counsellingData.email}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="d-flex flex-column mt-4">
                    <label
                      className=" fs_17 ff_heebo fw-medium text-white"
                      htmlFor=""
                    >
                      Contact Number
                    </label>
                    <input
                      className="input py-2 px-3 rounded-2 mt-2"
                      type="number"
                      placeholder="Enter contact number"
                      value={counsellingData.phone}
                      name="phone"
                      onChange={handleInputChange}
                    />
                  </div>

                  <button
                    type="submit"
                    className="w-100 py-2 bg_yellow border-0 mt-5 rounded-1 text-white fw-semibold fs_17 ff_heebo"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-5 mt-5">
        <div className="container counselling mt-5 pt-md-5">
          <div className="bg_darkblue rounded-top-3 mb-0 p-3">
            <h5 className="mb-0 text-white ff_heebo">Course Content</h5>
          </div>
          <div className="bg_lightblue rounded-bottom-3 p-4">
            <Accordion className=" " defaultActiveKey="0">
              <Accordion.Item className="mt-2  rounded-0" eventKey="0">
                <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                  <div className="d-flex flex-column">
                    <h6>Introduction</h6>
                    <p>1 Items | Duration : 2hrs</p>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="ps-5 pb-3">
                  <p>
                    Our Coding Bootcamp offers both full-time and part-time
                    options.If you choose the full-time Coding Bootcamp, you'll
                    need to commit from 10 am to 6 pm SGT, Monday to Friday, for
                    four months. We recommend against holding a full-time job
                    while attending the full-time Coding Bootcamp.
                  </p>
                  <p>
                    For those opting for the part-time Coding Bootcamp, you'll
                    need to commit to classes from 7:30 pm to 9:30 pm on
                    Saturday evenings and from 10 am to 12 pm SGT on Sunday
                    mornings. This schedule amounts to roughly 20 hours of work
                    per week for twelve to fifteen months.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <div className="border_blue"></div>
              <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="1">
                <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                  <div className="d-flex flex-column">
                    <h6>HTML</h6>
                    <p>1 Items | Duration : 2hrs</p>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="px-5 pb-3">
                  <p className="pt-3">
                    While there's no strict attendance policy for Kodu coding
                    courses, students failing to meet performance standards may
                    be asked to withdraw from their course.
                  </p>
                  <p>
                    If you anticipate missing a class due to any reason, please
                    inform your instructor in advance. We value communication
                    and appreciate your proactive approach..
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <div className="border_blue"></div>
              <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="2">
                <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                  <div className="d-flex flex-column">
                    <h6>CSS-I</h6>
                    <p>1 Items | Duration : 2hrs</p>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="px-5 pb-3">
                  <p className="pt-3">
                    {" "}
                    If you anticipate missing a class due to any reason, please
                    inform your instructor in advance. We value communication
                    and appreciate your proactive approach..
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <div className="border_blue"></div>
              <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="3">
                <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                  <div className="d-flex flex-column">
                    <h6>CSS-II</h6>
                    <p>1 Items | Duration : 2hrs</p>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="px-5 pb-3">
                  <p className="pt-3">
                    Feeling challenged by coding is normal and shared by many
                    software engineers. If you're unsure whether your struggles
                    are typical, consider starting with our Coding Fundamentals
                    course. At Kodu, we offer on-demand support to students
                    through group chat. If you find yourself stuck on a problem
                    for more than 15 minutes, don't hesitate to ask for help. We
                    want to ensure you don't spend too much time on relatively
                    simple issues.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <div className="border_blue"></div>
              <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="4">
                <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                  <div className="d-flex flex-column">
                    <h6>CSS-III</h6>
                    <p>1 Items | Duration : 2hrs</p>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="px-5 pb-3">
                  <p className="pt-3">
                    More than 95% of Kodu Institute students didn't hold CS
                    degrees or diplomas before joining our program. Kodu
                    Institute has seen success stories from individuals with
                    diverse backgrounds, including fields like cooking. The
                    existence of coding bootcamps is a response to the industry
                    demand, as universities struggle to produce enough
                    engineers. Ultimately, companies prioritize hiring talented
                    engineers, regardless of their formal qualifications.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <div className="border_blue"></div>
              <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="5">
                <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                  <div className="d-flex flex-column">
                    <h6>JS-I</h6>
                    <p>1 Items | Duration : 2hrs</p>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="px-5 pb-3">
                  <p className="pt-3">
                    If you're already familiar with coding but need guidance on
                    landing a software job, feel free to chat with us. We can
                    assess your situation and advise whether self-study or
                    joining a course would be most beneficial.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <div className="border_blue"></div>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Counselling;
