import React from "react";
import grid_img1 from "../../assets/images/png/grid_img1.png";
import grid_img2 from "../../assets/images/png/grid_img2.png";
import grid_img from "../../assets/images/webp/grid_group.webp";

const Unmatched = () => {
  return (
    <div className="py-5 bg_yellow">
      <div className="container">
        <div className="row justify-content-between align-items-center">
          <div className="col-lg-4">
            <h5 className=" fs_29 fw-bold ff_heebo text-black">
              Our Talent pool is <span className="color_red">Unmatched!</span>
            </h5>
            <h2 className=" fs_140 fw-bold text-white ff_heebo mb-0 mt-0 mt-md-4">
              1000+
            </h2>
            <p className=" fs_20 fw-medium ff_heebo text-black">
              students from India trust us for their Tech careers
            </p>
          </div>
          <div className="col-lg-7 mt-4 mt-lg-0">
            {/* <div className=" d_grid">
              <img
                className="grid1"
                width={200}
                src={grid_img1}
                alt="grid_img1"
              />
              <img
                className="grid2"
                width={350}
                src={grid_img2}
                alt="grid_img1"
              />
              <img
                className="grid3"
                width={350}
                src={grid_img2}
                alt="grid_img1"
              />
              <img
                className="grid4"
                width={200}
                src={grid_img1}
                alt="grid_img1"
              />
            </div> */}
            <img className="w-100" src={grid_img} alt="grid_img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Unmatched;
