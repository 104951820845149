import React from "react";

const WhyChoose = () => {
  return (
    <div className=" py-5 bg_lightblue">
      <div className="container">
        <h3 className=" fs_37 fw-bold ff_heebo color_blue text-center">
          Why choose us ?
        </h3>
        <div className="row mt-4 justify-content-between">
          <div className="col-lg-4 col-sm-6 mt-4">
            <div className="d-flex gap-3">
              <h3 className=" custom_font fw-bold ff_heebo">1</h3>
              <div>
                <h4 className="mb-0 fs_37 ff_heebo fw-bold color_blue mt-2">
                  100% Free <br className=" d-lg-block d-none"></br>Learning
                </h4>
                <p className=" fs_16 fw-medium ff_heebo">
                  On the courses enrolled within the 100% Refund offer through
                  which you earn your enrollment fee back as reward
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 mt-4">
            <div className="d-flex gap-3">
              <h3 className=" custom_font fw-bold ff_heebo">2</h3>
              <div>
                <h4 className="mb-0 fs_37 ff_heebo fw-bold color_blue mt-2">
                  Structured <br className=" d-lg-block d-none"></br>
                  Course
                </h4>
                <p className=" fs_16 fw-medium ff_heebo">
                  On the courses enrolled within the 100% Refund offer through
                  which you earn your enrollment fee back as reward
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 mt-4">
            <div className="d-flex gap-3">
              <h3 className=" custom_font fw-bold ff_heebo">3</h3>
              <div>
                <h4 className="mb-0 fs_37 ff_heebo fw-bold color_blue mt-2">
                  Instant Mentor <br className=" d-lg-block d-none"></br>Support
                </h4>
                <p className=" fs_16 fw-medium ff_heebo">
                  On the courses enrolled within the 100% Refund offer through
                  which you earn your enrollment fee back as reward
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 mt-4">
            <div className="d-flex gap-3">
              <h3 className=" custom_font fw-bold ff_heebo">4</h3>
              <div>
                <h4 className="mb-0 fs_37 ff_heebo fw-bold color_blue mt-2">
                  Projects &<br className=" d-lg-block d-none"></br>
                  Certifications
                </h4>
                <p className=" fs_16 fw-medium ff_heebo">
                  On the courses enrolled within the 100% Refund offer through
                  which you earn your enrollment fee back as reward
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 mt-4">
            <div className="d-flex gap-3">
              <h3 className=" custom_font fw-bold ff_heebo">5</h3>
              <div>
                <h4 className="mb-0 fs_37 ff_heebo fw-bold color_blue mt-2">
                  Internship
                  <br className=" d-sm-block d-none"></br> Opportunities
                </h4>
                <p className=" fs_16 fw-medium ff_heebo">
                  On the courses enrolled within the 100% Refund offer through
                  which you earn your enrollment fee back as reward
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 mt-4">
            <div className="d-flex gap-3">
              <h3 className=" custom_font fw-bold ff_heebo">6</h3>
              <div>
                <h4 className="mb-0 fs_37 ff_heebo fw-bold color_blue mt-2">
                  Lifetime <br className=" d-lg-block d-none"></br>
                  Course Access
                </h4>
                <p className=" fs_16 fw-medium ff_heebo">
                  On the courses enrolled within the 100% Refund offer through
                  which you earn your enrollment fee back as reward
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChoose;
