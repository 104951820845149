import React, { useEffect } from "react";
import Privacypolicy from "../privacypolicy/Privacypolicy";
import Navbar from "../common/Navbar";

const PrivacyPolicyPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <Privacypolicy />
    </div>
  );
};

export default PrivacyPolicyPage;
