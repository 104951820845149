import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CoursesDetailHero from "../coursesDetailsPage/CoursesDetailHero";
import makeApiCall from "../callApi";

const CoursesDetailsPage = () => {
  let { id } = useParams();
  const [course, SetCourse] = useState({});
  const [content, SetContent] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);

    makeApiCall("", "get", `course_details/${id}`, course_detail);
  }, []);

  const course_detail = useCallback((response) => {
    if (response.status === 200) {
      SetCourse(response.data.data);
      SetContent(response.data);
      console.log(response.data);
    } else {
      console.log("error");
    }
  });

  return (
    <div>
      <CoursesDetailHero course={course} content={content} />
    </div>
  );
};

export default CoursesDetailsPage;
