import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { IoLocationOutline } from "react-icons/io5";
import teacherImage from "../../assets/images/png/Group 2507.png";
import playbutton from "../../assets/images/png/SVGRepo_iconCarrier.png";
import makeApiCall from "../callApi";
import { Link } from "react-router-dom";
const ClassroomBatches = () => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    makeApiCall("", "get", "courses", homeDataCallback);
  }, []);

  const homeDataCallback = (response) => {
    if (response.status === 200) {
      setCourses(response.data.data);
      console.log(response.data.data);
    } else {
      console.error("Error fetching courses");
    }
  };

  const convertDate = (date) => {
    const res = new Date(date).toDateString();
    const res2 = new Date(date).toLocaleTimeString();
    return { date: res, time: res2 };
  };

  const [visibleCourses, setVisibleCourses] = useState(3);

  const handleSeeMore = () => {
    setVisibleCourses((prev) => prev + 3);
  };

  const handleSeeLess = () => {
    setVisibleCourses(3);
  };
  return (
    <div className="pb-5">
      <div className="container">
        <h3 className=" fs_26 ff_heebo fw-bold color_darkgray">
          Currently Available Classroom batches
        </h3>
        <div className="row align-items-center mt-4">
          <div className="col-3">
            <Form.Select
              aria-label="Default select example"
              className="bg_lightgray"
            >
              <option value="Select Location">Select Location</option>
              <option value="Hisar">Hisar</option>
              <option value="Sirsa">Sirsa</option>
              <option value="Rohtak">Rohtak</option>
              {/* <option value="Jaipur">Jaipur</option> */}
              <option value="Sikar">Sikar</option>
            </Form.Select>
          </div>
          <div className="col-3">
            <Form.Select
              aria-label="Default select example"
              className="bg_lightgray"
            >
              <option> FullStack Web Development</option>
              <option value="Web Development">Web Development</option>
              <option value="back end Development">backend Development</option>
              <option value="back end Development">Frontend Development</option>
            </Form.Select>
          </div>
          <div className="col-3">
            <Form.Select
              aria-label="Default select example"
              className="bg_lightgray"
            >
              <option> Online</option>
              <option value=" Ofline">Offline</option>
            </Form.Select>
          </div>
        </div>
        {courses?.length === 0 ? (
          <p className="fs_15 ff_heebo fw-medium py-5 text-center">
            No data found.
          </p>
        ) : (
          <>
            <div className="row mt-4 ">
              {courses.slice(0, visibleCourses)?.map((course, i) => {
                const convertedDate = convertDate(course.createdAt);
                return (
                  <div key={i} className="col-xl-4 col-md-6 mt-4 col-12">
                    <div className="centers_card h-100 bg_lightblue p-4 rounded-2">
                      <div class="d-flex flex-column justify-content-between py-3 gap-3">
                        <div>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center gap-1">
                              <span className=" fs_22">
                                <IoLocationOutline />
                              </span>
                              <span className="fs_16 pt-2 fw-medium ff_heebo color_blue">
                                {course.location}
                              </span>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                              <button className=" bg_darkblue px-2 py-1 rounded-1 fs_12 ff_heebo text-white border-0 fw-bold">
                                {course.type}
                              </button>
                              {/* <button className="px-3 py-1 fs_12 ff_heebo text-white border-0 rounded bg_red fw-bold">
                        LIVE
                      </button> */}
                            </div>
                          </div>

                          <div className="mt-4">
                            <h1
                              style={{
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                WebkitLineClamp: 1,
                                cursor: "pointer",
                                position: "relative",
                              }}
                              title={course.title}
                              className="ff_heebo text-start fs_24 fw-bold course_title"
                            >
                              {course.title}
                            </h1>
                            <span className="ff_heebo">
                              Schedule: {course?.timing}
                              {/* {convertedDate?.time} */}
                            </span>
                          </div>
                          <div className="mt-2">
                            <div className="bg_darkblue  rounded p-3 d-flex gap-2">
                              <div>
                                {/* <img
                                  src={teacherImage}
                                  width={50}
                                  height={50}
                                  className="rounded"
                                /> */}
                                <img
                                  width={70}
                                  src={`https://api.koduapp.com/public/images/${course?.trainer_id?.image}`}
                                  alt="hello"
                                />
                              </div>
                              <div>
                                <div>
                                  <span className="text-white ff_heebo fs_12">
                                    Instructors
                                  </span>
                                </div>
                                <div>
                                  <span className="color_yellow ff_heebo fs_17 fw-bolder">
                                    {course?.trainer_id?.name}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{ height: "200px" }}
                            className="row overflow-y-auto  mt-4"
                          >
                            {course?.features?.map((item, index) => {
                              return (
                                <div className="col-6  gap-2" key={index}>
                                  <div className="d-flex gap-2">
                                    <div>
                                      <img src={playbutton} />
                                    </div>
                                    <div>
                                      <span className="fs_12 ff_heebo ">
                                        {item}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                        <div className="d-flex w-100 justify-content-between align-items-center mt-2">
                          <div className="d-flex flex-column">
                            <span className="fs_15 ff_heebo gothrow_price">
                              ₹{course.price}
                            </span>
                            <span className="fs_29 fw-bolder color_blue ff_heebo">
                              ₹{course.discount}
                            </span>
                            <span className="fs_15 ff_heebo">
                              {course.validity} Months
                            </span>
                          </div>
                          <div>
                            <Link to={`/course-details/${course._id}`}>
                              <button className="btn bg_darkblue ff_heebo text-white px-5 py-2">
                                Enroll
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div>
                        <button class="Free_btn border-0 fs_14 fw-medium ff_heebo color_lightwhite my-3 w-100">
                          Get Counseling
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="text-end mt-4">
              {visibleCourses < courses?.length ? (
                <button
                  className="bg_darkblue px-4 py-2 rounded-2 border-0 text-white fw-medium fs_16"
                  onClick={handleSeeMore}
                >
                  See More
                </button>
              ) : (
                <button
                  className="bg_darkblue px-4 py-2 rounded-2 border-0 text-white fw-medium fs_16"
                  onClick={handleSeeLess}
                >
                  See Less
                </button>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ClassroomBatches;
