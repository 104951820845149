import React from "react";
import Slider from "react-slick";
import slider_image from "../../assets/images/png/slider_iage.png";
import icon from "../../assets/images/png/icon.png";
import icon1 from "../../assets/images/png/green_icon.png";
import icon2 from "../../assets/images/png/blue_icon.png";
import icon3 from "../../assets/images/png/pink_icon1.png";
import rounded_image from "../../assets/images/png/rounded_image.jpg";
import green_shadow from "../../assets/images/png/green_shadow.png";
import pink_shadow from "../../assets/images/png/pink_shadow.png";
import rounded_icon from "../../assets/images/png/rounded_icon.png";
import skill_icon from "../../assets/images/png/skill_icon1.png";
import poster from "../../assets/images/png/poster.png";
import poster1 from "../../assets/images/png/poster1.png";
import poster2 from "../../assets/images/png/poster2.png";
import poster3 from "../../assets/images/png/poster3.png";
import poster4 from "../../assets/images/png/poster4.png";
import { Link } from "react-router-dom";

const CodingSkills = () => {
  var settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000,
  };

  return (
    <div className="py-5 pt-sm-5 pt-0 position-relative overflow-hidden ">
      <img
        className=" position-absolute custom_bottom_space end-0"
        src={green_shadow}
        alt="green_shadow"
      />
      <img
        className="position-absolute custom_bottom_space start-0"
        src={pink_shadow}
        alt="green_shadow"
      />
      <div className="container">
        <Slider {...settings}>
          <Link to="/scholarshipPage-page">
            <img className="w-100 px-3" src={poster3} alt="poster" />
          </Link>
          <Link to="/centers">
            <img className="w-100 px-3" src={poster1} alt="poster" />
          </Link>
          <Link to="/course">
            <img className="w-100 px-3" src={poster2} alt="poster" />
          </Link>
          <Link to="/recorded-courses">
            <img className="w-100 mt-4 px-3" src={poster4} alt="poster" />
          </Link>
          {/* <Link to="/webinars-page">
            <img className="w-100 px-3 mt-4" src={poster} alt="poster" />
          </Link> */}

          {/* <div className="bg_darkyellow  rounded-4 ">
            <div className="row py-3 px-sm-5 px-3 align-items-center position-relative">
              <div className="col-lg-5">
                <img
                  className="custom_slider_image"
                  //   width={250}
                  src={slider_image}
                  alt="slider_image"
                />
              </div>
              <div className="col-lg-7  mt-4">
                <button className="bg_darkblue py-2 px-4 border-0 rounded-2 fs_17 ff_heebo text-white">
                  Maha Webinar 2.0
                </button>
                <h3 className=" ff_redrose fw-bold text-white fs_37 mb-1 mt-2">
                  Build, Create, Innovate
                </h3>
                <h6 className=" ff_redrose fw-bold color_darkgray fs_27 mb-0">
                  Learn Coding Skills
                </h6>
              </div>
            </div>
          </div>
          <div className="bg_darkyellow rounded-4">
            <div className="row py-3 px-sm-5 px-3 align-items-center position-relative">
              <div className="col-lg-5">
                <img
                  className="custom_slider_image"
                  //   width={250}
                  src={slider_image}
                  alt="slider_image"
                />
              </div>
              <div className="col-lg-7 mt-4">
                <button className="bg_darkblue py-2 px-4 border-0 rounded-2 fs_17 ff_heebo text-white">
                  Maha Webinar 2.0
                </button>
                <h3 className=" ff_redrose fw-bold text-white fs_37 mb-1 mt-2">
                  Build, Create, Innovate
                </h3>
                <h6 className=" ff_redrose fw-bold color_darkgray fs_27 mb-0">
                  Learn Coding Skills
                </h6>
              </div>
            </div>
          </div>
          <div className="bg_darkyellow rounded-4">
            <div className="row py-3 px-sm-5 px-3 align-items-center position-relative">
              <div className="col-lg-5">
                <img
                  className="custom_slider_image"
                  //   width={250}
                  src={slider_image}
                  alt="slider_image"
                />
              </div>
              <div className="col-lg-7 mt-4">
                <button className="bg_darkblue py-2 px-4 border-0 rounded-2 fs_17 ff_heebo text-white">
                  Maha Webinar 2.0
                </button>
                <h3 className=" ff_redrose fw-bold text-white fs_37 mb-1 mt-2">
                  Build, Create, Innovate
                </h3>
                <h6 className=" ff_redrose fw-bold color_darkgray fs_27 mb-0">
                  Learn Coding Skills
                </h6>
              </div>
            </div>
          </div> */}
        </Slider>
        <div className="row align-items-center py-5 mt-lg-5">
          <div className="col-md-6 mt-4 mt-md-0">
            <div className=" position-relative">
              <img
                width={30}
                className=" position-absolute  top-0 custom_end_space "
                src={skill_icon}
                alt="rounded_image"
              />
              <img
                className="custom_width rounded-5 z_index position-absolute top-50 translate-middle start-50"
                src={rounded_image}
                alt="rounded_image"
              />
              <div className="row justify-content-center">
                <div className="col-xl-4  col-lg-6 col-6 mt-4">
                  <div className="bg_lightyellow z p-4 py-sm-5 custom_rounded">
                    <div className="d-flex align-items-center">
                      <img width={24} src={icon} alt="icon" />
                      <p className=" ff_heebo fw-bold fs_22 color_yellow mb-0">
                        50+
                      </p>
                    </div>
                    <p className=" ff_heebo fs_17 color_darkgray mb-0">
                      IT post-graduate teachers
                    </p>
                  </div>
                </div>
                <div className="col-xl-4  col-lg-6 col-6">
                  <div className=" bg_lightgreen p-4 ps-sm-5 py-sm-5 rounded-2 mt-5">
                    <div className="d-flex align-items-center">
                      <img width={24} src={icon1} alt="icon" />
                      <p className=" ff_heebo fw-bold fs_22 color_green mb-0">
                        10+
                      </p>
                    </div>
                    <p className=" ff_heebo fs_17 color_darkgray mb-0">
                      Programming Language
                    </p>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-xl-4  col-lg-6 col-6">
                  <div className=" bg_lightblue p-4 mt-4  py-sm-5  rounded-2">
                    <div className="d-flex align-items-center">
                      <img width={24} src={icon2} alt="icon" />
                      <p className=" ff_heebo fw-bold fs_22 color_blue mb-0">
                        5+
                      </p>
                    </div>
                    <p className=" ff_heebo fs_17 color_darkgray mb-0">
                      Coding Industry Experts
                    </p>
                  </div>
                </div>
                <div className="col-xl-4  col-lg-6 col-6">
                  <div className=" bg_lightpink p-4 py-sm-5  mt-4 custom_bottom_rounded">
                    <div className="d-flex align-items-center">
                      <img width={24} src={icon3} alt="icon" />
                      <p className=" ff_heebo fw-bold fs_22 color_pink mb-0">
                        1:1
                      </p>
                    </div>
                    <p className=" ff_heebo fs_17 color_darkgray mb-0">
                      Sessions For Classroom & Live Programmes
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-4 mt-md-0 position-relative">
            <img
              width={60}
              className=" position-absolute custom_skill_icon "
              src={rounded_icon}
              alt="rounded_image"
            />
            <h4 className=" fw-bold ff_heebo fs_29 color_darkgray mb-0">
              Why Choose Kodu Coding Programme for your Coding Journey?
            </h4>
            <p className="fs_15 ff_heebo fw-normal color_darkgray mt-3 mb-0">
              <i className="fw-bold">
                Project-Based Learning & Job Placement Assistance for Real-World
                Success
              </i>
              &nbsp;Our coding institute provides access to a wealth of
              continuous learning resources, including workshops, webinars,
              coding challenges and online forums to support students' ongoing
              growth and development which bring them one step closer in
              cracking their dream jobs in IT industry.
            </p>
            <div className="d-flex mt-4 align-items-center gap-3">
              <button className=" fs_15 px-4 ff_heebo fw-medium color_lightwhite Free_btn border-0 mt-sm-1">
                Try a Free Class
              </button>
              <Link to="/scholarshipPage-page">
                <button className=" fs_15 ff_heebo rounded-1 fw-medium color_lightwhite bg_darkblue px-4 py-2 border-0 mt-sm-1">
                  Free Scholarship Test
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CodingSkills;
