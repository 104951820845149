import React from "react";
import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const MasterFooter = () => {
  return (
    <div className=" bg_darkblue pt-5 pb-4">
      <Container>
        <h4 className="text-center ff_heebo text-white">Our Centres</h4>
        <div className="d-flex align-items-center justify-content-evenly mt-4">
          <span className=" fw-medium cursor_pointer text-white ff_heebo ">
            Hisar
          </span>
          <span className=" fw-medium cursor_pointer text-white ff_heebo ">
            Sirsa
          </span>
          <span className=" fw-medium cursor_pointer text-white ff_heebo ">
            Rohtak
          </span>
          {/* <span className=" fw-medium cursor_pointer text-white ff_heebo ">
            Jaipur
          </span> */}
          <span className=" fw-medium cursor_pointer text-white ff_heebo ">
            Sikar
          </span>
        </div>
      </Container>
      <div className="custom_border my-4"></div>
      <p className="text-center mb-0 text-white">
        Copyright © Kodu. All Rights Reserved
      </p>
      <p className="text-c pb-5 pb-md-0 color_yellow mt-2 text-center">
        MADE WITH ❤️ IN INDIA
      </p>
    </div>
  );
};

export default MasterFooter;
