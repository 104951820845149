import React from "react";
import icon from "../../assets/images/png/Features_icon.png";
import icon1 from "../../assets/images/png/Features_icon1.png";
import icon2 from "../../assets/images/png/Features_icon2.png";
import icon3 from "../../assets/images/png/Features_icon3.png";
import icon4 from "../../assets/images/png/Features_icon4.png";
import icon5 from "../../assets/images/png/Features_icon5.png";

const WhyHireFromKodu = () => {
  return (
    <div className="py-5">
      <div className="container">
        <h3 className=" fs_37 fw-bold ff_heebo color_blue">
          <span className=" color_yellow">Why Hire our </span> Graduates ?
        </h3>
        <p className=" fs_24 ff_heebo color_blue mb-0">
          Superb talent for multiple roles, all at one place
        </p>
        <div className="row mt-4">
          <div className="col-lg-3 col-md-4 col-sm-6 mt-4">
            <div className="features_box h-100 cursor_pointer p-4 rounded-2">
              <img width={45} src={icon} alt="icon" />
              <p className="fs_22 fw-medium ff_heebo color_darkgray mt-3 mb-0">
                Innovative Problem Solvers
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 mt-4">
            <div className="features_box h-100 cursor_pointer p-4 rounded-2">
              <img width={45} src={icon1} alt="icon" />
              <p className="fs_22 fw-medium ff_heebo color_darkgray mt-3 mb-0">
                Industry-Ready Skills
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 mt-4">
            <div className="features_box h-100 cursor_pointer p-4 rounded-2">
              <img width={45} src={icon2} alt="icon" />
              <p className="fs_22 fw-medium ff_heebo color_darkgray mt-3 mb-0">
                Real-World Experience
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 mt-4">
            <div className="features_box h-100 cursor_pointer p-4 rounded-2">
              <img width={45} src={icon3} alt="icon" />
              <p className="fs_22 fw-medium ff_heebo color_darkgray mt-3 mb-0">
                Adaptability and Growth Mindset
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 mt-4">
            <div className="features_box h-100 cursor_pointer p-4 rounded-2">
              <img width={45} src={icon4} alt="icon" />
              <p className="fs_22 fw-medium ff_heebo color_darkgray mt-3 mb-0">
                Team Players and Leaders
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 mt-4">
            <div className="features_box h-100 cursor_pointer p-4 rounded-2">
              <img width={45} src={icon5} alt="icon" />
              <p className="fs_22 fw-medium ff_heebo color_darkgray mt-3 mb-0">
                Hassle free hiring process
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyHireFromKodu;
