import React from "react";
import { Accordion } from "react-bootstrap";

const ScholarshipFaq = () => {
  return (
    <div className="container mb-5 mt-3">
      <h1 className="ff_heebo text-center fs_29 mb-4">FAQs</h1>
      <div>
        <Accordion>
          <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="0">
            <Accordion.Header className=" fs_17 ff_heebo color_darkgray courseFagbg">
              <li className=" custom_list_style_type color_blue"></li>
              What is the Kodu Coding Scholarship Test?
            </Accordion.Header>
            <Accordion.Body className="px-5 py-3">
              The Kodu Coding Scholarship Test is a special exam we've created
              to find students who are really good at or interested in coding,
              programming, and AI (Artificial Intelligence). If you do well on
              this test, you could get a scholarship from Kodu that pays for up
              to 90% of your education in these fields. sunt in culpa qui
              officia deserunt mollit anim id est laborum.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="1">
            <Accordion.Header className=" fs_17 ff_heebo color_darkgray courseFagbg">
              <li className=" custom_list_style_type color_blue"></li>
              Who can apply for the scholarship?
            </Accordion.Header>
            <Accordion.Body className="px-5 py-3">
              Any student who loves coding and wants to build a career in
              coding, programming, or AI is welcome to apply. You should be
              ready to take a test to show us how much you know and how eager
              you are to learn more.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="2">
            <Accordion.Header className=" fs_17 ff_heebo color_darkgray courseFagbg">
              <li className=" custom_list_style_type color_blue"></li>. How much
              scholarship can I get?
            </Accordion.Header>
            <Accordion.Body className="px-5 py-3">
              Depending on how well you do on the test, you could get a
              scholarship that covers up to 90% of your tuition fees. This means
              you might only have to pay for a small part of your education
              costs.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="3">
            <Accordion.Header className=" fs_17 ff_heebo color_darkgray courseFagbg">
              <li className=" custom_list_style_type color_blue"></li>. What
              does the scholarship cover?
            </Accordion.Header>
            <Accordion.Body className="px-5 py-3">
              The scholarship can be used to pay for your tuition fees. It's
              designed to make learning about coding, programming, and AI more
              affordable for talented students.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="4">
            <Accordion.Header className=" fs_17 ff_heebo color_darkgray courseFagbg">
              <li className=" custom_list_style_type color_blue"></li>
              How do I apply for the scholarship test?
            </Accordion.Header>
            <Accordion.Body className="px-5 py-3">
              To apply, you'll need to fill out a form on our website with some
              basic information about yourself. After you submit the form, we'll
              tell you what to do next to get ready for the test.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="5">
            <Accordion.Header className=" fs_17 ff_heebo color_darkgray courseFagbg">
              <li className=" custom_list_style_type color_blue"></li>
              When is the scholarship test happening?
            </Accordion.Header>
            <Accordion.Body className="px-5 py-3">
              The test dates are announced on our website. Make sure to check
              there for the most up-to-date information and to register before
              the deadline.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="6">
            <Accordion.Header className=" fs_17 ff_heebo color_darkgray courseFagbg">
              <li className=" custom_list_style_type color_blue"></li>
              What type of questions will be on the test?
            </Accordion.Header>
            <Accordion.Body className="px-5 py-3">
              The test will have questions about coding, programming logic, and
              basic Aptitude MCQ’s.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="7">
            <Accordion.Header className=" fs_17 ff_heebo color_darkgray courseFagbg">
              <li className=" custom_list_style_type color_blue"></li>
              How can I prepare for the test?
            </Accordion.Header>
            <Accordion.Body className="px-5 py-3">
              We recommend reviewing your coding skills, especially in the
              programming languages you're most comfortable with. Also, look
              into basic AI principles and practice problem-solving.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="8">
            <Accordion.Header className=" fs_17 ff_heebo color_darkgray courseFagbg">
              <li className=" custom_list_style_type color_blue"></li>
              Is there a fee to take the scholarship test?
            </Accordion.Header>
            <Accordion.Body className="px-5 py-3">
              No, there's no fee to take the test. Our goal is to make sure
              talented students have the chance to show their skills without
              worrying about test fees.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="9">
            <Accordion.Header className=" fs_17 ff_heebo color_darkgray courseFagbg">
              <li className=" custom_list_style_type color_blue"></li>
              What happens after I take the test?
            </Accordion.Header>
            <Accordion.Body className="px-5 py-3">
              We'll grade your test and let you know how you did. If you score
              high enough, we'll get in touch with details on how to claim your
              scholarship.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default ScholarshipFaq;
