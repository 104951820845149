import React, { useEffect } from "react";
import QuizHero from "../quizPage/QuizHero";
import QuizFaq from "../quizPage/QuizFaq";

const QuizPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <QuizHero />
      {/* <QuizFaq /> */}
    </div>
  );
};

export default QuizPage;
