import React, { useCallback, useEffect, useState } from "react";
import Hero from "../homePage/Hero";
import CodingSkills from "../homePage/CodingSkills";
import Curriculum from "../homePage/Curriculum";
import Features from "../homePage/Features";
import CodingCourses from "../homePage/CodingCourses";
import Faqs from "../homePage/Faqs";
import CareerGrowth from "../homePage/CareerGrowth";
import AmazingProjects from "../homePage/AmazingProjects";
import Reviews from "../homePage/Reviews";
import CareerServices from "../homePage/CareerServices";
import Scholarship from "../homePage/Scholarship";
import EnrollCourses from "../homePage/EnrollCourses";
import Footer from "../common/Footer";
import makeApiCall from "../callApi";
import Job from "../homePage/Job";

const HomePage = () => {
  const [courses, SetCourses] = useState([]);

  useEffect(() => {
    makeApiCall("", "post", "home", home_data);
  }, []);

  const home_data = useCallback((response) => {
    console.log(response);
    // setMainLoading(false);
    if (response.status === 200) {
      SetCourses(response.data.data);
      console.log(response.data.data[0]);
    } else {
      console.log("error");
    }
  });

  return (
    <div>
      <Hero />
      <CodingSkills />
      <Curriculum />
      <Features />
      <CodingCourses courses={courses} />
      <Job />
      {/* <FreeSession /> */}
      <Faqs />
      <CareerGrowth />
      <AmazingProjects />
      <Reviews />
      <CareerServices />
      {/* <Scholarship /> */}
      <EnrollCourses />
    </div>
  );
};

export default HomePage;
