import React from "react";

const Privacypolicy = () => {
  return (
    <div className="py-5">
      <div className="container">
        <h1 className="fw-bold ff_heebo color_yellow">Privacy Policy</h1>
        <h4 className="mt-5 fs_26 ff_heebo color_blue">Kodu Privacy Policy</h4>
        <p className="fs_18 fw-normal ff_heebo">
          This Privacy Policy describes how your personal information is
          collected, used, and shared when you visit or make a purchase from
          https://koduapp.com/ (the “Site”).
        </p>
        <h4 className="mt-5 fs_26 ff_heebo color_blue">
          PERSONAL INFORMATION WE COLLECT
        </h4>
        <p className="fs_18 fw-normal ff_heebo">
          When you visit the Site, we automatically collect certain information
          about your device, including information about your web browser, IP
          address, time zone, and some of the cookies that are installed on your
          device. Additionally, as you browse the Site, we collect information
          about the individual web pages or products that you view, what
          websites or search terms referred you to the Site, and information
          about how you interact with the Site. We refer to this
          automatically-collected information as “Device Information.”
        </p>
        <strong>
          We collect Device Information using the following technologies:
        </strong>
        <p className="fs_18 fw-normal ff_heebo mt-2">
          – “Cookies” are data files that are placed on your device or computer
          and often include an anonymous unique identifier. For more information
          about cookies, and how to disable cookies, visit
        </p>
        <a
          target="_blanck colo_blue"
          href="http://www.allaboutcookies.org.
"
        >
          http://www.allaboutcookies.org.
        </a>
        <p className="fs_18 fw-normal ff_heebo mt-2">
          – “Log files” track actions occurring on the Site, and collect data
          including your IP address, browser type, Internet service provider,
          referring/exit pages, and date/time stamps.
        </p>
        <p className="fs_18 fw-normal ff_heebo mt-2">
          – “Web beacons,” “tags,” and “pixels” are electronic files used to
          record information about how you browse the Site.
        </p>
        <p className="fs_18 fw-normal ff_heebo mt-2">
          Additionally when you make a purchase or attempt to make a purchase
          through the Site, we collect certain information from you, including
          your name, billing address, shipping address, payment information
          including credit card numbers , email address, and phone number. We
          refer to this information as “Order Information.”
        </p>
        <p className="fs_18 fw-normal ff_heebo mt-2">
          When we talk about “Personal Information” in this Privacy Policy, we
          are talking both about Device Information and Order Information.
        </p>
        <h4 className="mt-5 fs_26 ff_heebo color_blue">
          HOW DO WE USE YOUR PERSONAL INFORMATION?
        </h4>
        <p className="fs_18 fw-normal ff_heebo">
          We use the Order Information that we collect generally to fulfill any
          orders placed through the Site (including processing your payment
          information, arranging for shipping, and providing you with invoices
          and/or order confirmations). Additionally, we use this Order
          Information to: Communicate with you; Screen our orders for potential
          risk or fraud; and When in line with the preferences you have shared
          with us, provide you with information or advertising relating to our
          products or services.
        </p>
        <p className="fs_18 fw-normal ff_heebo">
          We use the Device Information that we collect to help us screen for
          potential risk and fraud (in particular, your IP address), and more
          generally to improve and optimize our Site (for example, by generating
          analytics about how our customers browse and interact with the Site,
          and to assess the success of our marketing and advertising campaigns).
        </p>
        <h4 className="mt-5 fs_26 ff_heebo color_blue">
          SHARING YOUR PERSONAL INFORMATION
        </h4>
        <p className="fs_18 fw-normal ff_heebo">
          We share your Personal Information with third parties to help us use
          your Personal Information, as described above. We also use Google
          Analytics to help us understand how our customers use the Site–you can
          read more about how Google uses your Personal Information here:
        </p>
        <p className="mb-0">
          {" "}
          <a
            target="_blanck colo_blue"
            href="http://www.allaboutcookies.org.
"
          >
            https://www.google.com/intl/en/policies/privacy/.
          </a>
          You can also opt-out of Google Analytics here:
        </p>
        <a
          target="_blanck colo_blue"
          href="http://www.allaboutcookies.org.
"
        >
          https://tools.google.com/dlpage/gaoptout
        </a>
        <p className="fs_18 fw-normal ff_heebo mt-3">
          Finally, we may also share your Personal Information to comply with
          applicable laws and regulations, to respond to a subpoena, search
          warrant or other lawful request for information we receive, or to
          otherwise protect our rights.
        </p>
        <h4 className="mt-5 fs_26 ff_heebo color_blue">
          BEHAVIOURAL ADVERTISING
        </h4>
        <p className="fs_18 fw-normal ff_heebo">
          As described above, we use your Personal Information to provide you
          with targeted advertisements or marketing communications we believe
          may be of interest to you. For more information about how targeted
          advertising works, you can visit the Network Advertising Initiative’s
          (“NAI”) educational page at
          <a
            target="_blanck"
            href=" http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work."
          >
            {" "}
            http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
          </a>
        </p>
        <p className="fs_18 fw-normal ff_heebo">
          You can opt out of targeted advertising by:
        </p>
        <p className="fs_18 fw-normal ff_heebo">
          INCLUDE OPT-OUT LINKS FROM WHICHEVER SERVICES BEING USED. COMMON LINKS
          INCLUDE:
        </p>
        <p className="fs_18 fw-normal ff_heebo">
          FACEBOOK –&nbsp;
          <a href=" https://www.facebook.com/koduapp">
            https://www.facebook.com/koduapp
          </a>
        </p>
        <p className="fs_18 fw-normal ff_heebo">
          INSTAGRAM –&nbsp;
          <a href=" https://www.instagram.com/kodubydhurina">
            https://www.instagram.com/kodubydhurina
          </a>
        </p>

        <p className="fs_18 fw-normal ff_heebo">
          Additionally, you can opt out of some of these services by visiting
          the Digital Advertising Alliance’s opt-out portal at:
          http://optout.aboutads.info/.
        </p>
        <h4 className="mt-5 fs_26 ff_heebo color_blue">DO NOT TRACK</h4>
        <p className="fs_18 fw-normal ff_heebo">
          Please note that we do not alter our Site’s data collection and use
          practices when we see a Do Not Track signal from your browser.
        </p>
        <h4 className="mt-5 fs_26 ff_heebo color_blue">DATA RETENTION</h4>
        <p className="fs_18 fw-normal ff_heebo">
          When you place an order through the Site, we will maintain your Order
          Information for our records unless and until you ask us to delete this
          information.
        </p>
        <h4 className="mt-5 fs_26 ff_heebo color_blue">CHANGES</h4>
        <p className="fs_18 fw-normal ff_heebo">
          We may update this privacy policy from time to time in order to
          reflect, for example, changes to our practices or for other
          operational, legal or regulatory reasons.
        </p>
      </div>
    </div>
  );
};

export default Privacypolicy;
