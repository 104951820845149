import React from "react";
import amico1 from "../../assets/images/png/reacorded_img.png";

const RecordedCoursesHero = () => {
  return (
    <div className="py-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-7 ">
            <h3 className=" fs_43 fw-bold ff_heebo color_blue">
              Learn&nbsp;
              <span className=" color_yellow">
                Web <br className="d-xl-block d-none"></br>Development MERN
                Stack
              </span>
              , effectively for <span className=" color_yellow">FREE</span>
            </h3>
            <p className=" fs_24 fw-normal ff_heebo mt-3">
              By getting 100% of your fees back on course
              <br className="d-xl-block d-none"></br> completion
            </p>
            <div className="d-sm-flex mt-3 align-items-start gap-4">
              <h3 className=" fs_37 fw-bold color_blue ff_inter">₹ 699</h3>
              <p className="mb-0">
                Pay now & get it back on course{" "}
                <br className="d-md-block d-none"></br> completion
              </p>
            </div>
            <button className=" mt-5 bg_yellow px-5 py-2 fs_17 fw-medium ff_heebo text-white border-0 rounded-1">
              Enroll Now
            </button>
          </div>
          <div className="col-md-5 mt-4 mt-md-0">
            <img className="w-100" src={amico1} alt="amico2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecordedCoursesHero;
