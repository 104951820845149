import React, { useState } from "react";
import { Accordion } from "react-bootstrap";

const Faqs = () => {
  const [selectedButton, setSelectedButton] = useState(1);
  const [selectedData, setSelectedData] = useState(null);

  const handleButtonClick = (buttonId, newData) => {
    setSelectedButton(buttonId);
    setSelectedData(newData);
  };
  return (
    <div className="py-5">
      <div className="container">
        <h4 className=" text-center fs_29 fw-medium ff_heebo text-black mb-0">
          Frequently Asked Question's
        </h4>
        <div className=" ">
          <div className="row mb-5">
            <div className="col-sm-4 mt-4">
              <button
                className={`w-100 border-1 py-2 rounded-2 fs_16 ff_heebo fw-medium ${
                  selectedButton === 1
                    ? "bg_darkblue color_lightwhite"
                    : "bg-transparent color_darkgray"
                }`}
                onClick={() => handleButtonClick(1)}
              >
                General info
              </button>
            </div>
            <div className="col-sm-4 mt-4">
              <button
                className={`w-100 border-1 py-2 rounded-2 fs_16 ff_heebo fw-medium ${
                  selectedButton === 2
                    ? "bg_darkblue color_lightwhite"
                    : "bg-transparent color_darkgray"
                }`}
                onClick={() => handleButtonClick(2)}
              >
                Our coding courses
              </button>
            </div>
            <div className="col-sm-4 mt-4">
              <button
                className={`w-100 border-1 py-2 rounded-2 fs_16 ff_heebo fw-medium ${
                  selectedButton === 3
                    ? "bg_darkblue color_lightwhite"
                    : "bg-transparent color_darkgray"
                }`}
                onClick={() => handleButtonClick(3)}
              >
                Why choose kodu
              </button>
            </div>
          </div>
          {selectedButton == 1 ? (
            <>
              {" "}
              <Accordion defaultActiveKey="0">
                <Accordion.Item
                  className="mt-2 border-0 rounded-0"
                  eventKey="0"
                >
                  <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                    <li className=" custom_list_style_type color_yellow"></li>
                    Can I join Kodu’s courses while studying in college?
                  </Accordion.Header>
                  <Accordion.Body className="px-5 pb-3">
                    Yes, you can join Kodu’s courses while studying in college.
                    Our courses are designed to be flexible and can complement
                    your college education by enhancing your skills in software
                    development and programming.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item
                  className="mt-2 border-0 rounded-0"
                  eventKey="1"
                >
                  <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                    <li className=" custom_list_style_type color_yellow"></li>
                    How many classes can I miss?
                  </Accordion.Header>
                  <Accordion.Body className="px-5 pb-3">
                    We understand that life can get busy. While consistent
                    attendance is key to mastering the material, Kodu allows for
                    a limited number of absences. Please refer to our specific
                    course policies for the exact number of classes you can
                    miss.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item
                  className="mt-2 border-0 rounded-0"
                  eventKey="3"
                >
                  <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                    <li className=" custom_list_style_type color_yellow"></li>
                    I’m bad at maths. Can I be a software engineer?
                  </Accordion.Header>
                  <Accordion.Body className="px-5 pb-3">
                    Yes, you can become a software engineer even if you're not
                    strong in math. While some areas of software engineering
                    require mathematical skills, there are many aspects of
                    software development that rely more on problem-solving and
                    logic than on advanced math.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item
                  className="mt-2 border-0 rounded-0"
                  eventKey="4"
                >
                  <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                    <li className=" custom_list_style_type color_yellow"></li>
                    I’ve tried coding but find it difficult. Can I still be a
                    software engineer?
                  </Accordion.Header>
                  <Accordion.Body className="px-5 pb-3">
                    Absolutely. Coding can be challenging at first, but with
                    practice and persistence, it becomes easier. Kodu’s courses
                    are designed to help learners at all levels, from beginners
                    to advanced, develop their coding skills over time.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item
                  className="mt-2 border-0 rounded-0"
                  eventKey="5"
                >
                  <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                    <li className=" custom_list_style_type color_yellow"></li>
                    Do I need a Computer Science degree or diploma to be a
                    software engineer?
                  </Accordion.Header>
                  <Accordion.Body className="px-5 pb-3">
                    No, a Computer Science degree or diploma is not strictly
                    necessary to become a software engineer. Many successful
                    software engineers have learned through coding bootcamps,
                    self-study, or other alternative education paths.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item
                  className="mt-2 border-0 rounded-0"
                  eventKey="6"
                >
                  <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                    <li className=" custom_list_style_type color_yellow"></li>I
                    can code but struggle to get a job. Should I still join
                    Kodu?
                  </Accordion.Header>
                  <Accordion.Body className="px-5 pb-3">
                    Yes, joining Kodu can be beneficial. Our courses not only
                    refine your coding skills but also offer career guidance,
                    interview preparation, and networking opportunities to help
                    you secure a job in the tech industry.
                    <p className="mt-3">
                      What is the difference between software engineering,
                      programming, and coding?
                    </p>
                    <ul>
                      <li className=" custom_list_style_type">
                        Software Engineering involves the application of
                        engineering principles to the design, development,
                        maintenance, testing, and evaluation of software and
                        systems.
                      </li>
                      <li className=" custom_list_style_type">
                        Programming is the process of creating a set of
                        instructions that tell a computer how to perform a task.
                      </li>
                      <li className=" custom_list_style_type">
                        Coding is the act of writing code, or the actual lines
                        of text that are written by a programmer.
                      </li>
                    </ul>
                    <p className="mt-3">
                      What is the difference between software engineering, data
                      science, and data analytics?
                    </p>
                    <ul>
                      <li className=" custom_list_style_type">
                        Software Engineering focuses on designing and building
                        software applications.
                      </li>
                      <li className=" custom_list_style_type">
                        Data Science involves using algorithms, data analysis,
                        and machine learning techniques to derive insights and
                        predictions from data.
                      </li>
                      <li className=" custom_list_style_type">
                        Data Analytics is more about analyzing data sets to
                        identify trends and insights, often for the purpose of
                        business decision-making.
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </>
          ) : (
            ""
          )}
          <div>
            {selectedButton == 2 ? (
              <>
                {" "}
                <Accordion defaultActiveKey="0">
                  <Accordion.Item
                    className="mt-2 border-0 rounded-0"
                    eventKey="0"
                  >
                    <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                      <li className=" custom_list_style_type color_yellow"></li>
                      What is the total fee for the Advanced Software
                      Development course?
                    </Accordion.Header>
                    <Accordion.Body className="px-5 pb-3">
                      The total program fee for this course is , INR 1,00,000 +
                      18% GST
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    className="mt-2 border-0 rounded-0"
                    eventKey="1"
                  >
                    <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                      <li className=" custom_list_style_type color_yellow"></li>
                      Can I pay in installments for the course fees?
                    </Accordion.Header>
                    <Accordion.Body className="px-5 pb-3">
                      Yes, you can pay the installments with a no-fee EMI option
                      for INR ₹20,000/month up to 6 months.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    className="mt-2 border-0 rounded-0"
                    eventKey="2"
                  >
                    <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                      <li className=" custom_list_style_type color_yellow"></li>
                      Is there any scholarship/discount available for the
                      advanced Software Development course? What is Group
                      Discount?
                    </Accordion.Header>
                    <Accordion.Body className="px-5 pb-3">
                      Kodu offers a 100% scholarship for all career
                      enthusiastic. Please check our websites or contact our
                      sales team to know more about the applicable discounts.
                      Group Discount : Group Discount is applicable only if
                      you’re joining with your friends.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    className="mt-2 border-0 rounded-0"
                    eventKey="3"
                  >
                    <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                      <li className=" custom_list_style_type color_yellow"></li>
                      How many real-time projects will be there?
                    </Accordion.Header>
                    <Accordion.Body className="px-5 pb-3">
                      We have a set of 6 real-time projects from multiple
                      domains.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    className="mt-2 border-0 rounded-0"
                    eventKey="4"
                  >
                    <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                      <li className=" custom_list_style_type color_yellow"></li>
                      When is the job assistance provided at Kodu?
                    </Accordion.Header>
                    <Accordion.Body className="px-5 pb-3">
                      At Kodu, after the course is over, placement Assistance is
                      offered. It consists of interview preparation workshops,
                      mock interviews, and recommendations from leading MNCs and
                      startups. Unless a person is placed in a corporation, this
                      service will be offered.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    className="mt-2 border-0 rounded-0"
                    eventKey="5"
                  >
                    <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                      <li className=" custom_list_style_type color_yellow"></li>
                      How can I opt for a Hybrid mode of learning?
                    </Accordion.Header>
                    <Accordion.Body className="px-5 pb-3">
                      You have the flexibility to opt for online learning for
                      your theoretical classes and in-person classroom sessions
                      for your capstone projects and interview preparation. Once
                      you progress to the project modules, you'll need to attend
                      the Kodu authorized training centers located in your city.
                      Your dedicated counselor will provide guidance and support
                      throughout this process.{" "}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </>
            ) : (
              ""
            )}
          </div>
          <div>
            {selectedButton == 3 ? (
              <>
                {" "}
                <Accordion defaultActiveKey="0">
                  <Accordion.Item
                    className="mt-2 border-0 rounded-0"
                    eventKey="5"
                  >
                    <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                      <li className=" custom_list_style_type color_yellow"></li>
                      Why choose Kodu’s courses over other coding bootcamps?
                    </Accordion.Header>
                    <Accordion.Body className="px-5 pb-3">
                      Kodu distinguishes itself through a focus on individual
                      learning experiences, a curriculum aligned with industry
                      demands, and mentorship from seasoned professionals. Our
                      commitment to small class sizes ensures personalized
                      attention, fostering a conducive learning environment for
                      all students.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    className="mt-2 border-0 rounded-0"
                    eventKey="6"
                  >
                    <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                      <li className=" custom_list_style_type color_yellow"></li>
                      Why choose Kodu over university or polytechnic courses?
                    </Accordion.Header>
                    <Accordion.Body className="px-5 pb-3">
                      Kodu offers a more direct and concentrated route into
                      technology careers, emphasizing practical skills and
                      hands-on learning. Our programs are crafted to quickly
                      equip students with the necessary skills for employment,
                      making it an efficient alternative to longer, more
                      theoretical degree courses.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    className="mt-2 border-0 rounded-0"
                    eventKey="7"
                  >
                    <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                      <li className=" custom_list_style_type color_yellow"></li>
                      Why choose Kodu over self-learning?
                    </Accordion.Header>
                    <Accordion.Body className="px-5 pb-3">
                      While self-directed study offers flexibility, Kodu
                      provides a structured curriculum, accountability
                      mechanisms, and a supportive community, essential for
                      sustained learning and progress. Our platform facilitates
                      access to mentorship and networking opportunities that are
                      invaluable for career development.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    className="mt-2 border-0 rounded-0"
                    eventKey="8"
                  >
                    <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                      <li className=" custom_list_style_type color_yellow"></li>
                      Why choose Kodu’s Coding Fundamentals course?
                    </Accordion.Header>
                    <Accordion.Body className="px-5 pb-3">
                      The Coding Fundamentals course at Kodu is specially
                      designed for beginners and those seeking to strengthen
                      their foundational knowledge in coding. It delivers an
                      in-depth understanding of basic coding principles,
                      fostering critical thinking and problem-solving skills
                      vital for further technical studies.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    className="mt-2 border-0 rounded-0"
                    eventKey="9"
                  >
                    <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                      <li className=" custom_list_style_type color_yellow"></li>
                      Why choose Kodu’s Full Stack Development course?
                    </Accordion.Header>
                    <Accordion.Body className="px-5 pb-3">
                      Our Full Stack Development course prepares individuals to
                      become comprehensive developers, proficient in both
                      client-side and server-side programming. It integrates
                      project-based learning with the latest technological
                      practices, enabling students to develop complete web
                      solutions and build a professional portfolio.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
