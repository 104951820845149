import React from "react";
import { ReviewsData } from "../common/Helper";
import reviews_image4 from "../../assets/images/png/Reviews_image4.png";
import reviews_image5 from "../../assets/images/png/Reviews_image5.png";
import reviews_image6 from "../../assets/images/png/Reviews_image6.png";
import star_image from "../../assets/images/png/star_image.png";
function CourseReview() {
  return (
    <div className="container py-5 my-3">
      <h4 className=" fs_29 ff_heebo fw-medium text-black">
        Choose from the following courses
      </h4>
      <div className="row mt-md-4 ">
        {ReviewsData.map((obj, i) => {
          return (
            <>
              <div key={i} className="col-lg-4 col-sm-6 mt-4">
                <div className=" position-relative">
                  <img className="w-100" src={obj.image} alt="image" />
                  <div className="d-flex position-absolute bottom-0 mb-4 justify-content-between w-100 px-lg-4 px-2">
                    <h4 className=" fs_22 fw-medium text-white ff_heebo">
                      {obj.name}
                    </h4>
                    <button className=" border-0 px-4 fs_18 rounded-2 fw-medium text-black">
                      {obj.see_video}
                    </button>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
      <div className="row ">
        <div className="col-lg-4 col-sm-6 mt-4">
          <div className="  bg_lightyellow rounded-2 p-4 yellow_bottom_border">
            <div className="d-flex gap-3">
              <img src={reviews_image4} alt="reviews_image4" />
              <div>
                <h4 className="mb-0 fs_22 ff_heebo color_lightblack fw-medium">
                  Satya Parkash
                </h4>
                <img src={star_image} alt="star_image" />
              </div>
            </div>
            <p className=" mt-4 fs_14 ff_heebo color_darkgray">
              Joining this live coding course was one of the best decisions I've
              made for my career. The real-time interaction with instructors
              helped me overcome challenges on the spot, and the comprehensive
              curriculum on DSA and System Design has significantly improved my
              problem-solving skills. Highly recommend it to anyone serious
              about coding!
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6 mt-4">
          <div className="  bg_lightyellow rounded-2 p-4 yellow_bottom_border">
            <div className="d-flex gap-3">
              <img src={reviews_image5} alt="reviews_image4" />
              <div>
                <h4 className="mb-0 fs_22 ff_heebo color_lightblack fw-medium">
                  Suman
                </h4>
                <img src={star_image} alt="star_image" />
              </div>
            </div>
            <p className=" mt-4 fs_14 ff_heebo color_darkgray">
              As a student, balancing this course with my university studies was
              initially a concern, but the flexible schedule and the engaging
              nature of the live classes made it perfectly manageable. The
              interactive sessions and the supportive community have been
              invaluable for my growth as a developer.
            </p>
          </div>
        </div>
        <div className="col-lg-4 col-sm-6 mt-4">
          <div className="  bg_lightyellow rounded-2 p-4 yellow_bottom_border">
            <div className="d-flex gap-3">
              <img src={reviews_image6} alt="reviews_image4" />
              <div>
                <h4 className="mb-0 fs_22 ff_heebo color_lightblack fw-medium">
                  Rajveer
                </h4>
                <img src={star_image} alt="star_image" />
              </div>
            </div>
            <p className=" mt-4 fs_14 ff_heebo color_darkgray">
              I was completely new to coding when I started this course, and I
              couldn't be happier with the progress I've made. The live
              interactive sessions allowed me to ask questions and clarify
              doubts in real-time, and working on projects gave me the
              confidence and skills to pursue a career in data science.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseReview;
