import React, { useCallback, useEffect, useState } from "react";
import makeApiCall from "../callApi";
import crossblack_icon from "../../assets/images/png/blackcross_icon.png";
import edit_icon from "../../assets/images/png/edit_icon.png";
import delete_icon from "../../assets/images/png/delete_icon.png";
import { Modal } from "react-bootstrap";

const TestTab = () => {
  const [addTestModal, setAddTestModal] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [testData, setTestData] = useState({
    title: "",
    image: "",
    type: "scholarship",
  });
  const [newTestData, setNewTestData] = useState({
    title: "",
    type: "scholarship",
  });
  const [value, setValue] = useState("");
  const [newValue, setNewValue] = useState("");

  useEffect(() => {
    setNewTestData({
      id: currentData?._id,
      title: currentData?.title,
      image: currentData?.image,
      type: currentData?.type || "scholarship",
    });
    setNewValue(currentData?.description);
  }, [currentData]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setTestData((prevData) => ({
        ...prevData,
        [name]: files?.length > 0 ? files[0] : value,
      }));
    } else {
      setTestData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleInputChange2 = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setNewTestData((prevData) => ({
        ...prevData,
        [name]: files?.length > 0 ? files[0] : value,
      }));
    } else {
      setNewTestData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  async function createBlog(e) {
    e.preventDefault();

    const formdata = new FormData();
    formdata.append("title", testData.title);
    formdata.append("image", testData.image);
    formdata.append("type", testData.type);

    await makeApiCall(
      formdata,
      "post",
      "admin/updateTest",
      createblogCallBack,
      false
    );
  }

  const createblogCallBack = useCallback(async (res) => {
    console.log(res);
    if (res.data.status === "success") {
      setAddTestModal(false);
      setTestData({
        title: "",
        image: "",
        type: "Scholarship",
      });
      getTest();
    }
  }, []);

  async function updateBlog(e) {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("id", newTestData.id);
    formdata.append("title", newTestData.title);
    formdata.append("image", newTestData.image);
    formdata.append("type", newTestData.type);

    await makeApiCall(
      formdata,
      "post",
      "admin/updateTest",
      updateblogCallBack,
      false
    );
  }

  const updateblogCallBack = useCallback(async (res) => {
    console.log(res);
    if (res.data.status === "success") {
      setFreeTrialModalVisible(false);
      getTest();
    }
  }, []);

  useEffect(() => {
    console.log(testData);
  }, [testData]);

  useEffect(() => {
    console.log(value);
  }, [value]);

  const [testList, SetTestList] = useState([]);

  async function getTest() {
    await makeApiCall("", "get", "admin/TestList", testListCallBack);
  }

  useEffect(() => {
    getTest();
  }, []);

  const testListCallBack = useCallback((response) => {
    console.log(response);
    if (response.status === 200) {
      SetTestList(response.data.data);
    } else {
      console.log("error");
    }
  }, []);

  async function deleteCourse(id) {
    await makeApiCall("", "get", `admin/deleteTest/${id}`, testDeleteCallBack);
  }

  const testDeleteCallBack = useCallback(
    (response) => {
      console.log(response);
      if (response.status === 200) {
        console.log("Course deleted successfully");
        getTest();
      } else {
        console.error("Error deleting course");
      }
    },
    [getTest]
  );

  useEffect(() => {
    getTest();
  }, []);

  const [freeTrialModalVisible, setFreeTrialModalVisible] = useState(false);
  const toggleFreeTrialModal = () => {
    setFreeTrialModalVisible(!freeTrialModalVisible);
  };

  const handleResizeError = (e) => {
    if (e.message.includes("ResizeObserver")) {
      console.error("ResizeObserver error suppressed:", e);
      return;
    }
    throw e;
  };

  window.addEventListener("error", handleResizeError);

  return (
    <div>
      <h2 className=" fs_37 fw-semibold ff_heebo">Test</h2>
      <div className="border my-4"></div>

      {!addTestModal ? (
        <>
          <div className=" mb-4">
            <button
              onClick={() => setAddTestModal(true)}
              className=" bg_yellow py-2 px-4 border-0 ff_heebo text-white fw-bold"
            >
              ADD NEW TEST
            </button>
          </div>
          <div className="  border-0 ff_heebo pb-4">
            <div
              className="card rounded-3"
              style={{ maxWidth: "1300px", overflowX: "auto" }}
            >
              <table className="w-100">
                <tr>
                  <th className="py-3 text-white borderstart_r ff_heebo  px-3 bg_darkblue">
                    Image
                  </th>
                  <th className="py-3 text-white ff_heebo  px-3 bg_darkblue">
                    Title
                  </th>
                  <th className="py-3 text-white ff_heebo  px-3 bg_darkblue">
                    Type
                  </th>
                  <th className="py-3 text-white ff_heebo borderend_r px-3 bg_darkblue">
                    Action
                  </th>
                </tr>
                {testList?.length < 1 ? (
                  <div className="text-start ps-3 py-4">No data found</div>
                ) : (
                  <>
                    {testList?.map((obj, i) => {
                      return (
                        <tr key={i}>
                          <td className="px-3 py-3">
                            <img
                              width={70}
                              src={`https://api.koduapp.com/public/images/${obj.image}`}
                              alt="hello"
                            />
                          </td>
                          <td className="px-3 py-3">{obj.title}</td>
                          <td className="px-3 py-3">{obj.type}</td>
                          <td className="px-3 py-3">
                            <div className="d-flex gap-3 bg-white">
                              <img
                                onClick={() => {
                                  toggleFreeTrialModal();
                                  setCurrentData(obj);
                                }}
                                width={20}
                                className="cursor_pointer"
                                src={edit_icon}
                                alt="hello"
                              />
                              <img
                                onClick={() => deleteCourse(obj._id)}
                                width={20}
                                className="cursor_pointer"
                                src={delete_icon}
                                alt="hello"
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                )}
              </table>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="card rounded-0 px-5 py-4 position-relative">
            <div className="position-absolute top-0 end-0">
              <img
                onClick={() => setAddTestModal(false)}
                width={20}
                className="me-3 mt-3 cursor_pointer"
                src={crossblack_icon}
                alt="Close"
              />
            </div>
            <div>
              <form onSubmit={createBlog}>
                <div className="d-flex w-100 flex-column col-3 mt-4">
                  <label>Title</label>
                  <input
                    className="custom_admin_input mt-2 px-3 py-2 w-100"
                    type="text"
                    name="title"
                    value={testData.title}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="d-flex flex-column w-100 mt-4">
                  <label>Image</label>
                  <input
                    required
                    className="custom_admin_input mt-2 px-3 py-2 w-100"
                    type="file"
                    name="image"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="d-flex flex-column w-100 mt-4">
                  <label>Type</label>
                  <div className="d-flex align-items-center gap-5 mt-3">
                    <label class="radio_container d-flex align-items-center">
                      &nbsp; <span>Scholarship</span>
                      <input
                        type="radio"
                        name="type"
                        value="scholarship"
                        checked={testData.type === "scholarship"}
                        onChange={handleInputChange}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label class="radio_container">
                      &nbsp; <span>Quiz</span>
                      <input
                        type="radio"
                        name="type"
                        value="quiz"
                        checked={testData.type === "quiz"}
                        onChange={handleInputChange}
                      />
                      <span className="checkmark"></span>
                    </label>
                    {/* <label>
                      <input
                        type="radio"
                        name="type"
                        value="Scholarship"
                        checked={testData.type === "Scholarship"}
                        onChange={handleInputChange}
                      />{" "}
                      &nbsp; Scholarship
                    </label> */}
                    {/* <label>
                      <input
                        type="radio"
                        name="type"
                        value="Quiz"
                        checked={testData.type === "Quiz"}
                        onChange={handleInputChange}
                      />{" "}
                      &nbsp; Quiz
                    </label> */}
                  </div>
                </div>

                <button
                  className="mt-5 border-0 bg_darkblue px-5 py-2 rounded-2 fs_16 ff_heebo fw-medium text-white"
                  type="submit"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </>
      )}
      <Modal
        show={freeTrialModalVisible}
        centered
        onHide={toggleFreeTrialModal}
      >
        <div
          style={{ width: "700px", margin: "0 auto" }}
          className="bg-white rounded-3 "
        >
          <div className="position-absolute top-0 end-0">
            <img
              onClick={toggleFreeTrialModal}
              width={20}
              className="me-2 mt-2 cursor_pointer"
              src={crossblack_icon}
              alt="Close"
            />
          </div>

          <Modal.Body>
            <form className="mt-4" onSubmit={updateBlog}>
              <div className="d-flex w-100 flex-column col-3 mt-4">
                <label>Title</label>
                <input
                  className="custom_admin_input mt-2 px-3 py-2 w-100"
                  type="text"
                  name="title"
                  value={newTestData?.title}
                  onChange={handleInputChange2}
                />
              </div>
              <div className="d-flex flex-column mt-4">
                <label>Image</label>
                <input
                  className="custom_admin_input mt-2 px-3 py-2 w-100"
                  type="file"
                  name="image"
                  onChange={handleInputChange2}
                />
              </div>
              <div className="d-flex flex-column w-100 mt-4">
                <label>Type</label>
                <div className="d-flex gap-4 mt-2">
                  <label class="radio_container d-flex align-items-center">
                    &nbsp; <span>Scholarship</span>
                    <input
                      type="radio"
                      name="type"
                      value="scholarship"
                      checked={newTestData.type === "scholarship"}
                      onChange={handleInputChange2}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label class="radio_container">
                    &nbsp; <span>Quiz</span>
                    <input
                      type="radio"
                      name="type"
                      value="quiz"
                      checked={newTestData.type === "quiz"}
                      onChange={handleInputChange2}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>

              <button
                className="mt-5 border-0 bg_darkblue px-5 py-2 rounded-2 fs_16 ff_heebo fw-medium text-white"
                type="submit"
              >
                Submit
              </button>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default TestTab;
