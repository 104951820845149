import React, { useEffect } from "react";
import Navbar from "../common/Navbar";
import CourseHero from "../CoursePage/CourseHero";
import Features from "../CoursePage/Features";
import Courses from "../CoursePage/Courses";
import CourseReview from "../CoursePage/CourseReview";
import CourseFeature from "../CoursePage/CourseFeature";
import CourseFaq from "../CoursePage/CourseFaq";
import Compare from "../CoursePage/Compare";
import Counselling from "../CoursePage/Counselling";

function Coursepage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <CourseHero />
      <Features />
      <Courses />
      <CourseReview />
      <CourseFeature />
      <CourseFaq />
      <Compare />
      <Counselling />
    </div>
  );
}

export default Coursepage;
