import React from "react";

const TermsCondition = () => {
  return (
    <div className="py-5">
      <div className="container">
        <h1 className=" fs_37 fw-bold ff_heebo color_yellow">
          Term’s & Condition’s
        </h1>
        <h4 className="mt-5 fs_26 ff_heebo color_blue">Terms Of Use</h4>
        <p className="fs_18 fw-normal ff_heebo">
          kodu (“we”, “us”, “our”, or “Company”) conducts online classes, live
          demo, Video Lectures and provides online courses and PDF Notes along
          with doubt clearing sessions for the students seeking to enrol for
          such courses (the “Services”), which are accessible on kodu
          applications for mobile, tablet and other smart devices and
          application program interfaces (collectively, the “Applications”)
          which are made available for download through an app store on your
          mobile device.
        </p>
        <h4 className="mt-5 fs_26 ff_heebo color_blue">Introduction</h4>
        <p className="fs_18 fw-normal ff_heebo">
          These Terms & Conditions (“Terms”/ “Terms of Use”) of use of our
          application or any modes of registrations or usage of products,
          including through mobiles, tablets or other storage/transmitting
          devices are between kodu and its users (“User/You/Your”).
        </p>
        <h4 className="mt-5 fs_26 ff_heebo color_blue">
          Your Relationship With Us
        </h4>
        <p className="fs_18 fw-normal ff_heebo">
          By accessing or using Application or Services or by downloading or
          posting any content from or on the Applications, you would be
          indicating that you have read, and that you understand and agree to be
          bound by these terms and receive our Services, whether or not you have
          registered with the Application.
        </p>
        <p className="fs_18 fw-normal ff_heebo">
          Please read these Terms and the privacy policy of the Company
          (“Privacy Policy”) with respect to registration with us, the use of
          the Application and Services carefully before accessing or using the
          Application or Services or downloading or posting any content from or
          on the Application or through vthe Services, carefully as they contain
          important information regarding your legal rights, remedies and
          obligations.
        </p>
        <p className="fs_18 fw-normal ff_heebo">
          In the event of any discrepancy between the Terms and any other
          policies with respect to the Application or Services, the provisions
          of the Terms shall prevail.
        </p>
        <p className="fs_18 fw-normal ff_heebo">
          If you do not agree to these Terms, please do not use the Application
          and Services offered by us.
        </p>

        <h4 className="mt-5 fs_26 ff_heebo color_blue">
          Information About You and Your Privacy
        </h4>
        <p className="fs_18 fw-normal ff_heebo">
          Your privacy is important to us. You should read our Privacy Policy
          (link to Privacy Policy) to understand how we collect, use and share
          information about you.
        </p>
        <h5 className="mt-3 ff_heebo color_yellow">Setting Up an Account</h5>
        <ol>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              To access our Service, you must register with us and set up an
              account with a username and password (your “Account”). We
              encourage you to use “strong” passwords (passwords that use a
              combination of upper and lower case letters, numbers and symbols)
              with your Account
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              Eligibility: You must be at least 18 years of age and at the time
              of entering into a legally binding agreement to use our Service.
              If you are under the age of 18, you must have explicit consent of
              your parent/legal guardian for setting up an account with us. We
              do not knowingly collect or solicit any information from anyone
              under the age of 18 or knowingly allow such persons to register
              for the Service. Children under the age of 18 are prohibited from
              providing any personal information to the Application without the
              explicit consent of their parent/legal guardian. In the event that
              we learn that we have collected personal information from a child
              under age 18 without parental consent, we will delete that
              information as quickly as possible.
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              You are responsible for maintaining the confidentiality of your
              login details and any activities that occur under your Account. If
              you have any concerns that your Account may have been misused, you
              should contact us atsupport@mine.lndo.sitestraight away to let us
              know.
            </span>
          </li>
        </ol>
        <h4 className="mt-5 fs_26 ff_heebo color_blue">
          Use of Proprietary Information
        </h4>
        <p className="fs_18 fw-normal ff_heebo">
          Except as mentioned below, all information, content, material,
          trademarks, services marks, trade names, and trade secrets including
          but not limited to the software, text, images, graphics, video, script
          and audio, contained in the Application, Services and products are
          proprietary property of the Company (“Proprietary Information”). No
          Proprietary Information may be copied, downloaded, reproduced,
          modified, republished, uploaded, posted, transmitted or distributed in
          any way without obtaining prior written permission from the Company
          and nothing on this Application or Services shall be or products
          deemed to confer a license of or any other right, interest or title to
          or in any of the intellectual property rights belonging to the
          Company, to the User. You may own the medium on which the information,
          content or materials resides, but the Company shall at all times
          retain full and complete title to the information, content or
          materials and all intellectual property rights inserted by the Company
          on such medium. Certain contents on the Application may belong to
          third parties. Such contents have been reproduced after taking prior
          consent from said party and all rights relating to such content will
          remain with such third party. Further, you recognize and acknowledge
          that the ownership of all trademarks, copyright, logos, service marks
          and other intellectual property owned by any third party shall
          continue to vest with such party and You are not permitted to use the
          same without the consent of the respective third party.
        </p>
        <h4 className="mt-5 fs_26 ff_heebo color_blue">User Conduct</h4>
        <p className="fs_18 fw-normal ff_heebo">
          Your use of our products, Application and Services is solely for Your
          personal and non-commercial use. Any use of the Application, Services
          or products or their contents other than for personal purposes is
          prohibited. Your personal and non-commercial use of this Application,
          products and / or our Services shall be subjected to the following
          restrictions:
        </p>
        <ol>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              You may not decompile, reverse engineer, or disassemble the
              contents of the Application and/or Services/ products or modify,
              copy, distribute, transmit, display, perform, reproduce, publish,
              license, create derivative works from, transfer, or sell any
              information or software obtained from the Application and/or
              Services/products, or remove any copyright, trademark
              registration, or other proprietary notices from the contents of
              the Application and / or and /or Services/products.
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              You will not (a) use this Application and/or any of our product/s
              or Service/s for commercial purposes of any kind, or (b) advertise
              or sell the Application or any products, Services or domain names
              or otherwise (whether or not for profit), or solicit others
              (including, without limitation, solicitations for contributions or
              donations) or use any public forum for commercial purposes of any
              kind, or (c) use the Application /our products and Services in any
              way that is unlawful, or harms the Company or any other person or
              entity as determined by the Company.
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              No User shall be permitted to perform any of the following
              prohibited activities while availing our Services:
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              Making available any content that is misleading, unlawful,
              harmful, threatening, abusive, tortious, defamatory, libelous,
              vulgar, obscene, child-pornographic, lewd, lascivious, profane,
              invasive of another’s privacy, hateful, or racially, ethnically or
              otherwise objectionable;
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              Stalking, intimidating and/or harassing another and/or inciting
              other to commit violence;
            </span>
          </li>
        </ol>
        <p className="fs_18 fw-normal ff_heebo">
          <strong>iii.</strong> Transmitting material that encourages anyone to
          commit a criminal offence, that results in civil liability or
          otherwise breaches any relevant laws, regulations or code of practice;
        </p>
        <ol>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              You may not decompile, reverse engineer, or disassemble the
              contents of the Application and/or Services/ products or modify,
              copy, distribute, transmit, display, perform, reproduce, publish,
              license, create derivative works from, transfer, or sell any
              information or software obtained from the Application and/or
              Services/products, or remove any copyright, trademark
              registration, or other proprietary notices from the contents of
              the Application and / or and /or Services/products.
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              You will not (a) use this Application and/or any of our product/s
              or Service/s for commercial purposes of any kind, or (b) advertise
              or sell the Application or any products, Services or domain names
              or otherwise (whether or not for profit), or solicit others
              (including, without limitation, solicitations for contributions or
              donations) or use any public forum for commercial purposes of any
              kind, or (c) use the Application /our products and Services in any
              way that is unlawful, or harms the Company or any other person or
              entity as determined by the Company.
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              No User shall be permitted to perform any of the following
              prohibited activities while availing our Services:
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              Making available any content that is misleading, unlawful,
              harmful, threatening, abusive, tortious, defamatory, libelous,
              vulgar, obscene, child-pornographic, lewd, lascivious, profane,
              invasive of another’s privacy, hateful, or racially, ethnically or
              otherwise objectionable;
            </span>
          </li>
        </ol>
        <p className="fs_18 fw-normal ff_heebo">
          <strong>vii.</strong> Impersonate any person or entity, or falsely
          state or otherwise misrepresent Your affiliation with a person or
          entity;
        </p>
        <p className="fs_18 fw-normal ff_heebo">
          <strong>viii.</strong> Post, transmit or make available any material
          that contains viruses, trojan horses, worms, spyware, time bombs,
          cancelbots, or other computer programming routines, code, files or
          such other programs that may harm the Application/services, interests
          or rights of other users or limit the functionality of any computer
          software, hardware or telecommunications, or that may harvest or
          collect any data or personal information about other Users without
          their consent;
        </p>
        <ol>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              You may not decompile, reverse engineer, or disassemble the
              contents of the Application and/or Services/ products or modify,
              copy, distribute, transmit, display, perform, reproduce, publish,
              license, create derivative works from, transfer, or sell any
              information or software obtained from the Application and/or
              Services/products, or remove any copyright, trademark
              registration, or other proprietary notices from the contents of
              the Application and / or and /or Services/products.
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              Access or use the Application/Services/products in any manner that
              could damage, disable, overburden or impair any of the
              Application’s servers or the networks connected to any of the
              servers on which the Application is hosted;
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              Intentionally or unintentionally interfere with or disrupt the
              services or violate any applicable laws related to the access to
              or use of the Application/Services/products, violate any
              requirements, procedures, policies or regulations of networks
              connected to the Application/Services/products, or engage in any
              activity prohibited by these Terms;
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              Disrupt or interfere with the security of, or otherwise cause harm
              to, the Application/Services/products, materials, systems
              resources, or gain unauthorized access to user accounts,
              passwords, servers or networks connected to or accessible through
              the Application /Services/products;
            </span>
          </li>
        </ol>
        <p className="fs_18 fw-normal ff_heebo">
          <strong>xii.</strong> Interfere with, or inhibit any user from using
          and enjoying access to the Application/Services/products, or engage in
          disruptive attacks such as denial of service attack on the Application
          /Services/products;
        </p>
        <p className="fs_18 fw-normal ff_heebo">
          <strong>xiii.</strong> . Interfere with, or inhibit any user from
          using and enjoying access to the Application/Services/products, or
          engage in disruptive attacks such as denial of service attack on the
          Application /Services/products;
        </p>
        <p className="fs_18 fw-normal ff_heebo">
          <strong>xiii.</strong>Use deep-links, page-scrape, robot, spider or
          other automatic device, program, algorithm or methodology, or any
          similar or equivalent manual process, to increase traffic to the
          Application/Services/products, to access, acquire, copy or monitor any
          portion of the Application / Services/products, or in any way
          reproduce or circumvent the navigational structure or presentation of
          the Application, or any content, to obtain or attempt to obtain any
          content, documents or information through any means not specifically
          made available through the Application/Services/products;
        </p>
        <p className="fs_18 fw-normal ff_heebo">
          <strong>xiv.</strong>Alter or modify any part of the Services;
        </p>
        <ol>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              Use the Services for purposes that are not permitted by: (i) these
              Terms; and (ii) any applicable law, regulation or generally
              accepted practices or guidelines in the relevant jurisdiction; or
            </span>
          </li>
        </ol>
        <p className="fs_18 fw-normal ff_heebo">
          <strong>xvi.</strong>Violate any of the terms specified under the
          Terms for the use of the Application /Services/products.
        </p>
        <p className="fs_18 fw-normal ff_heebo">
          Failure to comply with the User Conduct constitutes a serious breach
          of these Terms of Use, and may result in our taking all or any of the
          following actions (with or without notice, and entirely at our
          discretion):
        </p>
        <ol>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              immediate, temporary or permanent withdrawal of your right to use
              our Service;
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              immediate, temporary or permanent removal of any User Content
              (including the removal of Sale Items listed on the Service);
            </span>
          </li>
        </ol>
        <p className="fs_18 fw-normal ff_heebo">
          <strong>iii.</strong> issuing of a warning to you;
        </p>
        <ol>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              legal action against you including proceedings for reimbursement
              of all costs (including, but not limited to, reasonable
              administrative and legal costs) resulting from the breach;
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              disclosure of such information to law enforcement authorities as
              we reasonably feel is necessary.
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              We may investigate any suspected breach of the User Conduct.
              During such investigation we may temporarily withdraw your right
              to use our Service or remove User Content (including the removal
              of Sale Items listed on the Service) without notice to you.
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              The responses described in section 7(c) are not limited, and we
              may take any other action we reasonably deem appropriate.
            </span>
          </li>
        </ol>
        <h4 className="mt-5 fs_26 ff_heebo color_blue">
          Our liability/responsibility to you
        </h4>
        <ol>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              Our Application provides Users with access to compiled educational
              information and related sources. Such information is provided on
              an As Is basis and We assume no liability for the accuracy or
              completeness or use or non-obsolescence of such information. We
              shall not be liable to update or ensure continuity of such
              information contained on the Application. We would not be
              responsible for any errors, which might appear in such
              information, which is compiled from third party sources or for any
              unavailability of such information. From time to time the
              Application may also include links to other websites/applications.
              These links are provided for your convenience to provide further
              information. They do not signify that we endorse the
              website(s)/application(s). We have no responsibility for the
              content of the linked website(s) /application(s). You may not
              create a link to the Website from another website or document
              without the Company’s prior written consent.
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              The contents of the Application/Services/products are developed on
              the concepts covered in the structured curriculum syllabus
              prescribed for students of various courses. The usage of the
              Application/Services/products is not endorsed as a substitution to
              the curriculum based education provided by the educational
              institutions but is intended to supplement the same by explaining
              and presenting the concepts in a manner enabling easy
              understanding. The basic definitions and formulae of the subject
              matter would remain the same. The Company acknowledges that there
              are various means of delivering structured curriculum pedagogy and
              inclusion of methods in the Application/Services/products does not
              imply endorsement of any particular method nor exclusion imply
              disapproval. Subscription to the Application or usage of our
              Services/products does not in any manner guarantee admission to
              any educational institutions or passing of any exams or
              achievement of any specified percentage of marks in any
              examinations.
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              Certain contents in the Application/Services/ products may contain
              opinions and views. The Company shall not be responsible for such
              opinions or any claims resulting from them. Further, the Company
              makes no warranties or representations whatsoever regarding the
              quality, content, completeness, or adequacy of such information
              and data.
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              The Company shall have no responsibility for any loss or damage
              caused to tablet or any other hardware and / or software and/or
              instrument, including loss of data or effect on the processing
              speed, resulting from Your use of our products and Services.
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              Although it is our intention that the Service is available as much
              as possible, there may be occasions when the Service may be
              interrupted, including for scheduled maintenance, upgrades and
              emergency repairs, or due to failure of telecommunication links
              and/or equipment. The Service and any content contained therein
              (including User Content) is provided on an “as available” and “as
              is” basis. This means that we are unable to promise that your use
              of the Service will be uninterrupted, without delays, error-free
              or meet your expectations and we do not give any commitment
              relating to the performance or availability of the Service in
              these Terms of Use and, to the extent we are able to do so, we
              exclude any commitments that may be implied by, or be expressed
              by, applicable law. You accept that the internet may be subject to
              breaches of security and that the submission of User Content or
              other information may not be secure.
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              kodu is not responsible or liable for any loss or harm caused by
              viruses, worms or other programmes designed to impair the Service.
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              You may be able to access third party links/websites/products via
              the Service. kodu is not responsible or liable for any third-party
              links/websites/products which may be accessed by you at your sole
              option. Your access to and use of such third-party
              links/websites/products and services shall be solely at your own
              risk and subject to your acceptance and compliance with the
              separate terms and conditions of such third party.
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              We will never be responsible for any loss or damage that is not
              reasonably foreseeable and we will never be responsible for
              indirect losses and/or financial and business loss, or loss of
              goodwill or reputation.
            </span>
          </li>
        </ol>
        <h4 className="mt-5 fs_26 ff_heebo color_blue">
          Cancellation and Refund Policy
        </h4>
        <ol>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              If you wish to cancel a confirmed enrolment made via the
              Application/Website, after enrolment to the Course, you may do so
              by contacting us via the contact link in the Contact Section.
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              Any Cancellation Requests received later than 7 business days
              prior to the end of the current service period will be treated as
              cancellation of services for the next service period and no refund
              shall be made for the said period.
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              All courses, products and services listed on the application are
              Non-Refundable and once enrolled in/obtained/subscribed to/ordered
              shall not be subject to any refunds or returns.
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              A refund shall be made to the user only in the event a repeat
              payment (payment made twice for the same course) has been made by
              the user. Such refund shall be made for the amount paid in excess
              of the fee of the course only and not the entire amount paid. The
              refund shall be credited to the bank account of the User within 7
              business days. If payment is made via credit card, refunds shall
              be issued to the original credit card provided at the time of
              purchase.
            </span>
          </li>
        </ol>
        <h4 className="mt-5 fs_26 ff_heebo color_blue">Communication</h4>
        <p className="fs_18 fw-normal ff_heebo">
          The Company may, based on any form of access to the Application
          (including free download/trials) or Services or registrations through
          any source whatsoever, contact the User through sms, email and call,
          to give information about its products as well as notifications on
          various important updates and/or to seek permission for demonstration
          of its products. The User expressly grants such permission to contact
          him/her through telephone, SMS, e-mail and holds the Company
          indemnified against any liabilities including financial penalties,
          damages, expenses in case the User’s mobile number is registered with
          Do not Call (DNC) database. By registering yourself, you agree to make
          your contact details available to Our employees, associates and
          partners so that you may be contacted for education information and
          promotions through telephone, SMS, email etc.
        </p>
        <p className="fs_18 fw-normal ff_heebo">
          Access to certain elements of the Services including doubt clearance,
          mentoring services etc may be subject to separate terms, conditions
          and fair usage policy. The Company reserves the right to determine the
          criteria for provision of various elements of Services to the
          different categories of Users based on its policies. Hence,
          subscription to the Application/products or registrations do not
          automatically entitle the User to any and all elements of Services
          provided by the Company and the Company shall be entitled to exercise
          its discretion while providing access to and determining continuity of
          certain elements of Services. We reserve the right to extend, cancel,
          discontinue, prematurely withdraw or modify any of Our Services at Our
          discretion.
        </p>
        <h4 className="mt-5 fs_26 ff_heebo color_blue">Indemnity</h4>
        <p className="fs_18 fw-normal ff_heebo">
          You agree to defend, indemnify and hold harmless the Company, its
          officers, directors, employees and agents, from and against any and
          all claims, damages, obligations, losses, liabilities, costs or debt,
          and expenses (including but not limited to attorney’s fees) arising
          from: (i) Your use of and access of the Application/Services/products;
          (ii) Your violation of any term of these Terms or any other policy of
          the Company; (iii) Your violation of any third party right, including
          without limitation, any copyright, property, or privacy right; or (iv)
          any claim that Your use of the Application/Services has caused damage
          to a third party. This defense and indemnification obligation will
          survive these Terms.
        </p>
        <h4 className="mt-5 fs_26 ff_heebo color_blue">Your Content</h4>
        <ol>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              You confirm that images, sounds, text or information that you
              submit or create (“User Content”) whilst using the Service will
              meet the Rules of Acceptable Use.
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              ou grant us a worldwide, non-exclusive, royalty-free, irrevocable
              and perpetual licence to use, copy, reproduce, distribute, adapt,
              re-format, modify, publish, translate, licence, sub-licence,
              assign, transfer and utilize the User Content (including any
              intellectual property rights therein) anywhere and in any form for
              the purposes of providing our Service or for any purpose in
              connection with the operation of our business.
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              Our right to use your User Content does not in any way affect your
              privacy rights and we will only use information that identifies
              you as set out in our Privacy Policy.
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              We do not check or moderate any User Content before it is added to
              the Service by users. We may later check, moderate, reject, refuse
              or delete any User Content if we think that it breaks any of the
              Rules of Acceptable Use, or we believe it is against the ethos of
              kodu.
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              User Content removed from the Service may continue to be stored by
              us, including, without limitation, in order to comply with certain
              legal obligations. Therefore, we encourage you to maintain your
              own backup of your User Content and you agree that you will not
              rely on the Service for the purposes of User Content backup or
              storage. To the extent permitted by applicable law, we shall have
              no liability for any loss of User Content.
            </span>
          </li>
        </ol>
        <h4 className="mt-5 fs_26 ff_heebo color_blue">Termination</h4>
        <ol>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              We may, at any time and without notice, suspend, cancel, or
              terminate your right to use the Application (or any portion of the
              Application). In the event of suspension, cancellation, or
              termination, you are no longer authorized to access the part of
              the Application affected by such suspension, cancellation, or
              termination. In the event of any suspension, cancellation, or
              termination, the restrictions imposed on you with respect to
              material downloaded from the Application, and the disclaimers and
              limitations of liabilities set forth in the Agreement, shall
              survive.
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              Without limiting the foregoing, we may close, suspend or limit
              your access to your Account:
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              if we determine that you have breached, or are acting in breach
              of, this Terms of Use; .
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              if we determine that you have breached legal liabilities (actual
              or potential), including infringing someone else’s Intellectual
              Property Rights;
            </span>
          </li>
        </ol>
        <p className="fs_18 fw-normal ff_heebo">
          <strong>iii.</strong>if we determine that you have engaged, or are
          engaging, in fraudulent, or illegal activities;
        </p>
        <ol>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              you do not respond to account verification requests;
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              to manage any risk of loss to us, a User, or any other person; or
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">For other similar reasons.</span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              In the event that we close your Account, you will have no claim
              whatsoever against us in respect of any such suspension or
              termination of your Account.
            </span>
          </li>
        </ol>
        <h4 className="mt-5 fs_26 ff_heebo color_blue">
          Governing Law & Jurisdiction
        </h4>
        <p className="fs_18 fw-normal ff_heebo">
          This Agreement shall be governed by and construed in accordance with
          the laws of Republic of India without regard to its choice of law
          principles.
        </p>
        <h4 className="mt-5 fs_26 ff_heebo color_blue">
          Changes to these Terms of Use
        </h4>
        <ol>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              We may revise these Terms of Use from time to time and any changes
              will take effect after 3 days of any notification email sent to
              you notifying you of any changes, or at the time the revised Terms
              of Use are posted (whichever is the earlier) and your continued
              use of the Service shall constitute acceptance of such revised
              Terms of Use.
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              Changes will usually occur because of new features being added to
              the Service, changes in the law or where we need to clarify our
              position on something.
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              We will try, where possible and reasonable, to contact you to let
              you know about any significant changes to any of the documents
              referred to in these Terms of Use. We may contact you through the
              Service (for example by asking you to accept the changes before
              you continue to use the Service) or via a separate email.
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              Normally, we will try to give you some warning before the new
              terms become effective. However, sometimes changes will need to be
              made immediately and if this happens, we will not give you any
              notice.
            </span>
          </li>
        </ol>
        <h4 className="mt-5 fs_26 ff_heebo color_blue">Severability</h4>
        <p className="fs_18 fw-normal ff_heebo">
          Each Term shall be deemed to be severable. If any Term or portion
          thereof is found to be invalid or unenforceable, such invalidity or
          unenforceability shall in no way effect the validity or enforceability
          of any other Term.
        </p>
        <h4 className="mt-5 fs_26 ff_heebo color_blue">Assignment</h4>
        <ol>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              You will not assign any rights or delegate any obligations under
              these Terms, in whole or in part, by operation of law or
              otherwise, without obtaining our prior written consent, which may
              be withheld in our sole discretion.
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              We may assign our rights and delegate any of our obligations under
              these Terms, in whole or in part, without your consent. Any
              assignment or delegation in violation of the foregoing will be
              null and void. These Terms will be binding and inure to the
              benefit of each party’s permitted successors and assigns.
            </span>
          </li>
        </ol>
        <h4 className="mt-5 fs_26 ff_heebo color_blue">Force Majeure</h4>
        <p className="fs_18 fw-normal ff_heebo">
          We shall be under no liability to you in respect of anything that, if
          not for this provision, would or might constitute a breach of these
          Terms, where this arises out of circumstances beyond our control,
          including but not limited to:
        </p>

        <ol>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">Acts of god;</span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">Natural disasters;</span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">Sabotage;</span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">Accident;</span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">Riot;</span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              Shortage of supplies, equipment, and materials;
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">Strikes and lockouts;</span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">Civil unrest;</span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">Computer hacking; or</span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">Malicious damage.</span>
          </li>
        </ol>
        <h4 className="mt-5 fs_26 ff_heebo color_blue">No Waiver</h4>
        <p className="fs_18 fw-normal ff_heebo">
          The failure of kodu to enforce any right or provision of these Terms
          will not constitute a waiver of future enforcement of that right or
          provision. The waiver of any such right or provision will be effective
          only if in writing and signed by a duly authorized representative of
          kodu. Except as expressly set forth in these Terms, the exercise by
          either party of any of its remedies under these Terms will be without
          prejudice to its other remedies under these Terms or otherwise. If for
          any reason a court of competent jurisdiction finds any provision of
          these Terms invalid or unenforceable, that provision will be enforced
          to the maximum extent permissible and the other provisions of these
          Terms will remain in full force and effect.
        </p>
        <h4 className="mt-5 fs_26 ff_heebo color_blue">Digital Signature</h4>

        <ol>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              By using our services, you are deemed to have executed this
              Agreement electronically; effective on the date you register your
              Account and start using our services. Your Account registration
              constitutes an acknowledgement that you are able to electronically
              receive, download, and print this Agreement.
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              In connection with this Agreement, you may be entitled to receive
              certain records, such as contracts, notices, and communications,
              in writing. To facilitate your use of the Application, you give us
              permission to provide these records to you electronically instead
              of in paper form.
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              By registering for an Account, you consent to electronically
              receive and access, via email, all records and notices for the
              services provided to you under this Agreement that we would
              otherwise be required to provide to you in paper form. However, we
              reserve the right, in our sole discretion, to communicate with you
              via the Postal Service and other third-party mail services using
              the address under which your account is registered. Your consent
              to receive records and notices electronically will remain in
              effect until you withdraw it. You may withdraw your consent to
              receive further records and notices electronically at any time by
              contacting at the Contact details provided on our Application. If
              you withdraw your consent to receive such records and notices
              electronically, we will terminate your access to the Services, and
              you will no longer be able to use the Services. Any withdrawal of
              your consent to receive records and notices electronically will be
              effective only after we have a reasonable period of time to
              process your request for withdrawal. Please note that your
              withdrawal of consent to receive records and notices
              electronically will not apply to records and notices
              electronically provided by us to you before the withdrawal of your
              consent becomes effective.
            </span>
          </li>
          <li className=" custom_list_style_type_number mt-3 ff_heebo fw-bold color_blue">
            <span className=" fw-normal">
              In order to ensure that we are able to provide records and notices
              to you electronically, you must notify us of any change in your
              email address by updating your Account information by contacting
              Customer Support at [ENTER CUSTOMER SUPPORT EMAIL].
            </span>
          </li>
        </ol>
        <h4 className="mt-5 fs_26 ff_heebo color_blue">DISCLAIMER</h4>
        <p className="fs_18 fw-normal ff_heebo">
          THIS APPLICATION AND THE SERVICES ARE PROVIDED ON AN “AS IS” BASIS
          WITH ALL FAULTS AND WITHOUT ANY WARRANTY OF ANY KIND. THE COMPANY
          HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH REGARD TO THE
          APPLICATION/PRODUCTS AND THE SERVICES, INCLUDING WITHOUT LIMITATION,
          ALL IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR
          A PARTICULAR PURPOSE, TITLE, ACCURACY, TIMELINESS. PERFORMANCE,
          COMPLETENESS, SUITABILITY AND NON-INFRINGEMENT. ADDITIONALLY, THE
          COMPANY SHALL NOT BE LIABLE FOR ANY DAMAGES ARISING OUT OF OR IN
          CONNECTION WITH THE USE OR PERFORMANCE OF THIS APPLICATION OR THE
          SERVICES. YOUR USE OF ANY INFORMATION OR MATERIALS ON THIS
          APPLICATION/SERVICES/PRODUCTS IS ENTIRELY AT YOUR OWN RISK, FOR WHICH
          WE SHALL NOT BE LIABLE. IT SHALL BE YOUR OWN RESPONSIBILITY TO ENSURE
          THAT SERVICES PROVIDED BY US MEET YOUR SPECIFIC REQUIREMENTS.
        </p>
        <h4 className="mt-5 fs_26 ff_heebo color_blue">Survival</h4>
        <p className="fs_18 fw-normal ff_heebo">
          If you or we terminate this Agreement, the clauses of these Terms that
          reasonably should survive termination of the Agreement will remain in
          effect.
        </p>
        <h4 className="mt-5 fs_26 ff_heebo color_blue">Entire Agreement</h4>
        <p className="fs_18 fw-normal ff_heebo">
          The Agreement, in connection with the other obligations and rules
          detailed in writing on the Application, constitute the entire
          agreement between you and the Application and cannot be modified by
          you. The Terms of Use cannot be modified on an individual basis by any
          person affiliated, or claiming affiliation, with the Application.
          Nothing in this subsection will prevent the Application from modifying
          the terms of these Terms of Use and posting such other modified Terms
          of Use.
        </p>
        <h4 className="mt-5 fs_26 ff_heebo color_blue">Feedback</h4>
        <p className="fs_18 fw-normal ff_heebo">
          We welcome and encourage you to provide feedback, comments and
          suggestions for improvements to the Application and Services
          (“Feedback”). You may submit Feedback by emailing us, through the
          “Contact” section of the Application. You acknowledge and agree that
          all Feedback you give us will be the sole and exclusive property of
          kodu and you hereby irrevocably assign to kodu and agree to
          irrevocably assign to kodu all of your right, title, and interest in
          and to all Feedback, including without limitation all worldwide
          patent, copyright, trade secret, moral and other proprietary or
          intellectual property rights therein, and waive any moral rights you
          may have in such Feedback. At kodu’s request and expense, you will
          execute documents and take such further acts as kodu may reasonably
          request to assist kodu to acquire, perfect, and maintain its
          intellectual property rights and other legal protections for the
          Feedback.
        </p>
        <h4 className="mt-5 fs_26 ff_heebo color_blue">Notice</h4>
        <p className="fs_18 fw-normal ff_heebo">
          Any notices or other communications permitted or required hereunder,
          including those regarding modifications to these Terms, will be in
          writing and given by Us (i) via email (in each case to the address
          that you provide) or (ii) via the Application. For notices made by
          e-mail, the date of receipt will be deemed the date on which such
          notice is transmitted.
        </p>
      </div>
    </div>
  );
};

export default TermsCondition;
