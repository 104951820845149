import React, { useEffect, useState } from "react";
import LanguageModelHero from "../languageModelPage/LanguageModelHero";
import Navbar from "../common/Navbar";
import { useParams } from "react-router-dom";
import makeApiCall from "../callApi";
import { Parser } from "html-to-react";
const htmlParser = new Parser();

const LanguageModelPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { id } = useParams();
  const [blog, setBlog] = useState([]);

  useEffect(() => {
    makeApiCall("", "get", `blog_details/${id}`, handleBlogDetails);
  }, [id]);

  const handleBlogDetails = (response) => {
    if (response.status === 200) {
      setBlog(response.data.data);
    } else {
      console.log("Error fetching blog details");
    }
  };

  const htmlString = `<p className="ff_inter fw-normal text_grey fs_xxl ps-0">${blog?.description}</p>`;
  const parsedDesc = htmlParser.parse(htmlString);
  return (
    <div>
      <Navbar />
      <div className="py-5">
        <div className="container">
          <h4>{blog?.title}</h4>
          <div
            className="table_content overflow-x-auto"
            style={{ maxWidth: "1300px" }}
          >
            <p className=" mt-4">{parsedDesc}</p>
          </div>
        </div>
      </div>
      {/* <LanguageModelHero /> */}
    </div>
  );
};

export default LanguageModelPage;
