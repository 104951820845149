import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import slider_img from "../../assets/images/png/slide_img.png";
import prve_icon from "../../assets/images/png/prve_icon.png";
import next_icon from "../../assets/images/png/next_icon.png";

const Opportunity = () => {
  const sliderRef = useRef(null);
  const slickRef = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    centerMode: true,
    speed: 500,
    centerPadding: "0px",
    slidesToShow: 3,
    arrows: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          centerMode: true,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          //   arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          //   arrows: false,
          centerMode: true,
          slidesToShow: 1,
        },
      },
    ],

    customPaging: function (i) {
      return (
        <div className="dot-item">
          <button>{i + 1}</button>
        </div>
      );
    },
  };

  useEffect(() => {
    if (sliderRef.current && sliderRef.current.innerSlider) {
      slickRef.current = sliderRef.current.innerSlider;
    }
  }, []);

  const nextSlide = () => {
    if (slickRef.current) {
      slickRef.current.slickNext();
    }
  };

  const prevSlide = () => {
    if (slickRef.current) {
      slickRef.current.slickPrev();
    }
  };
  return (
    <div className="py-5">
      <div className="container">
        <h4 className="fs_37 fw-bold ff_heebo color_blue text-center">
          A limited seats opportunity
        </h4>
        <h4 className="text-center fs_37 fw-bold ff_heebo color_yellow mt-2">
          Refund Offer
        </h4>

        <div className="mt-5 position-relative">
          <img
            width={40}
            className=" cursor_pointer position-absolute  prve_icon"
            onClick={prevSlide}
            src={prve_icon}
            alt="prve_icon"
          />
          <img
            width={40}
            className=" cursor_pointer position-absolute next_icon"
            onClick={nextSlide}
            src={next_icon}
            alt="prve_icon"
          />
          <Slider {...settings} ref={sliderRef}>
            <div className="px-3">
              <div className="bg_darkblue d-flex flex-column justify-content-between rounded-4 px-3 py-4 custom_hight">
                <div>
                  <p className="fs_16 fw-semibold ff_heebo color_lightwhite">
                    How it works ?
                  </p>
                  <p className=" fs_16 text-white fw-normal ff_heebo">
                    A limited time opportunity for rewarding our learners to
                    <strong> upskill for free.</strong>
                  </p>
                  <div className="border justify-content-center d-flex align-items-center gap-3 text-white border-2 p-3 rounded-2 bg_darkyellow">
                    <div className="d-flex gap-2">
                      <span className="mt-1 fs_15 ff_heebo">₹</span>
                      <span className=" fs_37 fw-bold ff_heebo">0</span>{" "}
                    </div>
                    <span className=" fs_16 fw-semibold ff_heebo">
                      Effective Fee
                    </span>
                  </div>
                </div>
                <div className="d-md-flex mt-4 gap-4 justify-content-between align-items-center">
                  <button className="bg_light w-100 rounded-2 text-white fw-medium py-2 px-4 border-0">
                    1 : 1 Mentorship
                  </button>
                  <button className="bg_light w-100 mt-3 mt-md-0 rounded-2 text-white fw-medium py-2 px-4 border-0">
                    Certification
                  </button>
                </div>
              </div>
            </div>
            <div className="px-3">
              <div className="bg_darkblue d-flex flex-column justify-content-between rounded-4 px-3 py-4 custom_hight">
                <div>
                  <p className="fs_16 fw-semibold mb-0 ff_heebo color_lightwhite">
                    How it works ?
                  </p>
                  <p className="fs_24 fw-bold py-2 mb-0 ff_heebo color_lightwhite">
                    Step 1 : Enroll
                  </p>
                  <p className=" fs_16 text-white fw-normal ff_heebo">
                    into your favourite courses
                  </p>
                  <div className="d-flex justify-content-center my-3">
                    <img
                      className=" text-center"
                      width={170}
                      src={slider_img}
                      alt="slider_img"
                    />
                  </div>
                </div>
                <button className="bg_light rounded-2 text-white fw-medium py-2 px-4 border-0">
                  Get Lifetime Course Access
                </button>
              </div>
            </div>
            <div className="px-3">
              <div className="bg_darkblue d-flex flex-column justify-content-between rounded-4 px-3 py-4 custom_hight ">
                <div>
                  <p className="fs_16 fw-semibold mb-0 ff_heebo color_lightwhite">
                    How it works ?
                  </p>
                  <p className="fs_24 fw-bold py-2 mb-0 ff_heebo color_lightwhite">
                    Step 2 : Complete Course
                  </p>
                  <p className=" fs_16 text-white fw-normal ff_heebo">
                    with shared curated assignments in
                  </p>
                  <div className="border justify-content-center d-flex align-items-center gap-3 text-white border-2 p-3 rounded-2 bg_darkyellow">
                    <span className=" fs_37 fw-bold ff_heebo">3</span>{" "}
                    <span className=" fs_16 fw-semibold ff_heebo">
                      months time from the <br></br>date of enrollment
                    </span>
                  </div>
                </div>
                <button className="bg_light mt-3 rounded-2 text-white fw-medium py-2 px-4 border-0">
                  Free Unlimited Doubt Solving
                </button>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Opportunity;
