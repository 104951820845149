import React from "react";
import icon from "../../assets/images/png/Features_icon.png";
import poster3 from "../../assets/images/png/poster3.png";

const ScholarshipBenefit = () => {
  return (
    <div className="py-5">
      <div className="container">
        <h3 className=" fs_37 ff_heebo fw-bold ">
          {/* How About Scholarship benefit students */}
          How does a scholarship test benefit students?
        </h3>
        <div className="row mt-4">
          <div className="col-lg-3 col-md-4 col-sm-6 mt-4">
            <div className="features_box h-100 cursor_pointer p-4 rounded-2">
              <img src={icon} alt="icon" />
              <p className="fs_22 fw-medium ff_heebo color_darkgray mt-3 mb-0">
                Help you to judge your knowledge.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 mt-4">
            <div className="features_box h-100 cursor_pointer p-4 rounded-2">
              <img src={icon} alt="icon" />
              <p className="fs_22 fw-medium ff_heebo color_darkgray mt-3 mb-0">
                To get an additional discount based on your knowledge.
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 mt-4">
            <div className="features_box h-100 cursor_pointer p-4 rounded-2">
              <img src={icon} alt="icon" />
              <p className="fs_22 fw-medium ff_heebo color_darkgray mt-3 mb-0">
                To outshine in your class
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 mt-4">
            <div className="features_box h-100 cursor_pointer p-4 rounded-2">
              <img src={icon} alt="icon" />
              <p className="fs_22 fw-medium ff_heebo color_darkgray mt-3 mb-0">
                Help in self analysis.
              </p>
            </div>
          </div>
        </div>
        <img className="w-100 mt-5 pt-md-5" src={poster3} alt="poster3" />
      </div>
    </div>
  );
};

export default ScholarshipBenefit;
