import React, { useEffect } from "react";
import SuccessStoriesHero from "../successstoriespage/SuccessStoriesHero";
import Navbar from "../common/Navbar";

const SuccessStoriesPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <SuccessStoriesHero />
    </div>
  );
};

export default SuccessStoriesPage;
