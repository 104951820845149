import React from "react";
import { Accordion } from "react-bootstrap";

const AboutCourse = () => {
  return (
    <div className="pb-5">
      <div className="container">
        <h3 className=" text-center mb-5 fs_37 fw-bold ff_heebo color_blue">
          About this course
        </h3>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mb-0">
          Welcome to the Complete Web Development Bootcamp,
          <strong> the only course you need</strong> to learn to code and become
          a full-stack web developer.
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          At 50+ hours, this Web Development course is without a doubt the
          <strong>most comprehensive</strong> web development course available
          online. Even if you have <strong>zero</strong> programming experience,
          this course will take you from <strong>beginner to masterys</strong>
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          This course doesn't cut any corners, tens of{" "}
          <strong> real-world projects</strong> which you will get to build.
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          The curriculum was developed over a period of{" "}
          <strong>four years</strong>, with comprehensive student testing and
          feedback.
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          You'll save yourself <strong> over Rs 50,000 </strong> by enrolling,
          but still get access to the same teaching materials and learn from the
          same instructor and curriculum as our in-person programming bootcamp.
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          The course is <strong>constantly updated</strong> with new content,
          with new projects and modules determined by students - that's you!
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          We'll take you <strong> step-by-step</strong> through engaging video
          tutorials and teach you everything you need to know to succeed as a
          web developer.
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          The course includes over <strong>50 hours</strong> of HD video
          tutorials and builds your programming knowledge while making
          real-world websites and web apps.
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          Throughout this comprehensive course, we cover a massive amount of
          tools and technologies, including:
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          Front-End Web Development
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          <strong>CSS 3</strong>
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          <strong>CSS 3</strong>
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          <strong>Bootstrap 4</strong>
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          DOM Manipulation
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          Bash Command Line
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          Git, <strong>GitHub</strong> and Version Control
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          Backend Web <strong>Development</strong>
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          <strong> Node.js</strong>
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          <strong>jQuery</strong>
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          EJS
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          APIs
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          SQL
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          MongoDB
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          Authentication
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          Firebase
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          <strong> React.js</strong>
        </p>

        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          React Hooks
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          Web Design
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          Deployment with GitHub Pages, Heroku and MongoDB Atlas
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          By the end of this course, you will be <strong>fluently</strong>{" "}
          programming and be ready to <strong></strong>
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          You'll also build a <strong>portfolio</strong> of over 25+ websites
          that you can show off to any potential employer.
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          <strong>Sign up today, and look forward to:</strong>
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          Video Lectures
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          Fully Fledged Projects
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          Quizzes
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          Programming Resources and Cheatsheets
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          Don't just take my word for it, check out what existing students have
          to say about the course:
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          "Rahul is just incredible, awesome and just fantastic in this course.
          I've never had such an instructor; <strong>detailed</strong> in every
          aspect of the course, gives precise explanations, gives you the
          anxiety to learn etc. He's got that ability to make fun while
          explaining thing for better understanding. I really love this course."
          - Ajit Singh
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          "Love the way instructor explains things. Easy to follow and full or
          logic. I can say he must spent a lot of energy creating this great
          course. Thank you and I recommend to all who's interested in coding!"
          - Parth Miglani
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          "So far (on my third day) this course has taught me{" "}
          <strong>
            {" "}
            more than I was able to learn in multiple other programming courses.{" "}
          </strong>{" "}
          This course is clearly outlined and builds upon itself gradually in an
          easy to understand way." - Keshav Nagpal
        </p>
        <p className=" fs_16 ff_heebo fw-medium color_darkgray mt-4 mb-0">
          So what are you waiting for? Click the enroll now button and join the
          world's highest rated web development course.
        </p>
        <h3 className=" fs_37 fw-bold ff_heebo color_blue text-center mt-5">
          Have Any Query?
        </h3>
        <div className="mt-4 about_faq d-flex justify-content-center">
          <Accordion defaultActiveKey="0">
            <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="0">
              <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                <li className=" custom_list_style_type color_yellow"></li>
                What is DevOps?
              </Accordion.Header>
              <Accordion.Body className="px-5 pb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="1">
              <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                <li className=" custom_list_style_type color_yellow"></li>
                What is the purpose of this DevOps Training?
              </Accordion.Header>
              <Accordion.Body className="px-5 pb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="2">
              <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                <li className=" custom_list_style_type color_yellow"></li>
                What are the benefits of taking DevOps Courses?
              </Accordion.Header>
              <Accordion.Body className="px-5 pb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="3">
              <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                <li className=" custom_list_style_type color_yellow"></li>
                What is the purpose of this DevOps Training?
              </Accordion.Header>
              <Accordion.Body className="px-5 pb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="4">
              <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                <li className=" custom_list_style_type color_yellow"></li>
                Do I need any prior experience to become a DevOps Engineer?
              </Accordion.Header>
              <Accordion.Body className="px-5 pb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="5">
              <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                <li className=" custom_list_style_type color_yellow"></li>
                What does a DevOps Engineer do?
              </Accordion.Header>
              <Accordion.Body className="px-5 pb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="6">
              <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                <li className=" custom_list_style_type color_yellow"></li>
                Module 4: JavaScript
              </Accordion.Header>
              <Accordion.Body className="px-5 pb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default AboutCourse;
