import React from "react";
import Slider from "react-slick";
import kodu_image from "../../assets/images/webp/kodu_image.webp";
import kodu_image1 from "../../assets/images/png/kodu_center_image.png";
import kodu_image2 from "../../assets/images/png/kodu_center_image1.png";

const OurCenters = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2.3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.7,
          dots: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };
  return (
    <div className="py-5">
      <h4 className="text-center fs_29 fw-bold ff_heebo">Our Centers</h4>

      <div>
        <div className="py-3 overflow-hidden centers_dotes">
          <Slider {...settings}>
            <div>
              <img className="w-100" src={kodu_image} alt="hello" />
            </div>{" "}
            <div className=" ">
              <img className="w-100" src={kodu_image1} alt="hello" />
            </div>{" "}
            <div>
              <img className="w-100" src={kodu_image2} alt="hello" />
            </div>{" "}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default OurCenters;
