import React, { useCallback, useContext, useState } from "react";
import Navbar from "../common/Navbar";
import sir_img from "../../assets/images/png/vikulsir_img.png";
import { Modal } from "react-bootstrap";
import crosswhtie_icon from "../../assets/images/png/crosswhite_icon.png";
import makeApiCall from "../callApi";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/Context";

const ScholarshipHero = () => {
  const { isLoginPopup, setIsLoginPopup, isLogin } = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [status, setStatus] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const navigate = useNavigate();

  const handleOpenModal = () => {
    if (!formSubmitted) {
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handlePlayNow = () => {
    if (status > 0) {
      alert("You can only attempt the scholarship test once.");
    } else {
      makeApiCall({ type: "scholarship" }, "post", "questions", (response) => {
        if (response.status === 200) {
          navigate(`/test/scholarship`, {
            state: { questions: response.data.data },
          });
          console.log(response.data.data);
        } else {
          console.log("Error fetching questions");
        }
      });
    }
  };

  // const handleSendOtp = async () => {
  //   try {
  //     const response = await makeApiCall(
  //       { phone: formData.phone },
  //       "post",
  //       "sendOTP",
  //       handleSendOtpCallback,
  //       false
  //     );
  //   } catch (error) {
  //     toast.error("Failed to send OTP. Please try again later.");
  //   }
  // };

  // const handleSendOtpCallback = (response) => {
  //   if (response.status === 200) {
  //     setOtpSent(true);
  //     toast.success("OTP sent successfully");
  //   } else {
  //     toast.error("Failed to send OTP");
  //   }
  // };

  // const handleVerifyOtp = async () => {
  //   try {
  //     const response = await makeApiCall(
  //       { phone: formData.phone, otp: otp, type: "register" },
  //       "post",
  //       "verifyOTP",
  //       handleVerifyOtpCallback,
  //       false
  //     );
  //   } catch (error) {
  //     toast.error("Failed to verify OTP. Please try again later.");
  //   }
  // };

  // const handleVerifyOtpCallback = (response) => {
  //   if (response.data.status === "success") {
  //     toast.success("OTP verified successfully");
  //   } else {
  //     toast.error("Invalid OTP");
  //   }
  // };

  // const createForm = async (e) => {
  //   e.preventDefault();

  //   if (formSubmitted) {
  //     return;
  //   }

  //   const formdata = {
  //     name: formData.name,
  //     email: formData.email,
  //     phone: formData.phone,
  //   };

  //   try {
  //     await makeApiCall(
  //       formdata,
  //       "post",
  //       "new_lead",
  //       createFormCallBack,
  //       false
  //     );
  //   } catch (error) {
  //     console.error("Error creating form:", error); // Debug log
  //     toast.error("Failed to create lead. Please try again later.");
  //   }
  // };

  // const createFormCallBack = useCallback((res) => {
  //   if (res.data.status === "success") {
  //     localStorage.setItem("phoneNumber", formData.phone);
  //     setShowModal(false);
  //     setFormSubmitted(true);
  //     setStatus(res?.data?.test_attempt_status);
  //     setFormData({
  //       name: "",
  //       email: "",
  //       phone: "",
  //     });
  //   } else {
  //     toast.error(res.data.message);
  //   }
  // });

  return (
    <div>
      <ToastContainer />
      <div>
        <Navbar />
      </div>
      <div className="bg_darkblue pt-md-5 pt-3">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-6">
              <h4 className="fs_43 fw-bold mb-0 ff_heebo color_yellow">
                Secure Your Future with Upto 70% Scholarship
              </h4>
              <p className="mb-0 fs_17 text-white ff_heebo">
                Duration : 60 mins
              </p>
              {isLogin ? (
                <button
                  onClick={handlePlayNow}
                  className="Free_btn border-0 mt-4 fs_17 fw-medium ff_heebo color_lightwhite"
                >
                  Attempt Test
                </button>
              ) : (
                <button
                  onClick={() => setIsLoginPopup(true)}
                  className="Free_btn border-0 mt-4 fs_17 fw-medium ff_heebo color_lightwhite"
                >
                  Register for Free
                </button>
              )}
              {/* {formSubmitted ? (
                <button
                  onClick={handlePlayNow}
                  className="Free_btn border-0 mt-4 fs_17 fw-medium ff_heebo color_lightwhite"
                >
                  Attempt Test
                </button>
              ) : (
                <button
                  onClick={handleOpenModal}
                  className="Free_btn border-0 mt-4 fs_17 fw-medium ff_heebo color_lightwhite"
                >
                  Register for Free
                </button>
              )} */}
            </div>
            <div className="col-md-4">
              <img className="w-100" src={sir_img} alt="sir_img" />
            </div>
          </div>
        </div>
      </div>
      {/* 
      <Modal
        style={{ zIndex: "23456789" }}
        show={showModal}
        onHide={handleCloseModal}
        centered
        
      >
        <div className="rounded-3 pb-4 px-4 bg_darkblue position-relative">
          <div className="position-absolute top-0 end-0">
            <img
              onClick={handleCloseModal}
              width={20}
              className="me-3 mt-3 cursor_pointer"
              src={crosswhtie_icon}
              alt="Close"
            />
          </div>
          <Modal.Header className="border-0 text-white pb-0 pt-5">
            <Modal.Title>
              <h3 className="mb-0 fs_29 ff_heebo fw-bolder text-white">
                Register for Scholarship
              </h3>

              <p className="fs_14 mt-2">
                Fill out the form to register for the scholarship exam.
              </p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={createForm} action="">
              <div className="d-flex flex-column pt-2">
                <label
                  className="fs_17 ff_heebo fw-medium text-white"
                  htmlFor="name"
                >
                  Your Full Name
                </label>
                <input
                  className="input py-2 px-3 rounded-2 mt-2"
                  type="text"
                  placeholder="Enter Full Name"
                  value={formData.name}
                  onChange={handleInputChange}
                  name="name"
                  id="name"
                />
              </div>
              <div className="d-flex flex-column mt-4">
                <label
                  className="fs_17 ff_heebo fw-medium text-white"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  className="input py-2 px-3 rounded-2 mt-2"
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  id="email"
                />
              </div>
              <div className="d-flex flex-column mt-4">
                <label
                  className="fs_17 ff_heebo fw-medium text-white"
                  htmlFor="phone"
                >
                  Contact Number
                </label>
                <div className="d-flex align-items-center gap-3 ">
                  <input
                    className="input w-100 py-2 px-3 rounded-2 mt-2"
                    type="number"
                    placeholder="Enter contact number"
                    value={formData.phone}
                    name="phone"
                    onChange={handleInputChange}
                    id="phone"
                  />
                  <button
                    type="button"
                    className="w-25 py-2 bg_yellow text-white rounded-2 mt-2 border-0 fs_17 fw-medium ff_heebo"
                    onClick={handleSendOtp}
                  >
                    Send OTP
                  </button>
                </div>
              </div>

              {otpSent && (
                <div className="d-flex flex-column mt-4">
                  <label
                    className="fs_17 ff_heebo fw-medium text-white"
                    htmlFor="otp"
                  >
                    Enter OTP
                  </label>
                  <div className="d-flex align-items-center gap-3 ">
                    <input
                      className="input w-100 py-2 px-3 rounded-2 mt-2"
                      type="number"
                      placeholder="Enter OTP"
                      value={otp}
                      name="otp"
                      onChange={handleOtpChange}
                      id="otp"
                    />
                    <button
                      type="button"
                      className="w-25 py-2 bg_yellow text-white rounded-2 mt-2 border-0 fs_17 fw-medium ff_heebo"
                      onClick={handleVerifyOtp}
                    >
                      Verify OTP
                    </button>
                  </div>
                </div>
              )}

              <button
                type="submit"
                className="Free_btn text-white border-0 py-2 px-3 mt-5 w-100 fs_17 fw-medium ff_heebo"
              >
                Submit
              </button>
            </form>
          </Modal.Body>
        </div>
      </Modal> */}
    </div>
  );
};

export default ScholarshipHero;
