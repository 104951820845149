import React from "react";
import { ReviewsData } from "../common/Helper";
import reviews_image4 from "../../assets/images/png/mandeep_img.png";
import reviews_image5 from "../../assets/images/png/harshit_img.png";
import reviews_image6 from "../../assets/images/png/boy_img.png";
import star_image from "../../assets/images/png/star_image.png";
import { Link } from "react-router-dom";

const SuccessStoriesHero = () => {
  return (
    <div className="py-5">
      <div className="container">
        <h3 className=" fs_37 ff_heebo fw-bold color_blue">
          Read our <span className=" color_yellow">success stories</span>
        </h3>

        <div className="row mt-md-4 ">
          {ReviewsData.map((obj, i) => {
            return (
              <>
                <div key={i} className="col-lg-4 col-sm-6 mt-4">
                  <div className=" position-relative">
                    {" "}
                    <img className="w-100" src={obj.image} alt="image" />
                    <div className="d-flex position-absolute bottom-0 mb-4 justify-content-between w-100 px-lg-4 px-2">
                      <h4 className=" fs_22 fw-medium text-white ff_heebo">
                        {obj.name}
                      </h4>
                      <a target="_blanck" href={obj.url} alt="hello">
                        <button className=" border-0 px-4 fs_18 rounded-2 fw-medium text-black">
                          {obj.see_video}
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
        <div className="row ">
          <div className="col-lg-4 col-sm-6 mt-4">
            {/* <Link to="/success-stories-details-page"> */}
            <div className="  bg_lightyellow h-100 rounded-2 p-4 yellow_bottom_border">
              <div className="d-flex gap-3">
                <img
                  width={50}
                  className="rounded-5"
                  src={reviews_image4}
                  alt="reviews_image4"
                />
                <div>
                  <h4 className="mb-0 fs_22 ff_heebo color_lightblack fw-medium">
                    Mandeep
                  </h4>
                  <img src={star_image} alt="star_image" />
                </div>
              </div>
              <p className=" mt-4 fs_14 ff_heebo color_darkgray">
                Before joining Kodu, I was uncertain about my career path. But
                now, I'm thrilled to say that I've secured a position in an
                excellent IT firm, all thanks to the skills I acquired here. The
                journey wasn't easy, but it was worth it. I'm excited about what
                lies ahead!
              </p>
            </div>
            {/* </Link> */}
          </div>
          <div className="col-lg-4 col-sm-6 mt-4">
            {/* <Link to="/success-stories-details-page"> */}
            <div className="  bg_lightyellow h-100 rounded-2 p-4 yellow_bottom_border">
              <div className="d-flex gap-3">
                <img
                  width={50}
                  className="rounded-5"
                  src={reviews_image5}
                  alt="reviews_image4"
                />
                <div>
                  <h4 className="mb-0 fs_22 ff_heebo color_lightblack fw-medium">
                    Harshit
                  </h4>
                  <img src={star_image} alt="star_image" />
                </div>
              </div>
              <p className=" mt-4 fs_14 ff_heebo color_darkgray">
                As a polytechnic college graduate, I was seeking a way to
                enhance my skills and open doors in the tech industry. Enrolling
                in Kodu was one of the best decisions I've made. Their practical
                approach to teaching and hands-on projects allowed me to apply
                what I learned immediately. Today, I'm proud to say that I'm
                thriving in a tech role, all thanks to Seema mam. You are best.
              </p>
            </div>
            {/* </Link> */}
          </div>
          <div className="col-lg-4 col-sm-6 mt-4">
            {/* <Link to="/success-stories-details-page"> */}
            <div className="  bg_lightyellow h-100 rounded-2 p-4 yellow_bottom_border">
              <div className="d-flex gap-3">
                <img
                  width={50}
                  className="rounded-5"
                  src={reviews_image6}
                  alt="reviews_image4"
                />
                <div>
                  <h4 className="mb-0 fs_22 ff_heebo color_lightblack fw-medium">
                    Rajveer
                  </h4>
                  <img src={star_image} alt="star_image" />
                </div>
              </div>
              <p className=" mt-4 fs_14 ff_heebo color_darkgray">
                The interview preparation and personality development classes at
                Kodu are second to none. Their comprehensive training not only
                sharpened my technical skills but also equipped me with the
                confidence and professionalism needed to excel in interviews.
                The mock interviews were particularly helpful in boosting my
                confidence and refining my communication skills. I'm grateful to
                Kodu for preparing me thoroughly for the job market.
              </p>
            </div>
            {/* </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessStoriesHero;
