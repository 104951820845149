import React from "react";
import student_img from "../../assets/images/png/student_img.png";

const SuccessStoriesDetailsHero = () => {
  return (
    <div className="py-5">
      <div className="container">
        <h3 className=" fs_43 custom_bottom_padding_space ff_heebo color_darkgray fw-bold text-center">
          Maths and Physics Graduate to<br className="d-lg-block d-none"></br>{" "}
          Software Engineer
        </h3>
      </div>
      <div className="bg_yellow d-flex justify-content-center pt-4">
        <div className="col-md-4 col-sm-8">
          <img
            className="SuccessStories_image w-100"
            src={student_img}
            alt="student_img"
          />
        </div>
      </div>
      <div className="container">
        <h5 className=" text-center fs_37 fw-medium color_red  mt-4">
          Parkash{" "}
        </h5>
        <p className=" text-center fs_16 ff_heebo color_darkgray">
          Software Engineer at Awosem Digital
        </p>
        <div className="px-md-5 pt-4">
          <p className="  fs_16 ff_heebo color_darkgray fw-medium">
            How did you find your time on the bootcamp?
          </p>
          <p className="  mt-4 fs_16 ff_heebo color_darkgray fw-medium">
            At Northcoders I felt I was moving tangibly towards an end goal,
            gaining new skills with each week. Each day was full and well paced.
            My colleagues had inspiring experiences to talk about, and the
            tutors made sure we were confident right from the start. It's
            fantastic to look back now and see how far mine and other people's
            development skills improved in 13 weeks, as shown by some amazing
            projects.
          </p>
          <p className="  mt-4 fs_16 ff_heebo color_darkgray fw-medium">
            What were you doing before the Bootcamp?
          </p>
          <p className="  mt-4 fs_16 ff_heebo color_darkgray fw-medium">
            I was looking for work after graduating from university.
          </p>
          <p className="  mt-4 fs_16 ff_heebo color_darkgray fw-medium">
            What made you interested in moving into tech?
          </p>
          <p className="  mt-4 fs_16 ff_heebo color_darkgray fw-medium">
            I had been programming for a long time, enjoyed programming, and
            felt I had aptitude, so it seemed an obvious good move.
          </p>
          <p className="  mt-4 fs_16 ff_heebo color_darkgray fw-medium">
            What made you choose Northcoders?
          </p>
          <p className="  mt-4 fs_16 ff_heebo color_darkgray fw-medium">
            I researched Northcoders, and I found it to be one of the largest
            and best established UK bootcamps, and it had good reviews.
          </p>
          <p className="  mt-4 fs_16 ff_heebo color_darkgray fw-medium">
            What was the support like on the course?
          </p>
          <p className="  mt-4 fs_16 ff_heebo color_darkgray fw-medium">
            My mentors were brilliant: firstly the positive feedback they gave
            boosted my confidence, but also they pushed me to do my best, which
            I'm really grateful for. They always had time for me. Hev pointed me
            towards technical resources and gave lots of encouragement, while
            Verity pushed me to make better git commits, and got in touch when I
            was working slowly.
          </p>
          <p className="  mt-4 fs_16 ff_heebo color_darkgray fw-medium">
            How much support did you receive from Northcoders with your job
            search after the course?
          </p>
          <p className="  mt-4 fs_16 ff_heebo color_darkgray fw-medium">
            Towards the end of the course I was assigned a careers consultant,
            who was always fast and helpful responding to questions. Keely's
            advice challenged what I thought about writing a CV, but it was
            valuable and transformative. There were also talks from partner
            companies, and it was interesting to learn about the services they
            provide, that I didn't know existed in the industry.
          </p>
          <p className="  mt-4 fs_16 ff_heebo color_darkgray fw-medium">
            What advice would you give to anyone thinking about applying?
          </p>
          <p className="  mt-4 fs_16 ff_heebo color_darkgray fw-medium">
            The FreeCodeCamp Javascript course gives sufficient background in
            Javascript. Consider taking Harvard CS50x as preparation if you
            don't have previous programming experience or comp sci foundations.
            Get comfortable reading error messages and using StackOverflow and
            the MDN JS documentation to solve them.
          </p>
          <p className="  mt-4 fs_16 ff_heebo color_darkgray fw-medium">
            The FreeCodeCamp Javascript course gives sufficient background in
            Javascript. Consider taking Harvard CS50x as preparation if you
            don't have previous programming experience or comp sci foundations.
            Get comfortable reading error messages and using StackOverflow and
            the MDN JS documentation to solve them.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SuccessStoriesDetailsHero;
