import React, { useState, useEffect } from "react";
import AdminPanel from "../adminpanel/AdminPanel";
import crosswhtie_icon from "../../assets/images/png/crosswhite_icon.png";
import { Modal } from "react-bootstrap";
import "../../../src/admin.css";

const AdminPanelPage = () => {
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);

  useEffect(() => {
    setLoginModalOpen(true);
  }, []);

  const toggleModal = () => {
    setLoginModalOpen(!isLoginModalOpen);
  };

  return (
    <div>
      <AdminPanel />
    </div>
  );
};

export default AdminPanelPage;
