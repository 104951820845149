import React from "react";
import { AmazingProjectsData } from "../common/Helper";
import { Link } from "react-router-dom";

const AmazingProjects = () => {
  return (
    <div className="py-5">
      <div className="container">
        <h3 className=" text-center fs_29 ff_heebo fw-medium text-black">
          See Amazing Projects Created By our Students
        </h3>
        <div className="row mt-5">
          {AmazingProjectsData.map((obj, i) => {
            return (
              <>
                <div key={i} className="col-md-4 col-6 mt-4">
                  <div className=" bg_lightblue rounded-md-4 rounded-2">
                    <img
                      className="w-100"
                      src={obj.image}
                      alt="project_image"
                    />
                    <div className=" p-sm-4 p-3">
                      <h4 className="fs_22 ff_heebo fw-medium color_darkgray mb-0">
                        {obj.name}
                      </h4>
                      <p className="fs_16 ff_heebo color_darkgray mb-0 mt-2">
                        {obj.project_name}
                      </p>
                      <a target="_blank" href={obj.link}>
                        <button className="w-100 border-0 bg_darkblue fs_22 mt-3 ff_heebo fw-medium color_lightwhite py-2 rounded-2">
                          {obj.button}
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AmazingProjects;
