import React from "react";

function Compare() {
  return (
    <div className="container custom_bottom_padding_space">
      <h1 className="ff_heebo fs_26 fw-bold text-center my-5">
        Comparative between Kodu's Live Courses vs Others
      </h1>
      <div className="comparecontainer table-responsive">
        <table class="table">
          <thead class="thead-dark ">
            <tr>
              <th
                scope="col"
                className="table_bg_yellow borderTopleft py-3 px-3 border-right ff_heebo fs_14"
              >
                Price
              </th>
              <th
                scope="col"
                className="table_bg_yellow py-3 border-right px-3 ff_heebo fs_14 text-nowrap"
              >
                3000/- including taxes
              </th>
              <th
                scope="col"
                className="table_bg_yellow borderTopRight py-3 px-3 ff_heebo fs_14 text-nowrap"
              >
                3500 - 17999/- plus taxes
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="table_bg_yellow py-3 px-3 ff_heebo fs_14 fw-bold">
                No. of Live interactive lecture hours
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                220+ hours
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                3500 - 17999/- plus taxes
              </td>
            </tr>
            <tr>
              <td className="table_bg_yellow py-3 px-3 ff_heebo fs_14 fw-bold">
                No. of Coding Questions
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                400+
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                60 - 150 hours
              </td>
            </tr>
            <tr>
              <td className="table_bg_yellow py-3 px-3 ff_heebo fs_14 fw-bold">
                Mic access - interact with mentor during classes
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                Available
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                200 - 400+
              </td>
            </tr>
            <tr>
              <td className="table_bg_yellow py-3 px-3 ff_heebo fs_14 fw-bold">
                Doubt Support
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                1:1 Doubt support through Physical, live, Web or Chat support
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                Typically not available
              </td>
            </tr>
            <tr>
              <td className="table_bg_yellow py-3 px-3 ff_heebo fs_14 fw-bold">
                System design module
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                Included with DSA course
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                Variable means for different players
              </td>
            </tr>
            <tr>
              <td className="table_bg_yellow py-3 px-3 ff_heebo fs_14 fw-bold">
                LMS for Coding questions and Recorded lectures
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                Integrated IDE, Code editor, Leaderboard, Video lectures, Notes
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                Variable, yes for some, no for some
              </td>
            </tr>
            <tr>
              <td className="table_bg_yellow py-3 px-3 ff_heebo fs_14 fw-bold">
                Placement and Internship assistance
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                Available
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                Variable, yes for some, no for some
              </td>
            </tr>
            <tr>
              <td className="table_bg_yellow py-3 px-3 ff_heebo fs_14 fw-bold">
                Certificate course
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                Available
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                Variable, yes for some, no for some
              </td>
            </tr>
            <tr>
              <td className="table_bg_yellow py-3 px-3 ff_heebo fs_14 fw-bold">
                Leetcode, Codeforces profiles
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                Available
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                Variable, yes for some, no for some
              </td>
            </tr>
            <tr>
              <td className="table_bg_yellow py-3 px-3 ff_heebo fs_14 fw-bold">
                Developer CV
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                Available
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                Variable, yes for some, no for some
              </td>
            </tr>
            <tr>
              <td className="table_bg_yellow py-3 px-3 ff_heebo fs_14 fw-bold">
                Content access after Course completion
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                6 months
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                3-6 months
              </td>
            </tr>
            <tr>
              <td className="table_bg_yellow py-3 px-3 ff_heebo fs_14 fw-bold borderbottomleft">
                Pre recorded content for lectures
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                Only Live interactive lectures are given, recordings of these
                are given in LMS after lecture is complete
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3 borderbottomright">
                Pre recorded content is given for content in certain cases
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="py-5">
        <h1 className="ff_heebo fs_26 fw-bold text-center my-5">
          Comparative between different modes of learning
        </h1>
      </div>
      <div className="comparecontainer table-responsive">
        <table class="table">
          <thead class="thead-dark ">
            <tr>
              <th
                scope="col"
                className="table_bg_yellow borderTopleft py-3 px-3 border-right ff_heebo fs_14"
              >
                Price
              </th>
              <th
                scope="col"
                className="table_bg_yellow py-3 border-right px-3 ff_heebo fs_14 text-nowrap"
              >
                3000/- including taxes
              </th>
              <th
                scope="col"
                className="table_bg_yellow borderTopRight py-3 px-3 ff_heebo fs_14 text-nowrap"
              >
                3500 - 17999/- plus taxes
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="table_bg_yellow py-3 px-3 ff_heebo fs_14 fw-bold">
                No. of Live interactive lecture hours
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                220+ hours
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                3500 - 17999/- plus taxes
              </td>
            </tr>
            <tr>
              <td className="table_bg_yellow py-3 px-3 ff_heebo fs_14 fw-bold">
                No. of Coding Questions
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                400+
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                60 - 150 hours
              </td>
            </tr>
            <tr>
              <td className="table_bg_yellow py-3 px-3 ff_heebo fs_14 fw-bold">
                Mic access - interact with mentor during classes
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                Available
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                200 - 400+
              </td>
            </tr>
            <tr>
              <td className="table_bg_yellow py-3 px-3 ff_heebo fs_14 fw-bold">
                Doubt Support
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                1:1 Doubt support through Physical, live, Web or Chat support
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                Typically not available
              </td>
            </tr>
            <tr>
              <td className="table_bg_yellow py-3 px-3 ff_heebo fs_14 fw-bold">
                System design module
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                Included with DSA course
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                Variable means for different players
              </td>
            </tr>
            <tr>
              <td className="table_bg_yellow py-3 px-3 ff_heebo fs_14 fw-bold">
                LMS for Coding questions and Recorded lectures
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                Integrated IDE, Code editor, Leaderboard, Video lectures, Notes
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                Variable, yes for some, no for some
              </td>
            </tr>
            <tr>
              <td className="table_bg_yellow py-3 px-3 ff_heebo fs_14 fw-bold">
                Placement and Internship assistance
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                Available
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                Variable, yes for some, no for some
              </td>
            </tr>
            <tr>
              <td className="table_bg_yellow py-3 px-3 ff_heebo fs_14 fw-bold">
                Certificate course
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                Available
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                Variable, yes for some, no for some
              </td>
            </tr>
            <tr>
              <td className="table_bg_yellow py-3 px-3 ff_heebo fs_14 fw-bold">
                Leetcode, Codeforces profiles
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                Available
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                Variable, yes for some, no for some
              </td>
            </tr>
            <tr>
              <td className="table_bg_yellow py-3 px-3 ff_heebo fs_14 fw-bold">
                Developer CV
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                Available
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                Variable, yes for some, no for some
              </td>
            </tr>
            <tr>
              <td className="table_bg_yellow py-3 px-3 ff_heebo fs_14 fw-bold">
                Content access after Course completion
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                6 months
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                3-6 months
              </td>
            </tr>
            <tr>
              <td className="table_bg_yellow py-3 px-3 ff_heebo fs_14 fw-bold borderbottomleft">
                Pre recorded content for lectures
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3">
                Only Live interactive lectures are given, recordings of these
                are given in LMS after lecture is complete
              </td>
              <td className="py-3 border-right-yellow border-bottom-yellow ff_heebo fs_14 fw-bold px-3 borderbottomright">
                Pre recorded content is given for content in certain cases
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Compare;
