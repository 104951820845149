import React from "react";
import date_icon from "../../assets/images/png/date_icon.png";
import bach1 from "../../assets/images/png/bach1.png";

const ExamDetails = () => {
  return (
    <div className=" py-5">
      <div className="container">
        <h4 className=" fs_37 fw-bold ff_heebo tgext-black">Exam Details</h4>
        <div className="row ">
          <div className="col-lg-6 mt-4">
            <div className="d-sm-flex gap-5 cursor_pointer align-items-center features_box h-100 p-4 rounded-3 justify-content-center">
              <div className=" ">
                <img width={80} src={date_icon} alt="date_icon" />
              </div>
              <div className="mt-4 mt-sm-0">
                <h5 className=" fs_29 ff_heebo fw-bold text-black mb-0">
                  Exam Dates
                </h5>
                <p className=" fs_20 ff_heebo mb-1 mt-2">
                  <span className="fw-bold">Weekly</span> - 1 time
                </p>
                <p className=" fs_20 ff_heebo mb-1 mt-2">
                  <span className="fw-bold">Day</span> - Saturday
                </p>
                <p className=" fs_20 ff_heebo mb-1 mt-2">
                  <span className="fw-bold">Medium </span> - Offline & Online
                  Both
                </p>
                {/* <p className=" fs_22 fw-semibold ff_heebo ">
                  Offline :7th April
                </p> */}
              </div>
            </div>
          </div>
          <div className="col-lg-6 mt-4">
            <div className="d-sm-flex gap-4 cursor_pointer align-items-center features_box h-100 p-4 rounded-3 justify-content-center">
              <div className=" mt-4">
                <img width={80} src={date_icon} alt="date_icon" />
              </div>
              <div className="mt-4 mt-sm-0">
                <h5 className=" fs_29 ff_heebo fw-bold text-black mb-0">
                  Exam Timings
                </h5>
                <p className=" fs_20 ff_heebo mb-0 mt-2">
                  <strong>Online</strong> : Anytime{" "}
                </p>
                <p className=" fs_20 ff_heebo mb-0 mt-2">
                  <strong>Offline</strong> : Anytime{" "}
                </p>
                {/* <p className=" fs_22 fw-semibold ff_heebo mb-0 mt-2 ">
                  <strong>Offline</strong> :12:00 PM - 04:00 PM
                </p> */}
              </div>
            </div>
          </div>
        </div>
        <div className="pt-5">
          <div className="bg_yellow mt-md-5 p-md-5 p-4 rounded-3">
            <div className="row align-items-center">
              <div className="col-md-9">
                <h4 className=" fs_37 fw-bold ff_heebo ">About Scholarship</h4>
                <p className=" fs_20 ff_heebo">
                  Kodu's test finds talented students who want to learn coding,
                  programming, and AI. Kodu will help pay for their education,
                  offering up to 70% in scholarships.
                </p>
              </div>
              <div className="col-md-3 col-8">
                <img
                  className="w-100 custom_bottom_margin"
                  src={bach1}
                  alt="bach1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExamDetails;
