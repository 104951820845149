import React, { useEffect, useState, useCallback } from "react";
import { Multiselect } from "multiselect-react-dropdown";
import makeApiCall from "../callApi";

const MultiyTestSelectComponent = ({ selectedValues, setSelectedValues }) => {
  const [testList, setTestList] = useState([]);

  useEffect(() => {
    getTest();
  }, []);

  async function getTest() {
    await makeApiCall("", "get", "admin/testList", testListCallBack);
  }

  const testListCallBack = useCallback((response) => {
    if (response.status === 200) {
      if (Array.isArray(response.data.data)) {
        const titles = response.data.data.map((item, index) => ({
          title: item?.title,
          id: item?._id,
          index: index,
        }));
        setTestList(titles);
      } else {
        console.log("Response data is not an array:", response.data.data);
      }
    } else {
      console.log("Error fetching courses");
    }
  }, []);

  const onSelect = (selectedList) => {
    setSelectedValues(selectedList);
  };

  const onRemove = (selectedList) => {
    setSelectedValues(selectedList);
  };

  return (
    <div>
      <Multiselect
        options={testList}
        selectedValues={selectedValues}
        onSelect={onSelect}
        onRemove={onRemove}
        displayValue="title"
      />
    </div>
  );
};

export default MultiyTestSelectComponent;
