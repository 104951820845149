import React from "react";
import featuresimg from "../../assets/images/png/Features_icon.png";
import featuresimg1 from "../../assets/images/png/Features_icon1.png";
import featuresimg2 from "../../assets/images/png/Features_icon2.png";
import featuresimg3 from "../../assets/images/png/Features_icon3.png";
import featuresimg4 from "../../assets/images/png/Features_icon4.png";
import featuresimg5 from "../../assets/images/png/Features_icon5.png";
import featuresimg6 from "../../assets/images/png/Features_icon6.png";
import featuresimg7 from "../../assets/images/png/Features_icon7.png";
import { Container, Row } from "react-bootstrap";

const MasterFeaturesProgram = () => {
  const masterFeaturesData = [
    {
      img: featuresimg,
      title: "Collaborative learning with 2-4 coding buddies.",
    },
    {
      img: featuresimg1,
      title: "Personalized feedback from experienced instructors.",
    },
    {
      img: featuresimg2,
      title: "Access to exclusive study materials.",
    },
    {
      img: featuresimg3,
      title: "Opportunities for coding competitions and hackathons.",
    },
    {
      img: featuresimg4,
      title: "Networking with industry professionals.",
    },
    {
      img: featuresimg5,
      title: "Regular progress assessments and goal setting.",
    },
  ];
  return (
    <div className="pb-5 mt-5">
      <Container>
        <h4 className=" fs_26 fw-semibold ff_heebo text-center">
          Features of this Program
        </h4>
        <Row>
          {masterFeaturesData.map((obj, i) => {
            return (
              <>
                <div key={i} className="col-lg-3 col-md-4 col-sm-6 mt-4">
                  <div className="bg_darkblue h-100 cursor_pointer p-4 rounded-2">
                    <img width={45} src={obj.img} alt="image" />
                    <p className=" fs_22 fw-medium ff_heebo text-white mt-3 mb-0">
                      {obj.title}
                    </p>
                  </div>
                </div>
              </>
            );
          })}
        </Row>
      </Container>
    </div>
  );
};

export default MasterFeaturesProgram;
