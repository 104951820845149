import React from "react";
import yellow_icon from "../../assets/images/png/yellow_icon.png";
import yellow_icon1 from "../../assets/images/png/yellow_icon1.png";
import { Accordion } from "react-bootstrap";

const RecordedCurriculum = () => {
  return (
    <div className="pt-md-5">
      <div className="container">
        <h3 className=" fs_37 fw-bold ff_heebo color_yellow text-center">
          Curriculum
        </h3>
        <div className="row mt-2 align-items-center justify-content-center">
          <div className="col-lg-2 col-md-3 col-sm-2 mt-4">
            <div className="d-flex align-items-center gap-3">
              <img width={50} src={yellow_icon} alt="yellow_icon" />
              <div>
                <h5 className=" mb-0">4.8</h5>
                <p className=" mb-0">rating</p>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-2 mt-4">
            <div className="d-flex align-items-center gap-3">
              <img width={50} src={yellow_icon} alt="yellow_icon" />
              <div>
                <h5 className=" mb-0">4000+</h5>
                <p className=" mb-0">students</p>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-2 mt-4">
            <div className="d-flex align-items-center gap-3">
              <img width={50} src={yellow_icon} alt="yellow_icon" />
              <div>
                <h5 className=" mb-0">1 time</h5>
                <p className=" mb-0">payment</p>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-2 mt-4">
            <div className="d-flex align-items-center gap-3">
              <img width={50} src={yellow_icon} alt="yellow_icon" />
              <div>
                <h5 className=" mb-0">Anyone</h5>
                <p className=" mb-0">can do (IT/NonIT)</p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 recoded_faq">
          <Accordion defaultActiveKey="0">
            <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="0">
              <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                <li className=" custom_list_style_type color_yellow"></li>
                What is DevOps?
              </Accordion.Header>
              <Accordion.Body className="px-5 pb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="1">
              <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                <li className=" custom_list_style_type color_yellow"></li>
                What is the purpose of this DevOps Training?
              </Accordion.Header>
              <Accordion.Body className="px-5 pb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="2">
              <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                <li className=" custom_list_style_type color_yellow"></li>
                What are the benefits of taking DevOps Courses?
              </Accordion.Header>
              <Accordion.Body className="px-5 pb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="3">
              <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                <li className=" custom_list_style_type color_yellow"></li>
                What is the purpose of this DevOps Training?
              </Accordion.Header>
              <Accordion.Body className="px-5 pb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="4">
              <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                <li className=" custom_list_style_type color_yellow"></li>
                Do I need any prior experience to become a DevOps Engineer?
              </Accordion.Header>
              <Accordion.Body className="px-5 pb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="5">
              <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                <li className=" custom_list_style_type color_yellow"></li>
                What does a DevOps Engineer do?
              </Accordion.Header>
              <Accordion.Body className="px-5 pb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="6">
              <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                <li className=" custom_list_style_type color_yellow"></li>
                Module 4: JavaScript
              </Accordion.Header>
              <Accordion.Body className="px-5 pb-4">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur. Excepteur sint occaecat cupidatat non proident,
                sunt in culpa qui officia deserunt mollit anim id est laborum.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <h4 className=" text-center fs_37 fw-bold ff_heebo color_blue mt-5">
          Course Benefits
        </h4>
        <div className="row">
          <div className="col-md-6 mt-4">
            <div className="bg_darkblue p-3 rounded-2">
              <div className="d-flex  align-items-center gap-3">
                <img width={40} src={yellow_icon1} alt="yellow_icon1" />
                <p className="mb-0 text-white ">
                  Be able to build ANY website you want.
                </p>
              </div>
              <div className="d-flex mt-3 align-items-center gap-3">
                <img width={40} src={yellow_icon1} alt="yellow_icon1" />
                <p className="mb-0 text-white ">
                  Become a front-end and Back-end developer - Complete Full
                  Stack Developer.
                </p>
              </div>
              <div className="d-flex mt-3 align-items-center gap-3">
                <img width={40} src={yellow_icon1} alt="yellow_icon1" />
                <p className="mb-0 text-white ">
                  Build 2 major projects which you can add in your resume and
                  submit as final year projects too
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 mt-4">
            <div className="bg_darkblue p-3 rounded-2">
              <div className="d-flex  align-items-center gap-3">
                <img width={40} src={yellow_icon1} alt="yellow_icon1" />
                <p className="mb-0 text-white ">
                  Be able to build ANY website you want.
                </p>
              </div>
              <div className="d-flex mt-3 align-items-center gap-3">
                <img width={40} src={yellow_icon1} alt="yellow_icon1" />
                <p className="mb-0 text-white ">
                  Become a front-end and Back-end developer - Complete Full
                  Stack Developer.
                </p>
              </div>
              <div className="d-flex mt-3 align-items-center gap-3">
                <img width={40} src={yellow_icon1} alt="yellow_icon1" />
                <p className="mb-0 text-white ">
                  Build 2 major projects which you can add in your resume and
                  submit as final year projects too
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecordedCurriculum;
