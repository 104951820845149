// import React, { useCallback, useState } from "react";
// import free_img from "../../assets/images/png/free_img.png";
// import makeApiCall from "../callApi";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { Spinner } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";

// const MasterClassHero = () => {
//   const [formData, setformData] = useState({
//     name: "",
//     email: "",
//     phone: "",
//     topic: "",
//     qualification: "",
//     course: "",
//     nearestCenter: "",
//   });
//   const navigate = useNavigate();
//   const [isLoading, setIsLoading] = useState(false);

//   const handleInputChange = (e) => {
//     const { name, value, files } = e.target;
//     if (files) {
//       setformData((prevData) => ({
//         ...prevData,
//         [name]: files?.length > 0 ? files[0] : value,
//       }));
//     } else {
//       setformData((prevData) => ({
//         ...prevData,
//         [name]: value,
//       }));
//     }
//   };

//   const validateForm = () => {
//     const { name, email, phone, topic, qualification, course, nearestCenter } =
//       formData;
//     if (
//       !name ||
//       !email ||
//       !phone ||
//       !topic ||
//       !qualification ||
//       !course ||
//       !nearestCenter
//     ) {
//       toast.error("All fields are required");
//       return false;
//     }
//     if (
//       qualification === " -- Select Qualification --" ||
//       course === " -- Select Course --" ||
//       nearestCenter === " -- Select Nearest Center --"
//     ) {
//       toast.error("Please select valid options for all dropdowns");
//       return false;
//     }
//     return true;
//   };

//   const createForm = async (e) => {
//     e.preventDefault();

//     if (!validateForm()) {
//       return;
//     }

//     setIsLoading(true);

//     const formdata = {
//       name: formData.name,
//       email: formData.email,
//       phone: formData.phone,
//       topic: formData.topic,
//       qualification: formData.qualification,
//       course: formData.course,
//       nearestCenter: formData.nearestCenter,
//     };

//     try {
//       const res = await makeApiCall(
//         formdata,
//         "post",
//         "new_lead",
//         createFormCallBack,
//         false
//       );
//       // createFormCallBack(res);
//     } catch (error) {
//       toast.error("Failed to create lead. Please try again later.");
//       setIsLoading(false);
//     }
//   };

//   const createFormCallBack = useCallback((res) => {
//     setIsLoading(false);
//     if (res.data.status === "success") {
//       toast.success(
//         "Congratulations! Your request has been submitted successfully!"
//       );
//       setformData({
//         name: "",
//         email: "",
//         phone: "",
//         topic: "",
//         qualification: "",
//         course: "",
//         nearestCenter: "",
//       });
//       setTimeout(() => navigate("/thankq-page"), 1500);
//     } else {
//       toast.error(res.data.message);
//     }
//   });

//   return (
//     <div id="form" className="py-5">
//       <ToastContainer />
//       <div className="container">
//         <div className="row flex-column-reverse flex-md-row align-items-center justify-content-between">
//           <div className="col-lg-5 mt-4 mt-lg-0">
//             <img className="w-100" src={free_img} alt="free_img" />
//             <h3 className="fs-1 fw-semibold ff_heebo mt-4">
//               KODU - Code the Future Master Coding From Scratch
//             </h3>
//           </div>
//           <div className="col-lg-6">
//             <div className="bg_darkblue rounded-4 p-4">
//               <h4 className="text-center color_yellow ff_heebo fs_29 fw-bolder ff_redrose">
//                 Book your Free Session
//               </h4>
//               <p className="text-center fs_18 ff_heebo color_lightwhite">
//                 Learn from Best IT Experts
//               </p>
//               <form action="" onSubmit={createForm}>
//                 <div className="d-flex flex-column mt-4">
//                   <label className="text-white ff_heebo" htmlFor="Name">
//                     Name
//                   </label>
//                   <input
//                     required
//                     type="text"
//                     className="py-2 px-3 mt-2"
//                     placeholder="Enter Name"
//                     value={formData.name}
//                     onChange={handleInputChange}
//                     name="name"
//                   />
//                 </div>
//                 <div className="d-flex flex-column mt-4">
//                   <label className="text-white ff_heebo" htmlFor="Name">
//                     Enter Your Mobile Number
//                   </label>
//                   <input
//                     required
//                     type="number"
//                     className="py-2 px-3 mt-2"
//                     placeholder="Enter Mobile Number"
//                     name="phone"
//                     value={formData.phone}
//                     onChange={handleInputChange}
//                   />
//                 </div>
//                 <div className="d-flex flex-column mt-4">
//                   <label className="text-white ff_heebo" htmlFor="Name">
//                     Email
//                   </label>
//                   <input
//                     required
//                     type="email"
//                     className="py-2 px-3 mt-2"
//                     placeholder="Enter Email"
//                     value={formData.email}
//                     name="email"
//                     onChange={handleInputChange}
//                   />
//                 </div>
//                 <div className="d-flex flex-column mt-4">
//                   <label className="text-white ff_heebo" htmlFor="Name">
//                     City
//                   </label>
//                   <input
//                     required
//                     type="text"
//                     className="py-2 px-3 mt-2"
//                     placeholder="Enter City"
//                     value={formData.topic}
//                     name="topic"
//                     onChange={handleInputChange}
//                   />
//                 </div>
//                 <div className="d-flex flex-column mt-4">
//                   <label className="text-white ff_heebo" htmlFor="Name">
//                     Nearest Center
//                   </label>
//                   <select
//                     required
//                     value={formData.nearestCenter}
//                     name="nearestCenter"
//                     onChange={handleInputChange}
//                     className="py-2 px-3 mt-2"
//                     id="nearestCenter"
//                   >
//                     <option value=" -- Select Nearest Center --">
//                       -- Select Nearest Center --
//                     </option>
//                     <option value="Hisar">Hisar</option>
//                     <option value="Rohtak">Rohtak</option>
//                     <option value="Sirsa">Sirsa</option>
//                     <option value="Sikar">Sikar</option>
//                   </select>
//                 </div>
//                 <div className="d-flex flex-column mt-4">
//                   <label className="text-white ff_heebo" htmlFor="Name">
//                     Qualification:
//                   </label>
//                   <select
//                     required
//                     value={formData.qualification}
//                     name="qualification"
//                     onChange={handleInputChange}
//                     className="py-2 px-3 mt-2"
//                     id=""
//                   >
//                     <option value=" -- Select Qualification --">
//                       -- Select Qualification --
//                     </option>
//                     <option value="10th">10th</option>
//                     <option value="11th">11th</option>
//                     <option value="12th">12th</option>
//                     <option value="Graduation">Graduation</option>
//                     <option value="Post Graduation">Post Graduation</option>
//                   </select>
//                 </div>
//                 <div className="d-flex flex-column mt-4">
//                   <label className="text-white ff_heebo" htmlFor="Name">
//                     Which course are you looking for?
//                   </label>
//                   <select
//                     required
//                     value={formData.course}
//                     name="course"
//                     onChange={handleInputChange}
//                     className="py-2 px-3 mt-2"
//                     id=""
//                   >
//                     <option value=" -- Select Course --">
//                       -- Select Course --
//                     </option>
//                     <option value="Frontend Development">
//                       Frontend Development
//                     </option>
//                     <option value="Backend Development">
//                       Backend Development
//                     </option>
//                     <option value="FullStack Development">
//                       FullStack Development
//                     </option>
//                     <option value="Web Development">Web Development</option>
//                     <option value="Mobile App Development">
//                       Mobile App Development
//                     </option>
//                   </select>
//                 </div>
//                 <button
//                   type="submit"
//                   className="mt-5 bg_yellow ff_heebo fs_19 border-0 text-white w-100 py-2"
//                   disabled={isLoading}
//                 >
//                   {isLoading ? <Spinner /> : "Request Callback"}
//                 </button>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default MasterClassHero;
import React, { useState, useCallback } from "react";
import free_img from "../../assets/images/png/free_img.png";
import makeApiCall from "../callApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const MasterClassHero = () => {
  const [formData, setformData] = useState({
    name: "",
    email: "",
    phone: "",
    topic: "",
    qualification: "",
    course: "",
    nearestCenter: "",
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const fields = [
    {
      name: "qualification",
      label: "Select You Highest Qualification ",
      type: "select",
      options: ["10th", "11th", "12th", "Graduation", "Post Graduation"],
    },
    {
      name: "nearestCenter",
      label: "Your Nearest Center",
      type: "select",
      options: ["Hisar", "Rohtak", "Sirsa", "Sikar"],
    },

    // {
    //   name: "course",
    //   label: "3. Which course are you looking for?",
    //   type: "select",
    //   options: [
    //     "Frontend Development",
    //     "Backend Development",
    //     "FullStack Development",
    //     "Web Development",
    //     "Mobile App Development",
    //   ],
    // },
    {
      name: "namePhoneCity",
      label: "Personal Information",
      type: "group",
      fields: [
        {
          name: "name",
          label: "Name",
          type: "text",
          placeholder: "Enter Name",
        },
        {
          name: "phone",
          label: "Mobile Number",
          type: "number",
          placeholder: "Enter Mobile Number",
        },
        // {
        //   name: "email",
        //   label: "5. Enter Your Email",
        //   type: "email",
        //   placeholder: "Enter Email",
        // },
        {
          name: "topic",
          label: "City",
          type: "text",
          placeholder: "Enter City",
        },
      ],
    },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setformData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleOptionSelect = (name, value) => {
    setformData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleNext = () => {
    const field = fields[currentStep];
    if (field.type === "select" && !formData[field.name]) {
      toast.error("Please select a valid option");
      return;
    }
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (currentStep < fields.length - 1) {
      handleNext();
      return;
    }

    setIsLoading(true);

    try {
      const res = await makeApiCall(
        formData,
        "post",
        "new_lead",
        createFormCallBack,
        false
      );
    } catch (error) {
      toast.error("Failed to create lead. Please try again later.");
      setIsLoading(false);
    }
  };

  const createFormCallBack = useCallback((res) => {
    setIsLoading(false);
    if (res.data.status === "success") {
      toast.success(
        "Congratulations! Your request has been submitted successfully!"
      );
      setformData({
        name: "",
        email: "",
        phone: "",
        topic: "",
        qualification: "",
        course: "",
        nearestCenter: "",
      });
      setTimeout(() => navigate("/thankq-page"), 1500);
    } else {
      toast.error(res.data.message);
    }
  });

  return (
    <div id="form" className="py-5 mb-md-5">
      <ToastContainer />
      <div className="container">
        <div className="row  align-items-center justify-content-between">
          <div className="col-lg-5 mt-lg-0">
            <img
              className="w-50 mt-4 d-lg-block d-none"
              src={free_img}
              alt="free_img"
            />
            <h3 className="fs-3 fw-semibold ff_heebo d-lg-block d-none mt-2 mb-4 text-center text-md-start">
              KODU - Code the Future Master Coding From Scratch
            </h3>
          </div>
          <div className="col-lg-5">
            <div className=" card rounded-4 p-4">
              <h4 className="text-center color_yellow ff_heebo fs_29 fw-bolder ff_redrose">
                Book your Free Session
              </h4>
              {/* <p className="text-center fs_18 ff_heebo text-black">
                Learn from Best IT Experts
              </p> */}
              <form onSubmit={handleSubmit}>
                <div className="d-flex flex-column mt-2">
                  <label
                    className=" color_blue text-center mb-1 fs-5 fw-bold ff_heebo"
                    htmlFor={fields[currentStep].name}
                  >
                    {fields[currentStep].label}
                  </label>
                  {fields[currentStep].type === "select" ? (
                    <div className="options-container text-center rounded-5">
                      {fields[currentStep].options.map((option) => (
                        <div
                          key={option}
                          className="option bg_darkblue cursor_pointer rounded-5  py-2 px-3 mt-3 text-white fw-bold ff_heebo"
                          onClick={() =>
                            handleOptionSelect(fields[currentStep].name, option)
                          }
                        >
                          {option}
                        </div>
                      ))}
                    </div>
                  ) : fields[currentStep].type === "group" ? (
                    fields[currentStep].fields.map((subField) => (
                      <div key={subField.name} className="mb-3">
                        {/* <label className="text-white fw-bold ff_heebo">
                          {subField.label}
                        </label> */}
                        <input
                          required
                          type={subField.type}
                          className="py-2 card rounded-3 px-3 mt-2 w-100"
                          placeholder={subField.placeholder}
                          value={formData[subField.name]}
                          name={subField.name}
                          onChange={handleInputChange}
                        />
                      </div>
                    ))
                  ) : (
                    <input
                      required
                      type={fields[currentStep].type}
                      className="py-2 px-3 mt-2"
                      placeholder={fields[currentStep].placeholder}
                      value={formData[fields[currentStep].name]}
                      name={fields[currentStep].name}
                      onChange={handleInputChange}
                    />
                  )}
                </div>
                {fields[currentStep].type !== "select" && (
                  <button
                    type="submit"
                    className="mt-4 option bg_darkblue rounded-5 ff_heebo fs_19 border-0 text-white w-100 py-2"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <Spinner />
                    ) : currentStep < fields.length - 1 ? (
                      "Next"
                    ) : (
                      "Request Callback"
                    )}
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MasterClassHero;
