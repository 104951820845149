import React, { useCallback, useEffect, useState } from "react";
import makeApiCall from "../callApi";
import ReactQuill from "react-quill";
import crossblack_icon from "../../assets/images/png/blackcross_icon.png";
import edit_icon from "../../assets/images/png/edit_icon.png";
import delete_icon from "../../assets/images/png/delete_icon.png";
import { Modal } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import MultiyContentSelect from "./MultiyContentSelect";

const ContentTab = () => {
  const [addCourseModal, setAddCourseModal] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedValuesForUpdate, setSelectedValuesForUpdate] = useState([]);
  const [freeTrialModalVisible, setFreeTrialModalVisible] = useState(false);
  const [courseData, setCourseData] = useState({
    id: "",
    title: "",
    duration: "",
    courseIds: "",
    description: "",
  });
  const [newcourseData, setNewcourseData] = useState({ title: "" });
  const [value, setValue] = useState("");
  const [newValue, setNewValue] = useState("");

  useEffect(() => {
    setNewcourseData({
      id: currentData?._id,
      title: currentData?.title,
      duration: currentData?.duration,
      courseIds: currentData?.courseIds,
      description: currentData?.description,
    });
    setNewValue(currentData?.description);
    setSelectedValuesForUpdate(currentData?.selectedValues || []);
  }, [currentData]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setCourseData((prevData) => ({
        ...prevData,
        [name]: files?.length > 0 ? files[0] : value,
      }));
    } else {
      setCourseData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const handleInputChange2 = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setNewcourseData((prevData) => ({
        ...prevData,
        [name]: files?.length > 0 ? files[0] : value,
      }));
    } else {
      setNewcourseData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    console.log(newcourseData);
  };

  async function createBlog(e) {
    e.preventDefault();
    // const formData = new FormData();

    // formData.append("title", courseData.title);
    // formData.append("duration", courseData.duration);
    // formData.append("description", value);
    let ids = [];
    selectedValues.forEach((value) => {
      ids.push(value.id);
    });

    let formData = {
      title: courseData.title,
      duration: courseData.duration,
      description: value,
      courseIds: ids,
    };

    await makeApiCall(
      formData,
      "post",
      "admin/updateContent",
      createblogCallBack,
      false
    );
  }

  const createblogCallBack = useCallback(async (res) => {
    console.log(res);
    if (res.data.status == "success") {
      setAddCourseModal(false);
      getcourse();
    }
  });
  async function updateBlog(e) {
    e.preventDefault();
    let ids = [];
    selectedValues.forEach((value) => {
      ids.push(value.id);
    });
    const formdata = {
      id: newcourseData?.id,
      title: newcourseData?.title,
      duration: newcourseData?.duration,
      courseIds: ids,
      description: newValue,
    };
    await makeApiCall(
      formdata,
      "post",
      "admin/updateContent",
      updateblogCallBack,
      false
    );
  }

  const updateblogCallBack = useCallback(async (res) => {
    console.log(res);
    if (res.data.status == "success") {
      setFreeTrialModalVisible(false);
      getcourse();
    }
  });

  useEffect(() => {
    console.log(courseData);
  }, [courseData]);

  useEffect(() => {
    console.log(value);
  }, [value]);

  const [blogList, SetblogList] = useState([]);

  async function getcourse() {
    await makeApiCall("", "get", "admin/contentList", blogListCallBack);
  }
  useEffect(() => {
    getcourse();
  }, []);

  const blogListCallBack = useCallback((response) => {
    console.log(response);
    if (response.status === 200) {
      SetblogList(response.data.data);
    } else {
    }
  });

  async function deleteCourse(id) {
    await makeApiCall(
      "",
      "get",
      `admin/deleteContent/${id}`,
      coursesDeleteCallBack
    );
  }

  const coursesDeleteCallBack = useCallback(
    (response) => {
      console.log(response);
      if (response.status === 200) {
        console.log("Course deleted successfully");
        getcourse();
      } else {
        console.error("Error deleting course");
      }
    },
    [getcourse]
  );
  useEffect(() => {
    getcourse();
  }, []);

  const toggleFreeTrialModal = () => {
    setFreeTrialModalVisible(!freeTrialModalVisible);
  };

  const handleResizeError = (e) => {
    if (e.message.includes("ResizeObserver")) {
      console.error("ResizeObserver error suppressed:", e);
      return;
    }
    throw e;
  };

  window.addEventListener("error", handleResizeError);

  return (
    <div>
      <h2 className=" fs_37 fw-semibold ff_heebo">Course Content</h2>
      <div className="border my-4"></div>

      {!addCourseModal ? (
        <>
          <div className=" mb-4">
            <button
              onClick={() => setAddCourseModal(true)}
              className=" bg_yellow py-2 px-4 border-0 ff_heebo text-white fw-bold"
            >
              Add Course Content
            </button>
          </div>
          <div className="  border-0 ff_heebo pb-4">
            <div
              className="card rounded-3"
              style={{ maxWidth: "1300px", overflowX: "auto" }}
            >
              <table className="w-100">
                <tr>
                  <th className="py-3 text-white ff_heebo  px-3 bg_darkblue">
                    Title
                  </th>
                  <th className="py-3 text-white ff_heebo  px-3 bg_darkblue">
                    Duration
                  </th>

                  <th className="py-3 text-white ff_heebo  px-3 bg_darkblue">
                    Action
                  </th>
                </tr>
                {blogList?.length < 1 ? (
                  <div className="text-start ps-3 py-4">No data found</div>
                ) : (
                  <>
                    {blogList?.map((obj, i) => {
                      return (
                        <>
                          <tr key={i}>
                            <td className="px-3 py-3">{obj.title}</td>
                            <td className="px-3 py-3">{obj.duration}</td>
                            <td className="px-3 py-3">
                              <div className="d-flex gap-3 bg-white">
                                <img
                                  onClick={() => {
                                    toggleFreeTrialModal();
                                    setCurrentData(obj);
                                  }}
                                  width={20}
                                  className=" cursor_pointer"
                                  src={edit_icon}
                                  alt=""
                                />
                                <img
                                  onClick={() => deleteCourse(obj._id)}
                                  width={20}
                                  className=" cursor_pointer"
                                  src={delete_icon}
                                  alt=""
                                />
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </>
                )}
              </table>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="card rounded-0 px-5 py-4 position-relative">
            <div className="position-absolute top-0 end-0">
              <img
                onClick={() => setAddCourseModal(false)}
                width={20}
                className="me-3 mt-3 cursor_pointer"
                src={crossblack_icon}
                alt="Close"
              />
            </div>
            <div>
              <form onSubmit={createBlog}>
                <div className="d-flex w-100 flex-column col-3 mt-4">
                  <lable>Title</lable>
                  <input
                    className="custom_admin_input mt-2  px-3 py-2 w-100"
                    type="text"
                    name="title"
                    value={courseData.title}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="row">
                  <div className="d-flex col-md-6 flex-column mt-4">
                    <lable>Duration</lable>
                    <select
                      value={courseData.duration}
                      onChange={handleInputChange}
                      className="custom_admin_input mt-2  px-3 py-2 w-100"
                      name="duration"
                      id=""
                    >
                      <option value="Select Duration">Select Duration</option>
                      <option value="20 Hours">20 Hours</option>
                      <option value="10 Hours">10 Hours</option>
                      <option value="30 Hours">30 Hours</option>
                      <option value="15 Hours">15 Hours</option>
                    </select>
                  </div>
                  <div className="d-flex col-md-6 flex-column  mt-4">
                    <div className="d-flex flex-column w-100  flex-column">
                      <label className="mb-2">Courses</label>
                      <MultiyContentSelect
                        selectedValues={selectedValues}
                        setSelectedValues={setSelectedValues}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex w-100 flex-column col-3 mt-4">
                  <lable className="mb-2">Description</lable>

                  <CKEditor
                    editor={ClassicEditor}
                    value={value}
                    onChange={(e, ed) => {
                      const data = ed.getData();
                      setValue(data);
                    }}
                    onReady={(editor) => {
                      console.log("Editor is ready to use!", editor);
                    }}
                  />
                </div>
                <span>
                  <button
                    className="mt-5 border-0 bg_darkblue px-5 py-2 roundd-2 fs_16 ff_heebo fw-medium text-white"
                    type="submit"
                  >
                    Submit
                  </button>
                </span>
              </form>
            </div>
          </div>
        </>
      )}
      <Modal
        show={freeTrialModalVisible}
        centered
        onHide={toggleFreeTrialModal}
      >
        <div
          style={{ width: "700px", margin: "0 auto" }}
          className="bg-white rounded-3 "
        >
          <div className="position-absolute top-0 end-0">
            <img
              onClick={toggleFreeTrialModal}
              width={20}
              className="me-2 mt-2 cursor_pointer"
              src={crossblack_icon}
              alt="Close"
            />
          </div>

          <Modal.Body>
            <form className="mt-4" onSubmit={updateBlog}>
              <div className="d-flex w-100 flex-column col-3 mt-4">
                <lable>Title</lable>
                <input
                  className="custom_admin_input mt-2  px-3 py-2 w-100"
                  type="text"
                  name="title"
                  value={newcourseData?.title}
                  onChange={handleInputChange2}
                />
              </div>
              <div className="row">
                <div className="d-flex col-md-6 flex-column mt-4">
                  <lable>Duration</lable>
                  <select
                    value={newcourseData.duration}
                    onChange={handleInputChange2}
                    className="custom_admin_input mt-2  px-3 py-2 w-100"
                    name="duration"
                    id=""
                  >
                    <option value="Select Duration">Select Duration</option>
                    <option value="20 Hours">20 Hours</option>
                    <option value="10 Hours">10 Hours</option>
                    <option value="30 Hours">30 Hours</option>
                    <option value="15 Hours">15 Hours</option>
                  </select>
                </div>
                <div className="d-flex col-md-6 flex-column  mt-4">
                  <div className="d-flex flex-column w-100  flex-column">
                    <label className="mb-2">Courses</label>
                    <MultiyContentSelect
                      selectedValues={selectedValuesForUpdate}
                      setSelectedValues={setSelectedValuesForUpdate}
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex w-100 flex-column  mt-4">
                <lable className="mb-2">Description</lable>

                <CKEditor
                  editor={ClassicEditor}
                  data={newValue}
                  onChange={(e, ed) => {
                    const data = ed.getData();
                    setNewValue(data);
                  }}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                />
              </div>
              <span>
                <button
                  className="mt-5 border-0 bg_darkblue px-5 py-2 roundd-2 fs_16 ff_heebo fw-medium text-white"
                  type="submit"
                >
                  Submit
                </button>
              </span>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default ContentTab;
