import React from "react";
import MasterClassHero from "../masterClassPage/MasterClassHero";
import MasterNavbar from "../masterClassPage/MasterNavbar";
import MasterFeatures from "../masterClassPage/MasterFeatures";
import Reviews from "../homePage/Reviews";
import MasterFeaturesProgram from "../masterClassPage/MasterFeaturesProgram";
import MasterFooter from "../masterClassPage/MasterFooter";
import MasterReviews from "../masterClassPage/MasterReviews";
import CenterPlacement from "../centersPage/CenterPlacement";

const MasterClassPage = () => {
  return (
    <div>
      <MasterNavbar />
      <MasterClassHero />
      {/* <MasterFeatures /> */}
      <CenterPlacement />
      <MasterFeaturesProgram />
      {/* <Reviews /> */}

      <MasterReviews />
      <MasterFooter />
      <a
        href="#form"
        className="d-sm-none d-block bg_yellow text-white position-fixed bottom-0 text-center w-100 border-0 p-3"
      >
        Book Demo
      </a>
    </div>
  );
};

export default MasterClassPage;
