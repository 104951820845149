import React from "react";
import icon from "../../assets/images/png/Features_icon.png";
import career_icon from "../../assets/images/png/career_icon.png";

const CareerGrowth = () => {
  return (
    <div className="py-5 career_background">
      <div className="container pt-lg-5">
        <div className="row">
          <div className="col-md-7">
            <button className=" border-0 bg_darkblue px-4 py-1 rounded-1 fs_29 fw-medium ff_heebo text-white">
              Career Growth & Roles
            </button>
            <h4 className=" ff_heebo fs_26 fw-medium text-black mb-0 mt-4 pt-2">
              Career Growth with Full Stack Web Development
              <br className="d-xl-block d-none"></br> Certifications
            </h4>
            <p className=" ff_heebo fs_24 fw-normal color_darkgray mt-4 mb-0">
              Choosing a career can be a tough prospect. We assist individuals
              in assessing their abilities and exploring their career options.
            </p>
            <div className="row">
              <div className="col-xxl-4 col-sm-6 mt-md-5 mt-4">
                <div className="career_box p-3 rounded-3">
                  <div className="d-flex gap-2 align-items-center">
                    <img src={icon} alt="icon" />
                    <p className="mb-0 fs_17 ff_heebo color_blue fw-medium">
                      Growth Potential
                    </p>
                  </div>
                  <p className=" fs_14 ff_heebo color_darkgray mt-3 ">
                    Enroll in our Full Stack Development Course and explore the
                    opportunity in the IT Industry where growth potential is
                    endless with growing demand for software developers, AI etc.
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-sm-6 mt-md-5 mt-4">
                <div className="career_box p-3 rounded-3">
                  <div className="d-flex gap-2 align-items-center">
                    <img src={icon} alt="icon" />
                    <p className="mb-0 fs_17 ff_heebo color_blue fw-medium">
                      Salary Growth
                    </p>
                  </div>
                  <p className=" fs_14 ff_heebo color_darkgray mt-3 ">
                    Our Full Stack Development Course sets you up for higher
                    pay. Get the essential skills needed for full-stack
                    development, so you can earn more as you progress in your
                    career.
                  </p>
                </div>
              </div>
              <div className="col-xxl-4 col-sm-6 mt-md-5 mt-4">
                <div className="career_box p-3 rounded-3">
                  <div className="d-flex gap-2 align-items-center">
                    <img src={icon} alt="icon" />
                    <p className="mb-0 fs_17 ff_heebo color_blue fw-medium">
                      Freelance Opportunities
                    </p>
                  </div>
                  <p className=" fs_14 ff_heebo color_darkgray mt-3 ">
                    Enroll in our Full Stack Development Course and explore
                    freelance work. Master the skills for various web
                    development projects, making you a valuable freelancer for
                    clients in different fields.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5 ps-lg-5 mt-4 mt-md-0">
            <div className="career_cart bg-white p-4 rounded-3">
              <h5 className=" fs_26 ff_heebo fw-medium text-black mb-0">
                Top Industries
              </h5>
              <div className="row">
                <div className="col-6 mt-4">
                  <div className="d-flex gap-2 align-items-center">
                    <img src={career_icon} alt="career_icon" />
                    <p className="mb-0 fs_16 ff_heebo fw-normal color_darkgray">
                      Banking
                    </p>
                  </div>
                </div>
                <div className="col-6 mt-4">
                  <div className="d-flex gap-2 align-items-center">
                    <img src={career_icon} alt="career_icon" />
                    <p className="mb-0 fs_16 ff_heebo fw-normal color_darkgray">
                      Education
                    </p>
                  </div>
                </div>
                <div className="col-6 mt-4">
                  <div className="d-flex gap-2 align-items-center">
                    <img src={career_icon} alt="career_icon" />
                    <p className="mb-0 fs_16 ff_heebo fw-normal color_darkgray">
                      Software
                    </p>
                  </div>
                </div>
                <div className="col-6 mt-4">
                  <div className="d-flex gap-2 align-items-center">
                    <img src={career_icon} alt="career_icon" />
                    <p className="mb-0 fs_16 ff_heebo fw-normal color_darkgray">
                      Travel
                    </p>
                  </div>
                </div>
                <div className="col-6 mt-4">
                  <div className="d-flex gap-2 align-items-center">
                    <img src={career_icon} alt="career_icon" />
                    <p className="mb-0 fs_16 ff_heebo fw-normal color_darkgray">
                      Insurance
                    </p>
                  </div>
                </div>
                <div className="col-6 mt-4">
                  <div className="d-flex gap-2 align-items-center">
                    <img src={career_icon} alt="career_icon" />
                    <p className="mb-0 fs_16 ff_heebo fw-normal color_darkgray">
                      Entertainment
                    </p>
                  </div>
                </div>
              </div>
              <h4 className=" fs_26 ff_heebo text-black mb-0 mt-4">
                Job Titles Include
              </h4>
              <div className="row">
                <div className="col-lg-6">
                  <ul className=" ps-4 mt-3">
                    <li className="custom_list_style_type color_darkgray fs_16 ff_heebo fw-normal pt-3">
                      Full Stack Web Developer
                    </li>
                    <li className="custom_list_style_type color_darkgray fs_16 ff_heebo fw-normal pt-3">
                      Front-End Developer
                    </li>
                    <li className="custom_list_style_type color_darkgray fs_16 ff_heebo fw-normal pt-3">
                      Back-End Developer
                    </li>
                    <li className="custom_list_style_type color_darkgray fs_16 ff_heebo fw-normal pt-3">
                      Web Developer
                    </li>
                    <li className="custom_list_style_type color_darkgray fs_16 ff_heebo fw-normal pt-3">
                      Software Engineer
                    </li>
                    <li className="custom_list_style_type color_darkgray fs_16 ff_heebo fw-normal pt-3">
                      Application Developer
                    </li>
                    <li className="custom_list_style_type color_darkgray fs_16 ff_heebo fw-normal pt-3">
                      JavaScript Developer
                    </li>
                  </ul>
                </div>
                <div className="col-6 d-lg-block d-none position-relative mt-5">
                  <div className="bg_lightblue d-flex align-items-center w-100 position-absolute custombule_career_offer ps-2  fs_43 ff_heebo color_blue fw-bolder pe-3">
                    90<span className="fs_12 ff_heebo pe-3">%</span>
                    <span className=" fw-medium fs_12 ff_heebo color_darkgray">
                      Saves Time and Money
                    </span>
                  </div>
                  <div className="bg_lightpink d-flex align-items-center w-100 position-absolute custompink_career_offer ps-2  fs_43 ff_heebo color_pink fw-bolder pe-3">
                    80<span className="fs_12 ff_heebo pe-3">%</span>
                    <span className=" fw-medium fs_12 ff_heebo color_darkgray">
                      Inspire Creativity
                    </span>
                  </div>
                  <div className="bg_lightyellow d-flex align-items-center w-100 position-absolute customyellow_career_offer ps-2  fs_43 ff_heebo color_yellow fw-bolder pe-3">
                    92<span className="fs_12 ff_heebo pe-3">%</span>
                    <span className=" fw-medium fs_12 ff_heebo color_darkgray">
                      Increased Development
                      <br className="d-xl-block d-none"></br> Efficiency
                    </span>
                  </div>
                  <div className="bg_lightgreen d-flex w-100 align-items-center position-absolute customgreen_career_offer ps-2  fs_43 ff_heebo color_green fw-bolder pe-3">
                    90<span className="fs_12 ff_heebo pe-3">%</span>
                    <span className=" fw-medium fs_12 ff_heebo color_darkgray">
                      Boost Revenue
                    </span>
                  </div>
                </div>
                <div className="col-lg-6 d-lg-none d-block position-relative mt-lg-5">
                  <div className="bg_lightblue d-flex align-items-center w-100  ps-2 clip_path fs_43 ff_heebo color_blue fw-bolder pe-3 mt-3">
                    90<span className="fs_12 ff_heebo pe-3">%</span>
                    <span className=" fw-medium fs_12 ff_heebo color_darkgray">
                      Saves Time and Money
                    </span>
                  </div>
                  <div className="bg_lightpink d-flex align-items-center w-100  ps-2 clip_path fs_43 ff_heebo color_pink fw-bolder pe-3 mt-3">
                    80<span className="fs_12 ff_heebo pe-3">%</span>
                    <span className=" fw-medium fs_12 ff_heebo color_darkgray">
                      Inspire Creativity
                    </span>
                  </div>
                  <div className="bg_lightyellow d-flex align-items-center w-100  ps-2 clip_path fs_43 ff_heebo color_yellow fw-bolder pe-3 mt-3">
                    92<span className="fs_12 ff_heebo pe-3">%</span>
                    <span className=" fw-medium fs_12 ff_heebo color_darkgray">
                      Increased Development
                      <br className="d-xl-block d-none"></br> Efficiency
                    </span>
                  </div>
                  <div className="bg_lightgreen d-flex w-100 align-items-center ps-2 clip_path fs_43 ff_heebo color_green fw-bolder pe-3 mt-3">
                    90<span className="fs_12 ff_heebo pe-3">%</span>
                    <span className=" fw-medium fs_12 ff_heebo color_darkgray">
                      Boost Revenue
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerGrowth;
