import React from "react";
import { featuresData } from "../common/Helper";

const Features = () => {
  return (
    <div className="py-5">
      <div className="container">
        <h3 className=" fs_29 ff_heebo fw-medium text-black text-center">
          Features of this Program
        </h3>
        <div className="row">
          {featuresData.map((obj, i) => {
            return (
              <>
                <div key={i} className="col-lg-3 col-md-4 col-sm-6 mt-4">
                  <div className="features_box h-100 cursor_pointer p-4 rounded-2">
                    <img width={45} src={obj.image} alt="image" />
                    <p className=" fs_22 fw-medium ff_heebo color_darkgray mt-3 mb-0">
                      {obj.paragraph}
                    </p>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Features;
