import React, { useCallback, useContext, useEffect, useState } from "react";
import Navbar from "../common/Navbar";
import bigner_icon from "../../assets/images/png/beginer_icon.png";
import makeApiCall from "../callApi";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import crosswhtie_icon from "../../assets/images/png/crosswhite_icon.png";
import { AppContext } from "../../context/Context";

const QuizHero = () => {
  // const [quiz, setQuiz] = useState([]);
  // const navigate = useNavigate();

  // const quiz_data = useCallback((response) => {
  //   if (response.status === 200) {
  //     setQuiz(response.data.data);
  //   } else {
  //     console.log("error");
  //   }
  // }, []);

  // useEffect(() => {
  //   makeApiCall("", "get", "quiz_list", quiz_data);
  // }, [quiz_data]);

  // const handlePlayNow = (quizId) => {
  //   makeApiCall(
  //     { type: "quiz", id: quizId },
  //     "post",
  //     "questions",
  //     (response) => {
  //       if (response.status === 200) {
  //         navigate(`/test/quiz?id=${quizId}`, {
  //           state: { questions: response.data.data },
  //         });
  //         console.log(response.data.data);
  //       } else {
  //         console.log("Error fetching questions");
  //       }
  //     }
  //   );
  // };

  const [quiz, setQuiz] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { isLoginPopup, setIsLoginPopup, isLogin } = useContext(AppContext);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const navigate = useNavigate();

  const quiz_data = useCallback((response) => {
    if (response.status === 200) {
      setQuiz(response.data.data);
    } else {
      console.log("error");
    }
  }, []);
  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  useEffect(() => {
    makeApiCall("", "get", "quiz_list", quiz_data);
  }, [quiz_data]);

  const handlePlayNow = (quizId, test_attempt_status) => {
    makeApiCall(
      { type: "quiz", id: quizId },
      "post",
      "questions",
      (response) => {
        if (response.status === 200) {
          navigate(`/test/quiz?id=${quizId}`, {
            state: { questions: response.data.data },
          });
          setShowModal(false);
          console.log(response.data.data);
        } else {
          console.log("Error fetching questions");
        }
      }
    );
  };

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // const handleSendOtp = async () => {
  //   try {
  //     const response = await makeApiCall(
  //       { phone: formData.phone },
  //       "post",
  //       "sendOTP",
  //       handleSendOtpCallback,
  //       false
  //     );
  //   } catch (error) {
  //     toast.error("Failed to send OTP. Please try again later.");
  //   }
  // };

  // const handleSendOtpCallback = (response) => {
  //   if (response.status === 200) {
  //     setOtpSent(true);
  //     toast.success("OTP sent successfully");
  //   } else {
  //     toast.error("Failed to send OTP");
  //   }
  // };

  // const handleVerifyOtp = async () => {
  //   try {
  //     const response = await makeApiCall(
  //       { phone: formData.phone, otp: otp, type: "register" },
  //       "post",
  //       "verifyOTP",
  //       handleVerifyOtpCallback,
  //       false
  //     );
  //   } catch (error) {
  //     toast.error("Failed to verify OTP. Please try again later.");
  //   }
  // };

  // const handleVerifyOtpCallback = (response) => {
  //   if (response.data.status === "success") {
  //     toast.success("OTP verified successfully");
  //   } else {
  //     toast.error("Invalid OTP");
  //   }
  // };

  // const createForm = async (e) => {
  //   e.preventDefault();

  //   if (formSubmitted) {
  //     return;
  //   }

  //   const formdata = {
  //     name: formData.name,
  //     email: formData.email,
  //     phone: formData.phone,
  //   };

  //   try {
  //     await makeApiCall(
  //       formdata,
  //       "post",
  //       "new_lead",
  //       createFormCallBack,
  //       false
  //     );
  //   } catch (error) {
  //     console.error("Error creating form:", error);
  //     toast.error("Failed to create lead. Please try again later.");
  //   }
  // };

  // const createFormCallBack = useCallback((res) => {
  //   if (res.data.status === "success") {
  //     localStorage.setItem("phoneNumber", formData.phone);
  //     setShowModal(false);
  //     setFormSubmitted(true);
  //     setFormData({
  //       name: "",
  //       email: "",
  //       phone: "",
  //     });
  //     toast.success("Form submitted successfully");
  //   } else {
  //     toast.error(res.data.message);
  //   }
  // });
  return (
    <div>
      <ToastContainer />
      <div>
        <Navbar />
      </div>
      <div className="py-5">
        <div className="container">
          <div className="d-md-flex justify-content-between align-items-center">
            <h2 className="mb-0 fs_43 fw-bold ff_heebo color_blue">
              Coding <span className="color_yellow">quizzes</span>
            </h2>
            <div className="d-sm-flex mt-4 mt-md-0 align-items-center gap-3">
              <button className="bg_darkblue fw-medium py-2 px-4 border-0 text-white fs_18 ff_heebo rounded-2">
                All Practice Quizzes
              </button>
              <button className="border_blue mt-3 mt-sm-0 fw-medium color_blue py-2 px-4 ff_heebo fs_17 bg-transparent rounded-2">
                Leaderboard
              </button>
            </div>
          </div>
          <div className="row mt-4">
            {quiz?.map((obj, i) => (
              <div key={i} className="col-md-4  col-sm-6 mt-4">
                <div>
                  <img
                    height={250}
                    className="w-100 object-fit-cover rounded-top-4"
                    src={`https://api.koduapp.com/public/images/${obj.image}`}
                    alt="hello"
                  />
                  <div className="p-3 border_orange rounded-bottom-4">
                    <div className="d-flex align-items-center justify-content-between">
                      <button className="bg_yellow px-2 pt-1 fs_14 fw-medium ff_heebo rounded-3 border-0">
                        Q: 10
                      </button>
                      <button className="bg_darkblue px-2 border-0 fs_14 fw-medium ff_heebo rounded-3 pt-1 color_lightwhite">
                        Plays: 56k
                      </button>
                    </div>
                    <h4 className="fs_22 fw-medium ff_heebo color_lightblack mb-0 mt-3">
                      {obj?.title}
                    </h4>
                    <div className="d-flex mb-2 mt-3 align-items-center gap-2">
                      <img width={15} src={bigner_icon} alt="bigner_icon" />
                      <p className="mb-0 fs_12 ff_heebo">Beginner Level</p>
                    </div>
                    {isLogin ? (
                      <button
                        className="mt-4 bg_yellow px-4 py-2 border-0 fs_17 fw-medium ff_heebo color_lightwhite rounded-3"
                        onClick={() => handlePlayNow(obj?._id)}
                      >
                        Play Now
                      </button>
                    ) : (
                      <button
                        onClick={() => setIsLoginPopup(true)}
                        className="Free_btn border-0  mt-4 fs_17 fw-medium ff_heebo color_lightwhite"
                      >
                        Register for Free
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* <Modal show={showModal} onHide={handleCloseModal} centered >
        <div className="rounded-3 pb-4 px-4 bg_darkblue position-relative">
          <div className="position-absolute top-0 end-0">
            <img
              onClick={handleCloseModal}
              width={20}
              className="me-3 mt-3 cursor_pointer"
              src={crosswhtie_icon}
              alt="Close"
            />
          </div>
          <Modal.Header className="border-0 text-white pb-0 pt-5">
            <Modal.Title>
              <h3 className="mb-0 fs_29 ff_heebo fw-bolder text-white">
                Register for Quiz
              </h3>

              <p className="fs_14 mt-2">
                Fill out the form to register for the Quiz exam.
              </p>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={createForm} action="">
              <div className="d-flex flex-column pt-2">
                <label
                  className="fs_17 ff_heebo fw-medium text-white"
                  htmlFor="name"
                >
                  Your Full Name
                </label>
                <input
                  className="input py-2 px-3 rounded-2 mt-2"
                  type="text"
                  placeholder="Enter Full Name"
                  value={formData.name}
                  onChange={handleInputChange}
                  name="name"
                  id="name"
                />
              </div>
              <div className="d-flex flex-column mt-4">
                <label
                  className="fs_17 ff_heebo fw-medium text-white"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  className="input py-2 px-3 rounded-2 mt-2"
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  id="email"
                />
              </div>
              <div className="d-flex flex-column mt-4">
                <label
                  className="fs_17 ff_heebo fw-medium text-white"
                  htmlFor="phone"
                >
                  Contact Number
                </label>
                <div className="d-flex align-items-center gap-3 ">
                  <input
                    className="input w-100 py-2 px-3 rounded-2 mt-2"
                    type="number"
                    placeholder="Enter contact number"
                    value={formData.phone}
                    name="phone"
                    onChange={handleInputChange}
                    id="phone"
                  />
                  <button
                    type="button"
                    className="w-25 py-2 bg_yellow text-white rounded-2 mt-2 border-0 fs_17 fw-medium ff_heebo"
                    onClick={handleSendOtp}
                  >
                    Send OTP
                  </button>
                </div>
              </div>

              {otpSent && (
                <div className="d-flex flex-column mt-4">
                  <label
                    className="fs_17 ff_heebo fw-medium text-white"
                    htmlFor="otp"
                  >
                    Enter OTP
                  </label>
                  <div className="d-flex align-items-center gap-3">
                    <input
                      className="input w-100 py-2 px-3 rounded-2 mt-2"
                      type="number"
                      placeholder="Enter OTP"
                      value={otp}
                      name="otp"
                      onChange={handleOtpChange}
                      id="otp"
                    />

                    <button
                      type="button"
                      className="w-25 py-2 bg_yellow text-white rounded-2 mt-2 border-0 fs_17 fw-medium ff_heebo"
                      onClick={handleVerifyOtp}
                    >
                      Verify OTP
                    </button>
                  </div>
                </div>
              )}

              <button
                type="submit"
                className="Free_btn text-white border-0 py-2 px-3 mt-5 w-100 fs_17 fw-medium ff_heebo"
              >
                Submit
              </button>
            </form>
          </Modal.Body>
        </div>
      </Modal> */}
    </div>
  );
};

export default QuizHero;
