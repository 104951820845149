import React, { useContext, useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import curriculum_icon from "../../assets/images/png/curriculum_icon.png";
import curriculum_icon1 from "../../assets/images/png/curriculum_icon1.png";
import curriculum_icon2 from "../../assets/images/png/curriculum_icon2.png";
import curriculum_icon3 from "../../assets/images/png/curriculum_icon3.png";
import curriculum_icon4 from "../../assets/images/png/curriculum_icon4.png";
import curriculum_icon5 from "../../assets/images/png/curriculum_icon5.png";
// import { AppContext } from "../../context/Context";

const Curriculum = () => {
  const [selectedButton, setSelectedButton] = useState(1);
  const [selectedData, setSelectedData] = useState(null);
  //  const { isLoginPopup, setIsLoginPopup, isLogin } = useContext(AppContext);
  const handleButtonClick = (buttonId, newData) => {
    setSelectedButton(buttonId);
    setSelectedData(newData);
  };
  const handleDownload = () => {
    const pdfUrl = process.env.PUBLIC_URL + "New_Kodu_Brochure_Curriculum.pdf";
    const anchor = document.createElement("a");
    anchor.href = pdfUrl;
    anchor.download = "New_Kodu_Brochure_Curriculum.pdf";
    anchor.click();
  };

  return (
    <div className=" py-5 curriculum_bg">
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <div className="d-flex align-items-center justify-content-between">
              <h3 className="fs_29 ff_heebo color_darkgray">Curriculum</h3>
              <button
                className="Free_btn border-0 ff_heebo fs_17 fw-medium color_lightwhite"
                onClick={handleDownload}
              >
                Download Brochure
              </button>
            </div>
            <div className="row">
              <div className="col-md-4 mt-4">
                <button
                  className={`w-100  border-1 py-2 rounded-2 fs_16 ff_heebo fw-medium ${
                    selectedButton === 1
                      ? "bg_darkblue color_lightwhite"
                      : "bg-transparent color_darkgray"
                  }`}
                  onClick={() => handleButtonClick(1)}
                >
                  Basic
                </button>
              </div>
              <div className="col-md-4 mt-4">
                <button
                  className={`w-100 border-1 py-2 rounded-2 fs_16 ff_heebo fw-medium ${
                    selectedButton === 2
                      ? "bg_darkblue color_lightwhite"
                      : "bg-transparent color_darkgray"
                  }`}
                  onClick={() => handleButtonClick(2)}
                >
                  Advance
                </button>
              </div>
            </div>
            <div className="mt-3">
              {selectedButton === 1 ? (
                <>
                  {" "}
                  <Accordion>
                    <Accordion.Item
                      className="mt-2 border-0 rounded-0"
                      eventKey="11"
                    >
                      <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                        <li className=" custom_list_style_type color_yellow"></li>
                        HTML
                      </Accordion.Header>
                      <Accordion.Body>
                        <Accordion className="px-0" defaultActiveKey="0">
                          <Accordion.Item
                            className="mt-2 px-0 border-0 rounded-0"
                            eventKey="10"
                          >
                            <Accordion.Header className="  fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              Basic HTML Language
                            </Accordion.Header>
                            <Accordion.Body className="px-5">
                              <ul className="pt-4">
                                <li className=" custom_list_style_type mt-2">
                                  Introduction HTML
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  HTML Basics
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  HTML Elements
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  HTML5 Semantic
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  HTML Attributes
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  HTML Headings
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  HTML Paragraph
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  HTML styles
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  HTML Formatting
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  HTML Quotations
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  HTML Computer Code
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  HTML Comments & Colors
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  HTML CSS, Links and Images
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  HTML Lists
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  HTML Blocks
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  HTML Classes
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  HTML Layout
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  HTML Responsive
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  HTML iframes
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  HTML JavaScript
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  HTML Head
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      className="mt-2 border-0 rounded-0"
                      eventKey="111"
                    >
                      <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                        <li className=" custom_list_style_type color_yellow"></li>
                        CSS3
                      </Accordion.Header>
                      <Accordion.Body>
                        <Accordion className="px-0" defaultActiveKey="0">
                          <Accordion.Item
                            className="mt-2 px-0 border-0 rounded-0"
                            eventKey="10"
                          >
                            <Accordion.Header className="  fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              Basic CSS Language
                            </Accordion.Header>
                            <Accordion.Body className="px-5">
                              <ul className="pt-4">
                                <li className=" custom_list_style_type mt-2">
                                  Introduction CSS3
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS3 syntax
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS3 How To
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS3 Colours
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Windows Host system
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS3 Backgrounds
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS3 Borders
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS Padding
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS Height/Width
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS3 Gradients
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS3 Shadows
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS3 Text
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS3 FontsCSS3 2D Transforms
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS3 3D Transforms
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS Links
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS Lists
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS Tables
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS Box Model
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS Outline
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS Display
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS Max-width
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS Position
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS Float
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS Inline-block
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS Align
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS Combinators
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS Pseudo-class
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS Pseudo-element
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS Navigation Bar
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS Dropdowns
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS Tooltips
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS3 Images
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS Attr Selectors
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS Forms
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS Counters
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS3 Animations
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS3 Buttons
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS3 Buttons
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS3 User Interface
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS3 Box Sizing
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS3 Filters
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  CSS3 Media Queries. CSS3 Responsive
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      className="mt-2 border-0 rounded-0"
                      eventKey="0"
                    >
                      <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                        <li className=" custom_list_style_type color_yellow"></li>
                        C
                      </Accordion.Header>
                      <Accordion.Body>
                        <Accordion className="px-0" defaultActiveKey="0">
                          <Accordion.Item
                            className="mt-2 px-0 border-0 rounded-0"
                            eventKey="0"
                          >
                            <Accordion.Header className="  fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              Basic C Language
                            </Accordion.Header>
                            <Accordion.Body className="px-5">
                              <ul className="pt-4">
                                <li className=" custom_list_style_type mt-2">
                                  C comments
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C format specifiers
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C Escape Sequences
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  ASCII values in C
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Constant in C
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Literals in C
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Tokens in C
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C Boolean
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Static in C
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Programming Errors in c
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Compile Time vs RunTime
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Conditional Operators in C
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            className="mt-2 border-0 rounded-0"
                            eventKey="1"
                          >
                            <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              C control Statements
                            </Accordion.Header>
                            <Accordion.Body className="px-5">
                              <ul className="pt-4">
                                <li className=" custom_list_style_type mt-2">
                                  C if - else
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C switch
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  If else vs switch
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C loops
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C do while loop
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C while loop
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C for loop
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Nested loop in C
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Infinite loop In c
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C break
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C continue
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C goto
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Type casting
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            className="mt-2 border-0 rounded-0"
                            eventKey="2"
                          >
                            <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              C Functions
                            </Accordion.Header>
                            <Accordion.Body className="px-5 pb-4">
                              <ul className="pt-4">
                                <li className=" custom_list_style_type mt-2">
                                  What is function
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Call : Value and Reference
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Recursion in c
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Storage Classes
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            className="mt-2 border-0 rounded-0"
                            eventKey="3"
                          >
                            <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              C Array
                            </Accordion.Header>
                            <Accordion.Body className="px-5 pb-4">
                              <ul className="pt-4">
                                <li className=" custom_list_style_type mt-2">
                                  1 – D Array
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  2 – D Array
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Return an Array in c
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Array to Function
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            className="mt-2 border-0 rounded-0"
                            eventKey="4"
                          >
                            <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              C Pointers
                            </Accordion.Header>
                            <Accordion.Body className="px-5 pb-4">
                              <ul className="pt-4">
                                <li className=" custom_list_style_type mt-2">
                                  C pointer
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C pointer to pointer
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C pointer Arithmatic
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C Function pointer
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C array pointer
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C Structure pointer
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Function pointer as argument
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Size of pointer
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Size of operator in C
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            className="mt-2 border-0 rounded-0"
                            eventKey="5"
                          >
                            <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              C Strings{" "}
                            </Accordion.Header>
                            <Accordion.Body className="px-5 pb-4">
                              <ul className="pt-4">
                                <li className=" custom_list_style_type mt-2">
                                  String in C
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C get() & puts()
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C String functions
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C Strlen()
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C Strcpy()
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C strcat()
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C Strcmp()
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C Strrev()
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C Strlwr()
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C Strupr()
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C String test
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            className="mt-2 border-0 rounded-0"
                            eventKey="6"
                          >
                            <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              C Math
                            </Accordion.Header>
                            <Accordion.Body className="px-5 pb-4">
                              <ul className="pt-4">
                                <li className=" custom_list_style_type mt-2">
                                  C Math Functions
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            className="mt-2 border-0 rounded-0"
                            eventKey="7"
                          >
                            <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              C Structure Union
                            </Accordion.Header>
                            <Accordion.Body className="px-5 pb-4">
                              <ul className="pt-4">
                                <li className=" custom_list_style_type mt-2">
                                  C Structure
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Typedef in C
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C Array of Structure
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C Nested Structure
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C Array Within Structure
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Passing Structure to a Function as parameter
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C Union
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C Structure test
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            className="mt-2 border-0 rounded-0"
                            eventKey="8"
                          >
                            <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              C PointeC File Handlings
                            </Accordion.Header>
                            <Accordion.Body className="px-5 pb-4">
                              <ul className="pt-4">
                                <li className=" custom_list_style_type mt-2">
                                  C File Handling
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C fprintf(), fscanf()
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C fputc(), fgetc()
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C fseek()
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C rewind()
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C ftell()
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      className="mt-2 border-0 rounded-0"
                      eventKey="1"
                    >
                      <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                        <li className=" custom_list_style_type color_yellow"></li>
                        C++
                      </Accordion.Header>
                      <Accordion.Body>
                        <Accordion className="px-0" defaultActiveKey="0">
                          <Accordion.Item
                            className="mt-2 px-0 border-0 rounded-0"
                            eventKey="0"
                          >
                            <Accordion.Header className="  fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              C++ Basics
                            </Accordion.Header>
                            <Accordion.Body className="px-5">
                              <ul className="pt-4">
                                <li className=" custom_list_style_type mt-2">
                                  C vs c++
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ History
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ Features
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ installation
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ Program
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ cout,cin,endl
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ variables
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ Data Types
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ Keywords
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ operators{" "}
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ identifiers
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ Expression
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            className="mt-2 border-0 rounded-0"
                            eventKey="1"
                          >
                            <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              C++ control Statements
                            </Accordion.Header>
                            <Accordion.Body className="px-5">
                              <ul className="pt-4">
                                <li className=" custom_list_style_type mt-2">
                                  C++ if-else{" "}
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ switch
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ For loop
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ while loop
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ do-while loop
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ break statement
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ continue Statement
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ Goto Statement
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ comments
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            className="mt-2 border-0 rounded-0"
                            eventKey="2"
                          >
                            <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              C++ Functions
                            </Accordion.Header>
                            <Accordion.Body className="px-5">
                              <ul className="pt-4">
                                <li className=" custom_list_style_type mt-2">
                                  C++ Functions
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ return type and parameters
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ call by value and call by reference
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ call by value and call by reference
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ storage classes
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            className="mt-2 border-0 rounded-0"
                            eventKey="3"
                          >
                            <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              C++ Array
                            </Accordion.Header>
                            <Accordion.Body className="px-5">
                              <ul className="pt-4">
                                <li className=" custom_list_style_type mt-2">
                                  C++ Arrays
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ Array to function
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  One and multidimensional Array
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            className="mt-2 border-0 rounded-0"
                            eventKey="4"
                          >
                            <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              C++ Pointers
                            </Accordion.Header>
                            <Accordion.Body className="px-5 pb-4">
                              <ul className="pt-4">
                                <li className=" custom_list_style_type mt-2">
                                  C++ pointers{" "}
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  sizeof() operator in c++
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ Array of Pointers
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ References
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  References vs pointers
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Function pointer in c++
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ memory management
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            className="mt-2 border-0 rounded-0"
                            eventKey="4"
                          >
                            <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              C++ Object Class
                            </Accordion.Header>
                            <Accordion.Body className="px-5 pb-4">
                              <ul className="pt-4">
                                <li className=" custom_list_style_type mt-2">
                                  C++ OOPS concept
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ object class
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ Constructor
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ Types of Constructor
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ Destructor
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ this pointer
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ Static
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ structs
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ Enumeration
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ friend function
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ Math Function
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            className="mt-2 border-0 rounded-0"
                            eventKey="4"
                          >
                            <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              C++ inheritance
                            </Accordion.Header>
                            <Accordion.Body className="px-5 pb-4">
                              <ul className="pt-4">
                                <li className=" custom_list_style_type mt-2">
                                  C++ inheritance
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ Aggregation
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            className="mt-2 border-0 rounded-0"
                            eventKey="4"
                          >
                            <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              C++ polymorphism
                            </Accordion.Header>
                            <Accordion.Body className="px-5 pb-4">
                              <ul className="pt-4">
                                <li className=" custom_list_style_type mt-2">
                                  C++ Polymorphism
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ overloading
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ overriding
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ virtual function
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            className="mt-2 border-0 rounded-0"
                            eventKey="4"
                          >
                            <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              C++ Abstraction
                            </Accordion.Header>
                            <Accordion.Body className="px-5 pb-4">
                              <ul className="pt-4">
                                <li className=" custom_list_style_type mt-2">
                                  C++ Interfaces
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ Data Abstraction
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            className="mt-2 border-0 rounded-0"
                            eventKey="4"
                          >
                            <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              C++ Namespaces
                            </Accordion.Header>
                            <Accordion.Body className="px-5 pb-4">
                              <ul className="pt-4">
                                <li className=" custom_list_style_type mt-2">
                                  C++ namespaces
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            className="mt-2 border-0 rounded-0"
                            eventKey="4"
                          >
                            <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              C++ Strings
                            </Accordion.Header>
                            <Accordion.Body className="px-5 pb-4">
                              <ul className="pt-4">
                                <li className=" custom_list_style_type mt-2">
                                  C++ Strings
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            className="mt-2 border-0 rounded-0"
                            eventKey="4"
                          >
                            <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              C++ Exceptions & file & Stream
                            </Accordion.Header>
                            <Accordion.Body className="px-5 pb-4">
                              <ul className="pt-4">
                                <li className=" custom_list_style_type mt-2">
                                  C++ Exception Handling
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ try/catch
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ user defined
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ file and stream
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  C++ getline()
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      className="mt-2 border-0 rounded-0"
                      eventKey="2"
                    >
                      <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                        <li className=" custom_list_style_type color_yellow"></li>
                        BOOTSTRAP
                      </Accordion.Header>
                      <Accordion.Body>
                        <Accordion className="px-0" defaultActiveKey="0">
                          <Accordion.Item
                            className="mt-2 px-0 border-0 rounded-0"
                            eventKey="0"
                          >
                            <Accordion.Header className="  fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              Basic Bootstrap Language
                            </Accordion.Header>
                            <Accordion.Body className="px-5">
                              <ul className="pt-2 pb-4">
                                <li className=" custom_list_style_type mt-2">
                                  Introduction to Bootstrap
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Bootstrap Basics
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Bootstrap Grids
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Bootstrap Themes
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Bootstrap CSS
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Bootstrap JS
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      className="mt-2 border-0 rounded-0"
                      eventKey="3"
                    >
                      <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                        <li className=" custom_list_style_type color_yellow"></li>
                        JAVASCRIPT
                      </Accordion.Header>
                      <Accordion.Body>
                        <Accordion className="px-0" defaultActiveKey="0">
                          <Accordion.Item
                            className="mt-2 px-0 border-0 rounded-0"
                            eventKey="0"
                          >
                            <Accordion.Header className="  fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              Basic Javascript Language
                            </Accordion.Header>
                            <Accordion.Body className="px-5">
                              <ul className="pt-2 pb-4">
                                <li className=" custom_list_style_type mt-2">
                                  Introduction to JavaScript
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  JavaScript Language Basics
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  JavaScript Objects
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  JavaScript Scope
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  JavaScript Numbers
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  JavaScript Math
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  JavaScript Arrays
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  JavaScript Boolean
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  JavaScript Comparisons
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  JavaScript Conditions
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  JavaScript Switch
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  JavaScript Loops
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  JavaScript Type Conversion
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  JavaScript RegExp
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  JavaScript Errors
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  JavaScript Debugging
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  JavaScript Hoisting
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  JavaScript Strict Mode
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  JavaScript Functions
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  JavaScript Objects
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  JavaScript Forms
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  JavaScript HTML DOM
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  JavaScript BOM
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      className="mt-2 border-0 rounded-0"
                      eventKey="13"
                    >
                      <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                        <li className=" custom_list_style_type color_yellow"></li>
                        Core Java
                      </Accordion.Header>
                      <Accordion.Body>
                        <Accordion className="px-0" defaultActiveKey="0">
                          <Accordion.Item
                            className="mt-2 px-0 border-0 rounded-0"
                            eventKey="12"
                          >
                            <Accordion.Header className="  fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              Basic Core Java Language
                            </Accordion.Header>
                            <Accordion.Body className="px-5">
                              <ul className="pt-2 pb-4">
                                <li className="custom_list_style_type mt-2">
                                  What is Java
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  History Of Java
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Features Of Java
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  C++ VS Java
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Hello Java Program
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Program Internal
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  How to Set path?
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  JDK, JRE and JVM
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  JVM: Java Virtual Machine
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Java Variables
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Java Data Types
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Unicode System
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Operators
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Keywords
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            className="mt-2 px-0 border-0 rounded-0"
                            eventKey="16"
                          >
                            <Accordion.Header className="  fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              Control Statements
                            </Accordion.Header>
                            <Accordion.Body className="px-5">
                              <ul className="pt-2 pb-4">
                                <li className="custom_list_style_type mt-2">
                                  Java control statements
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Java If – else
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Java Switch
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Java For loop
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Java While loop
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Java Do While loop
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Java Breaks
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Java Continue
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Java Comments
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Java Programs
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            className="mt-2 px-0 border-0 rounded-0"
                            eventKey="17"
                          >
                            <Accordion.Header className="  fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              Java Object Classes
                            </Accordion.Header>
                            <Accordion.Body className="px-5">
                              <ul className="pt-2 pb-4">
                                <li className="custom_list_style_type mt-2">
                                  Java OOPs concepts
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Naming Convention
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Object and Class
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Method
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Constructor
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Static keyword
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  This keyword
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            className="mt-2 px-0 border-0 rounded-0"
                            eventKey="18"
                          >
                            <Accordion.Header className="  fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              Java Inheritance
                            </Accordion.Header>
                            <Accordion.Body className="px-5">
                              <ul className="pt-2 pb-4">
                                <li className="custom_list_style_type mt-2">
                                  Inheritance (IS – A)
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Aggregation (HAS – A)
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            className="mt-2 px-0 border-0 rounded-0"
                            eventKey="19"
                          >
                            <Accordion.Header className="  fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              Java Polymorphism
                            </Accordion.Header>
                            <Accordion.Body className="px-5">
                              <ul className="pt-2 pb-4">
                                <li className="custom_list_style_type mt-2">
                                  Method Overloading
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Method Overriding
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Covariant Return type
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Super Keyword
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Instance Initializer Block
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Final Keyword
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Run time polymorphism
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Dynamic Binding
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Dynamic Binding
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            className="mt-2 px-0 border-0 rounded-0"
                            eventKey="20"
                          >
                            <Accordion.Header className="  fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              Java Abstraction
                            </Accordion.Header>
                            <Accordion.Body className="px-5">
                              <ul className="pt-2 pb-4">
                                <li className="custom_list_style_type mt-2">
                                  Abstract Class
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Interface
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Abstract Vs Interface
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            className="mt-2 px-0 border-0 rounded-0"
                            eventKey="21"
                          >
                            <Accordion.Header className="  fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              Java Encapsulation
                            </Accordion.Header>
                            <Accordion.Body className="px-5">
                              <ul className="pt-2 pb-4">
                                <li className="custom_list_style_type mt-2">
                                  Package
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Access Modifier
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Encapsulation
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            className="mt-2 px-0 border-0 rounded-0"
                            eventKey="22"
                          >
                            <Accordion.Header className="  fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              Java Array
                            </Accordion.Header>
                            <Accordion.Body className="px-5">
                              <ul className="pt-2 pb-4">
                                <li className="custom_list_style_type mt-2">
                                  Java Array
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Java String
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Java Regex
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Exception Handling
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Java Inner Classes
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Java Multithreading
                                </li>
                                <li className="custom_list_style_type mt-2">
                                  Java I/O
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      className="mt-2 border-0 rounded-0"
                      eventKey="23"
                    >
                      <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                        <li className=" custom_list_style_type color_yellow"></li>
                        JQUERY
                      </Accordion.Header>
                      <Accordion.Body>
                        <Accordion className="px-0" defaultActiveKey="0">
                          <Accordion.Item
                            className="mt-2 px-0 border-0 rounded-0"
                            eventKey="24"
                          >
                            <Accordion.Header className="  fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              Basic JQUERY Language
                            </Accordion.Header>
                            <Accordion.Body className="px-5">
                              <ul className="pt-2 pb-4"></ul>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </>
              ) : (
                <></>
              )}
              {selectedButton === 2 ? (
                <>
                  {" "}
                  <Accordion>
                    <Accordion.Item
                      className="mt-2 border-0 rounded-0"
                      eventKey="0"
                    >
                      <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                        <li className=" custom_list_style_type color_yellow"></li>
                        REACT JS
                      </Accordion.Header>
                      <Accordion.Body>
                        <Accordion className="px-0" defaultActiveKey="0">
                          <Accordion.Item
                            className="mt-2 px-0 border-0 rounded-0"
                            eventKey="0"
                          >
                            <Accordion.Header className="  fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              Basic REACT JS Language
                            </Accordion.Header>
                            <Accordion.Body className="px-5">
                              <ul className="pt-2 pb-4">
                                <li className=" custom_list_style_type mt-2">
                                  Introduction to ReactJS
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Understand ReactJS Library & directory
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  React Components
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Types of Components
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Build a simple React component
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Component composition Component styling
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Add styles to your components
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  How to pass data from one component to another
                                  Routing
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Using Routing to create single page app
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Hooks{" "}
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  States{" "}
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Hooks vs States
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Types of Hooks
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Redux{" "}
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Using Redux as state container for react apps
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  React Bootstrap
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  How to deploy ReactJS App
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      className="mt-2 border-0 rounded-0"
                      eventKey="1"
                    >
                      <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                        <li className=" custom_list_style_type color_yellow"></li>
                        NODE JS
                      </Accordion.Header>
                      <Accordion.Body>
                        <Accordion className="px-0" defaultActiveKey="0">
                          <Accordion.Item
                            className="mt-2 px-0 border-0 rounded-0"
                            eventKey="0"
                          >
                            <Accordion.Header className="  fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              Basic NODE JS Language
                            </Accordion.Header>
                            <Accordion.Body className="px-5">
                              <ul className="pt-2 pb-4">
                                <li className=" custom_list_style_type mt-2">
                                  Introduction to Node.js
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Architecture of Node.js Application
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Synchronous and Asynchronous Programming
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Call back Function in Node.js
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Promises in Node.js
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Mongodb with Node.js
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Design the Schema in Node.j
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Design the Rest API's
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  GET, POST, PUT, DELETE
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  JSON web Token Authentication in Node.js{" "}
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Create the Auth APP in node.js
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Integrated Payment Gateway
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      className="mt-2 border-0 rounded-0"
                      eventKey="2"
                    >
                      <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                        <li className=" custom_list_style_type color_yellow"></li>
                        EXPRESS JS
                      </Accordion.Header>
                      <Accordion.Body>
                        <Accordion className="px-0" defaultActiveKey="0">
                          <Accordion.Item
                            className="mt-2 px-0 border-0 rounded-0"
                            eventKey="0"
                          >
                            <Accordion.Header className="  fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              Basic EXPRESS JS Language
                            </Accordion.Header>
                            <Accordion.Body className="px-5">
                              <ul className="pt-2 pb-4">
                                <li className=" custom_list_style_type mt-2">
                                  Express
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Restful services
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Introducing Express
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Building your First Web Server
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Nodemon
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Environment Variables
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Route Parameters
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Handling HTTP GET Request
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Handling HTTP POST Request
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Calling Endpoints Using Postman
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Input Validations
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Handling HTTP PUT Request
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Handling HTTP DELETE Request
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Project- Build the Genres API
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      className="mt-2 border-0 rounded-0"
                      eventKey="3"
                    >
                      <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                        <li className=" custom_list_style_type color_yellow"></li>
                        MONGODB
                      </Accordion.Header>
                      <Accordion.Body>
                        <Accordion className="px-0" defaultActiveKey="0">
                          <Accordion.Item
                            className="mt-2 px-0 border-0 rounded-0"
                            eventKey="0"
                          >
                            <Accordion.Header className="  fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              Basic MONGODB Language
                            </Accordion.Header>
                            <Accordion.Body className="px-5">
                              <ul className="pt-2 pb-4">
                                <li className=" custom_list_style_type mt-2">
                                  Introduction to MongoDB (No-sql)
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Collections in MongoDB
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Documents In MongoDB
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Difference b/w Mysql and NoSql
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Inserting data into database
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Filter queries in MongoDB Database
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Schema Validation in MongoDb database Indexing
                                  In collections
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  Aggregation in MongoDB
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item
                      className="mt-2 border-0 rounded-0"
                      eventKey="4"
                    >
                      <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                        <li className=" custom_list_style_type color_yellow"></li>
                        React Native
                      </Accordion.Header>
                      <Accordion.Body className=" pb-4">
                        <Accordion>
                          <Accordion.Item
                            className="mt-2 border-0 rounded-0"
                            eventKey="5"
                          >
                            <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              Basic React Native Language
                            </Accordion.Header>
                            <Accordion.Body className="px-5">
                              <ul className="pt-2 pb-4">
                                <li className=" custom_list_style_type mt-2">
                                  React Native - Overview
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  React Native - Environment Setup
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  React Native - State
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  React Native - Props
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  React Native - Styling
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  React Native - Flexbox
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  React Native - ListView
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  React Native - Text Input
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  React Native - ScrollView
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  React Native - Images
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  React Native - Buttons
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  React Native - Animations
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  React Native - Debugging
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  React Native - Running IOS
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  React Native - Running Android
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            className="mt-2 border-0 rounded-0"
                            eventKey="5"
                          >
                            <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
                              <li className=" custom_list_style_type color_yellow"></li>
                              Components and APIs
                            </Accordion.Header>
                            <Accordion.Body className="px-5">
                              <ul className="pt-2 pb-4">
                                <li className=" custom_list_style_type mt-2">
                                  React Native - View
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  React Native - WebView
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  React Native - Modal
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  React Native - ActivityIndicator
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  React Native - Picker
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  React Native - Status Bar
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  React Native - Switch
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  React Native - Text
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  React Native - Alert
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  React Native - AsyncStorage
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  React Native - Geolocation
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  React Native - Redux
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  React Native - Navigation
                                </li>
                                <li className=" custom_list_style_type mt-2">
                                  React Native - Network Requests
                                </li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-md-5 mt-2 ps-lg-5 ps-3 mt-4 mt-md-0">
            <h4 className=" fs_29 ff_heebo fw-medium text-black">
              Program Highlights
            </h4>
            <div className="bg_darkblue curriculum_box_shadow rounded-2 p-4 mt-md-5 mt-3">
              <div className="d-flex align-items-center gap-4">
                <img width={45} src={curriculum_icon} alt="curriculum_icon" />
                <p className=" fs_17 ff_heebo text-white mb-0">Live classes</p>
              </div>
              <div className="custom_border my-3"></div>
              <div className="d-flex align-items-center gap-4">
                <img width={45} src={curriculum_icon1} alt="curriculum_icon" />
                <p className=" fs_17 ff_heebo text-white mb-0">
                  Interview preparation and mentorship
                </p>
              </div>
              <div className="custom_border my-3"></div>
              <div className="d-flex align-items-center gap-4">
                <img width={45} src={curriculum_icon2} alt="curriculum_icon" />
                <p className=" fs_17 ff_heebo text-white mb-0">
                  Placement assistance
                </p>
              </div>
              <div className="custom_border my-3"></div>
              <div className="d-flex align-items-center gap-4">
                <img width={45} src={curriculum_icon3} alt="curriculum_icon" />
                <div>
                  <p className=" fs_15 mt-2 ff_heebo text-white mb-0">
                    24/7 Practice lab facilities
                  </p>
                </div>
              </div>
              <div className="custom_border my-3"></div>
              <div className="d-flex align-items-center gap-4">
                <img width={45} src={curriculum_icon4} alt="curriculum_icon" />
                <div>
                  <p className=" fs_15 mt-2 ff_heebo text-white mb-0">
                    Personal guidance by real-time industry experts
                  </p>
                </div>
              </div>
              <div className="custom_border my-3"></div>
              <div className="d-flex align-items-center gap-4">
                <img width={45} src={curriculum_icon5} alt="curriculum_icon" />
                <div>
                  <p className=" fs_15 mt-2 ff_heebo text-white mb-0">
                    Well equipped classrooms rooms
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Curriculum;
