import axios from "axios";
const URL = process.env.REACT_APP_API_URL;
const USER_TOKEN = process.env.REACT_APP_TOKEN;

const makeApiCall = async (data, type, url, callback, images) => {
  try {
    const apiUrl = URL + url;
    const AuthStr = localStorage.getItem("admtoken");

    if (type === "get") {
      const response = await axios.get(apiUrl, {
        headers: { token: AuthStr },
      });
      callback(response);
    } else {
      if (images) {
        const response = await axios.post(apiUrl, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: AuthStr,
          },
        });
        let responseData = response;
        responseData.payload = data;
        callback(responseData);
      } else {
        const response = await axios.post(apiUrl, data, {
          headers: { token: AuthStr },
        });
        let responseData = response;
        responseData.payload = data;
        callback(responseData);
      }
    }
  } catch (error) {
    console.log(error);
  }
};

export default makeApiCall;
