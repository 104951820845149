import React from "react";
import { Accordion } from "react-bootstrap";

function CourseFaq() {
  return (
    <div className="container py-4 my-5">
      <h1 className="ff_heebo text-center fs_29 my-5">FAQs</h1>
      <div>
        <Accordion>
          <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="0">
            <Accordion.Header className=" fs_17 ff_heebo color_darkgray courseFagbg">
              <li className=" custom_list_style_type color_blue"></li>
              What is a Live Interactive Coding Course?
            </Accordion.Header>
            <Accordion.Body className="px-5 py-3">
              This course offers live coding lessons where students can interact
              directly with instructors. With this medium, students can directly
              interact with the trainer’s.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="1">
            <Accordion.Header className=" fs_17 ff_heebo color_darkgray courseFagbg">
              <li className=" custom_list_style_type color_blue"></li>
              Who is this course designed for?
            </Accordion.Header>
            <Accordion.Body className="px-5 py-3">
              Our course is suitable for anyone interested in learning to code,
              from complete beginners to those with some experience looking to
              deepen their understanding of specific programming languages or
              concepts like Data Structures and Algorithms (DSA) and System
              Design.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="2">
            <Accordion.Header className=" fs_17 ff_heebo color_darkgray courseFagbg">
              <li className=" custom_list_style_type color_blue"></li>
              What topics will the course cover?
            </Accordion.Header>
            <Accordion.Body className="px-5 py-3">
              The course covers fundamental to advanced coding concepts,
              including HTML, CSS & Javascript
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="3">
            <Accordion.Header className=" fs_17 ff_heebo color_darkgray courseFagbg">
              <li className=" custom_list_style_type color_blue"></li>
              How many sessions are included, and what is their duration?
            </Accordion.Header>
            <Accordion.Body className="px-5 py-3">
              The course comprises around 25-35 live sessions, each lasting
              approximately 3 hours.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="4">
            <Accordion.Header className=" fs_17 ff_heebo color_darkgray courseFagbg">
              <li className=" custom_list_style_type color_blue"></li>
              Are the sessions interactive? Can I ask questions during the
              class?
            </Accordion.Header>
            <Accordion.Body className="px-5 py-3">
              Yes, all sessions are live and interactive. You are encouraged to
              ask questions and clarify doubts directly with the mentor during
              the class.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="5">
            <Accordion.Header className=" fs_17 ff_heebo color_darkgray courseFagbg">
              <li className=" custom_list_style_type color_blue"></li>. What if
              I miss a live session?
            </Accordion.Header>
            <Accordion.Body className="px-5 py-3">
              Recordings of each session will be available for those who miss a
              class or wish to review the material. However, attending live is
              highly recommended to make the most of the interactive features.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="6">
            <Accordion.Header className=" fs_17 ff_heebo color_darkgray courseFagbg">
              <li className=" custom_list_style_type color_blue"></li>
              Is there any additional support available outside of class hours?
            </Accordion.Header>
            <Accordion.Body className="px-5 py-3">
              Yes, students will have access to a course forum for discussion,
              doubt clarification, and additional support from instructors and
              peers.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="6">
            <Accordion.Header className=" fs_17 ff_heebo color_darkgray courseFagbg">
              <li className=" custom_list_style_type color_blue"></li>. Will I
              work on projects?
            </Accordion.Header>
            <Accordion.Body className="px-5 py-3">
              Yes, hands-on projects are a core part of the curriculum, designed
              to apply what you've learned in real-world scenarios, enhancing
              your portfolio and practical understanding.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="6">
            <Accordion.Header className=" fs_17 ff_heebo color_darkgray courseFagbg">
              <li className=" custom_list_style_type color_blue"></li>. Will I
              What is the course fee, and are there any payment plans available?
            </Accordion.Header>
            <Accordion.Body className="px-5 py-3">
              The course fee includes all lectures, project materials, and
              access to the community forum. We offer a variety of payment
              options, including installment plans. Please contact our
              admissions office for more detailed information.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="6">
            <Accordion.Header className=" fs_17 ff_heebo color_darkgray courseFagbg">
              <li className=" custom_list_style_type color_blue"></li>. Will I
              Will I receive a certificate upon completion?
            </Accordion.Header>
            <Accordion.Body className="px-5 py-3">
              Yes, participants who successfully complete the course and all
              associated projects will receive a certificate of completion.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="6">
            <Accordion.Header className=" fs_17 ff_heebo color_darkgray courseFagbg">
              <li className=" custom_list_style_type color_blue"></li>. Will I
              How do I enroll in the course?
            </Accordion.Header>
            <Accordion.Body className="px-5 py-3">
              You can enroll through our website by filling out the registration
              form and completing the payment process. If you need assistance or
              have further questions, please contact our support team.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
}

export default CourseFaq;
