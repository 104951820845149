import React from "react";
import Navbar from "../common/Navbar";
import clock_icon from "../../assets/images/png/clock_icon.png";

const HireFromUsHero = () => {
  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div className="bg_darkblue pb-5 pt-md-5 pt-3">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6">
              <h4 className=" fs_43 fw-bold ff_heebo text-white">
                <span className="color_yellow">Empowering</span> the Next Tech
                Leaders
              </h4>
              <p className=" fs_17 fw-medium text-white">
                Our institute doesn’t just teach coding; we craft the next
                generation of tech visionaries. By hiring our graduates, you're
                not just filling a position. You're investing in the future of
                your company. Rs - 1/- / Hiring Fee’s
              </p>
            </div>
            <div className="col-md-5 mt-4 mt-md-0">
              <div className="card p-sm-5 p-3 text-center">
                <div className="d-flex justify-content-center gap-2 align-items-center">
                  <img width={20} src={clock_icon} alt="hello" />
                  <p className="mb-0 ff_heebo color_blue">Takes 45 sec</p>
                </div>
                <h4 className="mt-2 fs_37 fw-bold ff_heebo">
                  Click Start and get<br className="d-xl-block d-none"></br> the
                  best talent!
                </h4>
                <a
                  href="https://api.whatsapp.com/send/?phone=919728097234&text&type=phone_number&app_absent=0"
                  target="_blanck"
                >
                  <button className="w-100 py-2 bg_yellow border-0 mt-3 rounded-1 text-white fw-semibold fs_17 ff_heebo">
                    Start Here
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HireFromUsHero;
