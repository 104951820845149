import React, { useCallback, useEffect, useState } from "react";
import Navbar from "../common/Navbar";
import blog_img1 from "../../assets/images/png/blog_img1.png";
import pagenation_arrow from "../../assets/images/png/pagenation_right_arrow.png";
import poster3 from "../../assets/images/png/poster3.png";
import { Link } from "react-router-dom";
import makeApiCall from "../callApi";

const BlogHero = () => {
  const [blog, SetBlog] = useState([]);

  useEffect(() => {
    makeApiCall("", "get", "blogs", home_data);
  }, []);

  const home_data = useCallback((response) => {
    console.log(response);
    // setMainLoading(false);
    if (response.status === 200) {
      SetBlog(response.data.data);
      console.log(response.data.data);
    } else {
      console.log("error");
    }
  });
  const convertDate = (date) => {
    const res = new Date(date).toDateString();
    const res2 = new Date(date).toLocaleTimeString();
    return { date: res, time: res2 };
  };
  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div className="py-5">
        <div className="container">
          <h4 className=" fs_43 fw-bold ff_heebo color_yellow">
            <span className=" color_blue">Code Chronicles:</span> Unveiling the
            World of <br className=" d-xl-block d-none"></br>Programming
          </h4>
          <div className="row">
            {blog.length === 0 ? (
              <p className=" fs_15 ff_heebo fw-medium pt-5 text-center">
                No data found.
              </p>
            ) : (
              <div className="row">
                {blog.map((obj, i) => {
                  const converteddate = convertDate(obj.createdAt);
                  return (
                    <div className="col-lg-4 col-sm-6" key={i}>
                      <div>
                        <Link to={`/blog/${obj?._id}`}>
                          <div className="features_box1 h-100 p-4 rounded-3 mt-4">
                            <div>
                              <img
                                height={200}
                                className="w-100 object-fit-cover rounded-2"
                                src={`https://api.koduapp.com/public/images/${obj?.image}`}
                                alt="hello"
                              />
                            </div>
                            <p className="fs_24 mt-2 fw-bold ff_heebo color_lightblack mb-0 pt-3">
                              {obj?.title}
                            </p>
                            <p className="fs_14 ff_heebo color_yellow mb-0 pt-1">
                              {converteddate?.date} {converteddate?.time}
                            </p>
                          </div>
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {/* <div className="row">
            <div className="col-lg-4 col-sm-6">
              <Link to="/language-model">
                <div className="features_box1 p-4 rounded-3 mt-4">
                  <img className="w-100" src={blog_img1} alt="blog_img1" />
                  <p className=" fs_14 fw-bold color_blue ff_heebo mb-0 pt-3">
                    Coding Classes
                  </p>
                  <p className=" fs_16 fw-bold ff_heebo color_lightblack mb-0 pt-3">
                    Mastering Code: Unlocking the Secrets of Programming
                    Excellence
                  </p>
                  <p className=" fs_14 ff_heebo color_yellow mb-0 pt-3">
                    MARCH 13, 2024
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-sm-6">
              <Link to="/language-model">
                <div className="features_box1 p-4 rounded-3 mt-4">
                  <img className="w-100" src={blog_img1} alt="blog_img1" />
                  <p className=" fs_14 fw-bold color_blue ff_heebo mb-0 pt-3">
                    Coding Classes
                  </p>
                  <p className=" fs_16 fw-bold ff_heebo color_lightblack mb-0 pt-3">
                    Mastering Code: Unlocking the Secrets of Programming
                    Excellence
                  </p>
                  <p className=" fs_14 ff_heebo color_yellow mb-0 pt-3">
                    MARCH 13, 2024
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-sm-6">
              <Link to="/language-model">
                <div className="features_box1 p-4 rounded-3 mt-4">
                  <img className="w-100" src={blog_img1} alt="blog_img1" />
                  <p className=" fs_14 fw-bold color_blue ff_heebo mb-0 pt-3">
                    Coding Classes
                  </p>
                  <p className=" fs_16 fw-bold ff_heebo color_lightblack mb-0 pt-3">
                    Mastering Code: Unlocking the Secrets of Programming
                    Excellence
                  </p>
                  <p className=" fs_14 ff_heebo color_yellow mb-0 pt-3">
                    MARCH 13, 2024
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-sm-6">
              <Link to="/language-model">
                <div className="features_box1 p-4 rounded-3 mt-4">
                  <img className="w-100" src={blog_img1} alt="blog_img1" />
                  <p className=" fs_14 fw-bold color_blue ff_heebo mb-0 pt-3">
                    Coding Classes
                  </p>
                  <p className=" fs_16 fw-bold ff_heebo color_lightblack mb-0 pt-3">
                    Mastering Code: Unlocking the Secrets of Programming
                    Excellence
                  </p>
                  <p className=" fs_14 ff_heebo color_yellow mb-0 pt-3">
                    MARCH 13, 2024
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-sm-6">
              <Link to="/language-model">
                <div className="features_box1 p-4 rounded-3 mt-4">
                  <img className="w-100" src={blog_img1} alt="blog_img1" />
                  <p className=" fs_14 fw-bold color_blue ff_heebo mb-0 pt-3">
                    Coding Classes
                  </p>
                  <p className=" fs_16 fw-bold ff_heebo color_lightblack mb-0 pt-3">
                    Mastering Code: Unlocking the Secrets of Programming
                    Excellence
                  </p>
                  <p className=" fs_14 ff_heebo color_yellow mb-0 pt-3">
                    MARCH 13, 2024
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-sm-6">
              <Link to="/language-model">
                <div className="features_box1 p-4 rounded-3 mt-4">
                  <img className="w-100" src={blog_img1} alt="blog_img1" />
                  <p className=" fs_14 fw-bold color_blue ff_heebo mb-0 pt-3">
                    Coding Classes
                  </p>
                  <p className=" fs_16 fw-bold ff_heebo color_lightblack mb-0 pt-3">
                    Mastering Code: Unlocking the Secrets of Programming
                    Excellence
                  </p>
                  <p className=" fs_14 ff_heebo color_yellow mb-0 pt-3">
                    MARCH 13, 2024
                  </p>
                </div>
              </Link>
            </div>
          </div> */}
          <div className="d-flex align-items-center justify-content-center gap-4 mt-5">
            <span className=" pagenation_number cursor_pointer">1</span>
            <span className=" pagenation_number cursor_pointer">2</span>
            <span className=" pagenation_number cursor_pointer">3</span>
            <span className=" pagenation_number cursor_pointer">4</span>
            <img
              className=" cursor_pointer"
              src={pagenation_arrow}
              alt="pagenation_arrow"
            />
          </div>
          <img className=" w-100 pt-5  mt-lg-5" src={poster3} alt="poster3" />
        </div>
      </div>
    </div>
  );
};

export default BlogHero;
