import React from "react";
import Navbar from "../common/Navbar";
import poster2 from "../../assets/images/png/poster1.png";

const WebinarsHero = () => {
  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div className="py-md-5 py-4">
        <div className="container">
          <h3 className=" fs_43 fw-bold ff_heebo color_blue">
            Coding <span className=" color_yellow">webinars</span>
          </h3>
          <img className="w-100 pt-md-5 pt-3" src={poster2} alt="poster2" />
        </div>
      </div>
    </div>
  );
};

export default WebinarsHero;
