import axios from "axios";
import { AppContext } from "./Context";
import React, { useEffect, useState } from "react";

const KoduContext = (props) => {
  const [auth, setAuth] = useState(false);
  const [isLoginPopup, setIsLoginPopup] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  const handleLogin = async (email, password) => {
    const response = await axios.post(
      // "https://api.koduapp.com/admin/login",
      "https://api.koduapp.com/admin/login",
      { email, password },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response?.data?.status === "success") {
      // alert(JSON.stringify(response.data.data));
      localStorage.setItem("admtoken", response.data.data.token);
      setAuth(true);
    } else {
      alert(response?.data?.message);
    }
  };

  useEffect(
    () => {
      if (localStorage.getItem("token")) {
        setIsLogin(true);
      }
    },
    [localStorage.getItem("token")],
    isLogin,
    auth
  );

  return (
    <AppContext.Provider
      value={{
        auth,
        setAuth,
        handleLogin,
        isLoginPopup,
        setIsLoginPopup,
        isLogin,
        setIsLogin,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default KoduContext;
