import React, { useEffect } from "react";
import TermsCondition from "../termscondition/TermsCondition";
import Navbar from "../common/Navbar";

const TermsConditionPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <TermsCondition />
    </div>
  );
};

export default TermsConditionPage;
