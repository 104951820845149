import React from "react";
import enroll_icon from "../../assets/images/png/enroll_icon.png";
import one_image from "../../assets/images/png/one_image.png";
import three_icon from "../../assets/images/png/three_image.png";
import two_image from "../../assets/images/png/two_image.png";
import bach from "../../assets/images/png/bach.png";
import enroll_right_icon from "../../assets/images/png/enroll_right_icon.png";

const EnrollCourses = () => {
  return (
    <div className="pb-5 bg_lightyellow">
      <div className="container position-relative">
        <img
          className="d-md-block d-none position-absolute top-0 end-0"
          src={enroll_right_icon}
          alt="enroll_right_icon"
        />
        <div className="d-flex pt-5 align-items-center justify-content-center gap-2">
          <img src={enroll_icon} alt="icon" />
          <h4 className=" fs_29 ff_heebo fw-medium text-black mb-0">
            How To Enroll Courses
          </h4>
        </div>
        <p className="fs_24 text-center mt-3 ff_heebo fw-normal text-black">
          Learn, compete, and win certificates in coding competitions
        </p>
        <div className="row">
          <div className="col-lg-4 col-sm-6 mt-5">
            <div className="d-flex align-items-center gap-3">
              <img src={one_image} alt="one_image" />
              <div>
                <img width={50} src={bach} alt="bach" />
                <h4 className=" fs_26 ff_heebo fw-medium color_yellow mt-1 mb-0">
                  Select A Course
                </h4>
                <p className=" ff_heebo fw-normal color_darkgray fs_16 mt-2 mb-0">
                  Choose a course of your choice based on the child's age/grade
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 mt-5">
            <div className="d-flex align-items-center gap-3">
              <img src={two_image} alt="one_image" />
              <div>
                <img width={50} src={bach} alt="bach" />
                <h4 className=" fs_26 ff_heebo fw-medium color_yellow mt-1 mb-0">
                  Book a FREE trial class
                </h4>
                <p className=" ff_heebo fw-normal color_darkgray fs_16 mt-2 mb-0">
                  Choose your mentor of choice from 200+ mentors as well as your
                  preferred time slot
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 mt-5">
            <div className="d-flex align-items-center gap-3">
              <img src={three_icon} alt="three_icon" />
              {/* <img src={three_image} alt="three_icon" /> */}
              <div>
                <img width={50} src={bach} alt="bach" />
                <h4 className=" fs_26 ff_heebo fw-medium color_yellow mt-1 mb-0">
                  Enroll for the course
                </h4>
                <p className=" ff_heebo fw-normal color_darkgray fs_16 mt-2 mb-0">
                  After completion of a free demo class , you can get enrolled
                  in the course of your choice and kickstart your career with
                  KODU institute .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnrollCourses;
