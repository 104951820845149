import React from "react";
import UpcomingLive_img from "../../assets/images/png/UpcomingLive_img.png";
import UpcomingLive_img1 from "../../assets/images/png/UpcomingLive_img1.png";
import poster3 from "../../assets/images/png/poster3.png";

const PastWebinar = () => {
  return (
    <div className="py-5">
      <div className="container">
        <h3 className=" fs_43 ff_heebo fw-bold color_blue mb-0">
          Past webinar & Events
        </h3>
        <div className="row mt-3">
          <div className="col-md-4 col-sm-6 mt-4">
            <div className="bg_lightblue h-100 rounded-4">
              <img
                className="w-100"
                src={UpcomingLive_img}
                alt="UpcomingLive_img"
              />
              <div className="p-3  h-100 rounded-bottom-4">
                <h4 className=" fs_22 fw-medium ff_heebo color_blue mb-0">
                  JavaScript Journeys: Navigating the Basics - Webinar Series
                </h4>
                <button className="fs_22 fw-medium ff_heebo bg_darkblue py-1 mt-3 text-white rounded-2 w-100 border-0">
                  Check Recording
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mt-4">
            <div className="bg_lightblue h-100 rounded-4">
              <img
                className="w-100"
                src={UpcomingLive_img1}
                alt="UpcomingLive_img"
              />
              <div className="p-3  h-100 rounded-bottom-4">
                <h4 className=" fs_22 fw-medium ff_heebo color_blue mb-0">
                  Cybersecurity Crash Course: Essential Insights in our Webinar
                </h4>
                <button className="fs_22 fw-medium ff_heebo bg_darkblue py-1 mt-3 text-white rounded-2 w-100 border-0">
                  Check Recording
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6 mt-4">
            <div className="bg_lightblue h-100 rounded-4">
              <img
                className="w-100"
                src={UpcomingLive_img1}
                alt="UpcomingLive_img"
              />
              <div className="p-3  h-100 rounded-bottom-4">
                <h4 className=" fs_22 fw-medium ff_heebo color_blue mb-0">
                  Cybersecurity Crash Course: Essential Insights in our Webinar
                </h4>
                <button className="fs_22 fw-medium ff_heebo bg_darkblue py-1 mt-3 text-white rounded-2 w-100 border-0">
                  Check Recording
                </button>
              </div>
            </div>
          </div>
        </div>
        <img className=" w-100 pt-md-5 pt-4" src={poster3} alt="poster3" />
      </div>
    </div>
  );
};

export default PastWebinar;
