import React from 'react'
import { featuresData2 } from '../common/Helper';

function CourseFeature() {
    return (
        <div className='container'>
            <div>
                <h1 className='ff_heebo fs_29 fw-bolder'>Why Kodu?</h1>
                <p className='ff_heebo fs_15 color_darkgray'>Live interactive programs are a replica of the Classroom programs with all feature of the Classroom model except physical presence of the Mentor and absence of close interaction with the peer group. This model is extremely popular amongst students who want to learn from a popular mentor of choice but are separated demographically.</p>
            </div>
            <div className="row">
                {featuresData2.map((obj, i) => {
                    return (
                        <>
                            <div key={i} className="col-lg-3 col-md-4 col-sm-6">
                                <div className="features_box h-100 cursor_pointer p-4 mt-4 rounded-2">
                                    <img width={40} src={obj.image} alt="image" />
                                    <p className=" fs_22 fw-medium ff_heebo color_darkgray mt-3 mb-0">
                                        {obj.paragraph}
                                    </p>
                                </div>
                            </div>
                        </>
                    );
                })}
            </div>
        </div>
    )
}

export default CourseFeature