import React, { useEffect } from "react";
import ScholarshipHero from "../scholarshipPage/ScholarshipHero";
import ExamDetails from "../scholarshipPage/ExamDetails";
import ScholarshipFaq from "../scholarshipPage/ScholarshipFaq";
import ScholarshipBenefit from "../scholarshipPage/ScholarshipBenefit";

const ScholarshipPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <ScholarshipHero />
      <ExamDetails />
      <ScholarshipFaq />
      <ScholarshipBenefit />
    </div>
  );
};

export default ScholarshipPage;
