import React from "react";
import videoCoverImage from "../../assets/images/png/Group 2530.png";
import { Col, Row } from "react-bootstrap";
import icone from "../../assets/images/png/g2693.png";
function CourseHero() {
  return (
    <div className="bg_darkblue">
      <div className="py-4 couser_header_border">
        <div className="container">
          <span className="color_yellow fs_17 ff_heebo">
            Our Coding Courses/
          </span>
          <span className="text-white fs_17 ff_heebo">
            {" "}
            Full Stack Development with Assistance
          </span>
        </div>
      </div>
      <div className="container d-flex justify-content-center w-100 py-3 h-100">
        <div className="d-flex justify-content-center w-100">
          <div className="row jusify-center mx-lg-5 py-4 h-100">
            <div className="col-lg-4 col-12">
              {/* <img src={videoCoverImage} className="rounded w-100" /> */}
              <iframe
                className="rounded-5"
                width="315"
                height="560"
                src="https://www.youtube.com/embed/4x6B9HNfBOY?si=wB-fb4Yy5"
                // title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
            <div className="col-lg-8 col-md-12 h-100 mt-3 mt-lg-0">
              <div className="d-flex flex-column justify-content-lg-evenly h-100 w-100">
                <div>
                  <h1 className="fs_43 fw_bolder text-white ff_heebo">
                    LIVE Interactive Courses at ₹3000
                  </h1>
                  <span className="text-white ff_heebo w-75 fs_22">
                    Students most trusted platform now becomes <br /> the most
                    affordable!
                  </span>
                </div>
                <Row className="mt-4">
                  <Col lg={3} md={3} className="pt-2 pt-lg-0">
                    <div className="bg_yellow h-100 rounded p-3">
                      <div>
                        <img src={icone} />
                      </div>
                      <div className="mt-4">
                        <p className="fs_17 fs_heebo">
                          220+ Hours of LIVE Lectures
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3} md={3} className="pt-2 pt-lg-0">
                    <div className="bg_yellow h-100 rounded p-3">
                      <div>
                        <img src={icone} />
                      </div>
                      <div className="mt-4">
                        <p className="fs_17 fs_heebo">
                          Hi-Tech Portal for Recordings & Coding Practice
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3} md={3} className="pt-2 pt-lg-0">
                    <div className="bg_yellow h-100 rounded p-3">
                      <div>
                        <img src={icone} />
                      </div>
                      <div className="mt-4">
                        <p className="fs_17 fs_heebo">1:1 Doubt support</p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3} md={3} className="pt-2 pt-lg-0">
                    <div className="bg_yellow h-100 rounded p-3">
                      <div>
                        <img src={icone} />
                      </div>
                      <div className="mt-4">
                        <p className="fs_17 fs_heebo">
                          Job Assistance & Certificate
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="mt-4">
                  <button className="bg-white btn px-5 fs_20 ff_heebo fw-bold py-3">
                    Check out the courses
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseHero;
