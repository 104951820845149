import React from "react";
import { Col, Row } from "react-bootstrap";
import imge from "../../assets/images/png/Group 2534.png";
function Features() {
  return (
    <div className="container py-5 my-5">
      <Row>
        <Col lg={8} className="mx-0 p-0">
          <div className="bg_lightyellow2 rounded-left border-right-dashed p-4">
            <h1 className="fs_27 fw-bolder ff_heebo">
              Features of LIVE <br />
              interactive courses of Kodu
            </h1>
            <div className="row mt-5">
              <div className="col-lg-7">
                {/* <img src={imge} className="w-100 " /> */}
                <iframe
                  className="rounded-5 w-100 h-100"
                  src="https://www.youtube.com/embed/F7zraFDiWZY"
                  // title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className="col-lg-5 pt-2 pt-lg-0">
                <ul className="p-0 ps-2 liststyle">
                  <p className="mb-0 ff_heebo fs_15 py-1">
                    1. Around 65 lectures of 3 hoursduration for Content and
                    topicsfor DSA, System design will beadditional.
                  </p>
                  <p className="mb-0 ff_heebo fs_15 py-1">
                    2. All sessions are Live interactive,so you can actually
                    halt thementor, clarify your doubts andthen proceed.
                  </p>
                  <p className="mb-0 ff_heebo fs_15 py-1">
                    3. System design will also bedelivered in the same price.
                  </p>
                </ul>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={4} className="heigltbox mx-0 p-0">
          <div className="d-flex justify-content-center flex-column h-100 px-3">
            <div className="ps-4 ms-2">
              <h2 className="ff_heebo color_yellow">Key Hightlights</h2>
            </div>
            <ul className="mt-3 ps-5">
              <li className="mb-0 ff_heebo fs_15 py-1 liststyle">
                Developer CV
              </li>
              <li className="mb-0 ff_heebo fs_15 py-1 liststyle">
                Certificate Course
              </li>
              <li className="mb-0 ff_heebo fs_15 py-1 liststyle">
                7-9 months duration
              </li>
              <li className="mb-0 ff_heebo fs_15 py-1 liststyle">
                Interactive Live Batches,
              </li>
              <li className="mb-0 ff_heebo fs_15 py-1 liststyle">
                220+ Hours of Live Lectures
              </li>
              <li className="mb-0 ff_heebo fs_15 py-1 liststyle">
                Placement & Internship assistance
              </li>
              <li className="mb-0 ff_heebo fs_15 py-1 liststyle">
                LeetCode, Codeforces profiles development
              </li>
              <li className="mb-0 ff_heebo fs_15 py-1 liststyle">
                Learn on high tech LMS, Code on intepreted IDE
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Features;
