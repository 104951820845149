import React from "react";
import { Accordion } from "react-bootstrap";

export default function Faqs() {
  return (
    <div className="container py-5">
      <Accordion defaultActiveKey="0">
        <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="0">
          <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
            <li className=" custom_list_style_type color_yellow"></li>
            What is full stack development?
          </Accordion.Header>
          <Accordion.Body className="px-5 pb-4">
            Full stack development involves working on both the front-end and
            back-end parts of an application. The front-end is what users
            interact with directly, and the back-end involves the server-side,
            focusing on database management, user authentication, server,
            network, and hosting configurations.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="1">
          <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
            <li className=" custom_list_style_type color_yellow"></li>
            What languages and technologies will I learn in this course?
          </Accordion.Header>
          <Accordion.Body className="px-5 pb-4">
            Typically, a full stack course covers HTML, CSS, JavaScript for the
            front-end, and one or more programming languages like Python, Java,
            or JavaScript (Node.js) for the back-end. You'll also learn about
            databases (SQL and NoSQL), version control systems (like Git), and
            perhaps frameworks like React & Node.js, Express.js & MongoDB.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="2">
          <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
            <li className=" custom_list_style_type color_yellow"></li>
            Do I need any prior programming experience?
          </Accordion.Header>
          <Accordion.Body className="px-5 pb-4">
            No perquisite is required to start your Full Stack Development
            Program. Anyone from any background can start their career in
            programming.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="3">
          <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
            <li className=" custom_list_style_type color_yellow"></li>
            How long does the course last?
          </Accordion.Header>
          <Accordion.Body className="px-5 pb-4">
            The duration of full stack development courses can vary. Shorter
            courses might last a few weeks, while full stack development course
            can take upto 1 year.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="4">
          <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
            <li className=" custom_list_style_type color_yellow"></li>
            What are the class timings?
          </Accordion.Header>
          <Accordion.Body className="px-5 pb-4">
            Class timings can vary based on the locations & language courses.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="5">
          <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
            <li className=" custom_list_style_type color_yellow"></li>
            Is there any project work involved?
          </Accordion.Header>
          <Accordion.Body className="px-5 pb-4">
            Yes, full stack courses include hands-on projects. These projects
            help you apply what you’ve learned in real-world scenarios, building
            anything from simple web applications to complex interactive
            websites.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="6">
          <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
            <li className=" custom_list_style_type color_yellow"></li>
            What kind of support will I get during the course?
          </Accordion.Header>
          <Accordion.Body className="px-5 pb-4">
            In classroom course, you'll likely have access to face-to-face
            instruction, peer collaboration, and office hours with instructors.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="6">
          <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
            <li className=" custom_list_style_type color_yellow"></li>
            What are the career opportunities after completing this course?
          </Accordion.Header>
          <Accordion.Body className="px-5 pb-4">
            After completing a full stack development course, you can pursue
            roles such as full stack developer, web developer, software
            engineer, and more. The skills you learn are applicable in almost
            every industry that relies on web or mobile applications.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="6">
          <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
            <li className=" custom_list_style_type color_yellow"></li>
            What if I miss a class?
          </Accordion.Header>
          <Accordion.Body className="px-5 pb-4">
            Many courses offer recorded sessions, or you might be able to attend
            a makeup class. It’s important to check the course's policy on
            attendance and missed classes.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="6">
          <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
            <li className=" custom_list_style_type color_yellow"></li>
            Is there a certification upon completion?
          </Accordion.Header>
          <Accordion.Body className="px-5 pb-4">
            Kodu classes provide a certificate of completion. This can be
            beneficial for your resume or LinkedIn profile as proof of your
            newly acquired skills.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="6">
          <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
            <li className=" custom_list_style_type color_yellow"></li>
            What are the assessment methods in the course?
          </Accordion.Header>
          <Accordion.Body className="px-5 pb-4">
            Assessments typically include quizzes, coding assignments, and a
            final project. These are designed to test your understanding of the
            material and practical application of skills.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item className="mt-2 border-0 rounded-0" eventKey="6">
          <Accordion.Header className=" fs_17 ff_heebo color_darkgray">
            <li className=" custom_list_style_type color_yellow"></li>
            How much does the course cost?
          </Accordion.Header>
          <Accordion.Body className="px-5 pb-4">
            Costs can vary widely depending on the institution, course duration,
            and whether there are any additional resources or materials
            included.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
