import Features_icon from "../../assets/images/png/Features_icon.png";
import Features_icon1 from "../../assets/images/png/Features_icon1.png";
import Features_icon2 from "../../assets/images/png/Features_icon2.png";
import Features_icon3 from "../../assets/images/png/Features_icon3.png";
import Features_icon4 from "../../assets/images/png/Features_icon4.png";
import Features_icon5 from "../../assets/images/png/Features_icon5.png";
import Features_icon6 from "../../assets/images/png/Features_icon6.png";
import Features_icon7 from "../../assets/images/png/Features_icon7.png";
import project_image1 from "../../assets/images/png/project_image1.png";
import project_image2 from "../../assets/images/png/project_image2.png";
import project_image3 from "../../assets/images/png/project_image3.png";
import project_image4 from "../../assets/images/png/project_image4.png";
import project_image5 from "../../assets/images/png/project_image5.png";
import Reviews_image7 from "../../assets/images/png/Reviews_image7.png";
import Reviews_image8 from "../../assets/images/png/Reviews_image8.png";
import Reviews_image9 from "../../assets/images/png/Reviews_image9.png";

export const featuresData = [
  {
    image: Features_icon,
    paragraph: "100% Job Placement Assistance",
  },
  {
    image: Features_icon1,
    paragraph: "Scholarship Options & EMI Assistance",
  },
  {
    image: Features_icon2,
    paragraph: "Experienced Trainer's",
  },
  {
    image: Features_icon3,
    paragraph: "Internship Opportunities",
  },

  {
    image: Features_icon4,
    paragraph: "Mentorship By Top IT Professional's",
  },
  {
    image: Features_icon5,
    paragraph: "Working on Live Projects",
  },

  {
    image: Features_icon6,
    paragraph: "Limited Batch Size",
  },
  {
    image: Features_icon7,
    paragraph: "Offline And Online Learning",
  },
];
export const featuresData2 = [
  {
    image: Features_icon,
    paragraph: "Learn from Highly experienced faculty ",
  },
  {
    image: Features_icon,
    paragraph: "Get industry exposure ",
  },
  {
    image: Features_icon,
    paragraph: "Get placement assistance ",
  },
  {
    image: Features_icon,
    paragraph: "Preparations - resume building & mock interviews ",
  },
];
export const AmazingProjectsData = [
  {
    link: "https://medical-fullstack.vercel.app/",
    image: project_image1,
    name: "Deepak Kumar",
    project_name: "Medical Report Management",
    button: "View Project",
  },
  {
    link: "https://my-react-nu.vercel.app/",
    image: project_image2,
    name: "Vinu",
    project_name: "Bandage",
    button: "View Project",
  },
  { 
    link: "https://color-selector-one.vercel.app/",
    image: project_image3,
    name: "Sahil",
    project_name: "Color prediction game",
    button: "View Project",
  },
  {
    link: "https://free-water-six.vercel.app/",
    image: project_image4,
    name: "Ashish",
    project_name: "Landing Page",
    button: "View Project",
  },
  {
    link: "https://sight-care.vercel.app/",
    image: project_image5,
    name: "Deepak",
    project_name: "Google ad landing page",
    button: "View Project",
  },
];
export const ReviewsData = [
  {
    url: "https://youtube.com/shorts/6GyBYclK9fs",
    image: Reviews_image7,
    name: "kHushi",
    see_video: "See Video",
  },
  {
    url: "https://youtube.com/shorts/YO1HRSO5QgQ",
    image: Reviews_image8,
    name: "Pratham ",
    see_video: "See Video",
  },
  {
    url: "https://youtu.be/3QWTQt0tzUM",
    image: Reviews_image9,
    name: "Mohit ",
    see_video: "See Video",
  },
];
