import React from "react";
import { Link } from "react-router-dom";

const ThankqPage = () => {
  const handleDownload = () => {
    const pdfUrl = process.env.PUBLIC_URL + "New_Kodu_Brochure_Curriculum.pdf";
    const anchor = document.createElement("a");
    anchor.href = pdfUrl;
    anchor.download = "New_Kodu_Brochure_Curriculum.pdf";
    anchor.click();
  };

  return (
    <div className=" min-vh-100 d-flex justify-content-center align-items-center flex-grow-1 bg_darkblue">
      <div className="text-center">
        <h1 className="fs-1 ff_redrose color_yellow">Thank You!</h1>
        <p className=" ff_heebo text-white fs-5">
          Your request has been submitted successfully. We will contact you
          soon.
        </p>
        <div className="d-flex justify-content-center gap-5 align-items-center">
          <button
            className="Free_btn border-0 ff_heebo fs_17 fw-medium color_lightwhite"
            onClick={handleDownload}
          >
            Download Brochure
          </button>

          <Link to="/">
            <button className="border border-1 bg-transparent px-5 py-2 rounded-2 ff_heebo fs_17 fw-medium color_lightwhite">
              Visit Website
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ThankqPage;
