import React, { useCallback, useEffect, useState } from "react";
import makeApiCall from "../callApi";
import crossblack_icon from "../../assets/images/png/blackcross_icon.png";
import edit_icon from "../../assets/images/png/edit_icon.png";
import delete_icon from "../../assets/images/png/delete_icon.png";
import { Modal } from "react-bootstrap";
import MultiyTestSelectComponent from "./MultiyTestSelectComponent";

const QuizTab = () => {
  const [addQuizModal, setAddQuizModal] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedValuesForUpdate, setSelectedValuesForUpdate] = useState([]);
  const [quizData, setQuizData] = useState({
    id: "",
    question: "",
    option_a: "",
    option_b: "",
    option_c: "",
    option_d: "",
    // option_e: "",
    answer: "",
  });
  const [newQuizData, setNewQuizData] = useState({ title: "" });
  const [value, setValue] = useState("");
  const [newValue, setNewValue] = useState("");

  useEffect(() => {
    setNewQuizData({
      id: currentData?._id,
      question: currentData?.question,
      option_a: currentData?.option_a,
      option_b: currentData?.option_b,
      option_c: currentData?.option_c,
      option_d: currentData?.option_d,
      // option_e: currentData?.option_e,
      answer: currentData?.answer,
    });
    setSelectedValuesForUpdate(currentData?.selectedValues || []);
  }, [currentData]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setQuizData((prevData) => ({
        ...prevData,
        [name]: files?.length > 0 ? files[0] : value,
      }));
    } else {
      setQuizData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleInputChange2 = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setNewQuizData((prevData) => ({
        ...prevData,
        [name]: files?.length > 0 ? files[0] : value,
      }));
    } else {
      setNewQuizData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  async function createBlog(e) {
    e.preventDefault();
    let ids = selectedValues.map((value) => value.id);
    let formData = {
      question: quizData.question,
      option_a: quizData.option_a,
      option_b: quizData.option_b,
      option_c: quizData.option_c,
      option_d: quizData.option_d,
      // option_e: quizData.option_e,
      answer: quizData.answer,
      test_id: ids,
    };

    await makeApiCall(
      formData,
      "post",
      "admin/updateQuestion",
      createblogCallBack,
      false
    );
  }

  const createblogCallBack = useCallback(async (res) => {
    console.log(res);
    if (res.data.status === "success") {
      setAddQuizModal(false);
      setQuizData({
        id: "",
        question: "",
        option_a: "",
        option_b: "",
        option_c: "",
        option_d: "",
        // option_e: "",
        answer: "",
      });
      setSelectedValues([]);
      getQuiz();
    }
  }, []);

  async function updateQuiz(e) {
    e.preventDefault();
    let ids = selectedValuesForUpdate.map((value) => value.id);
    const formdata = {
      id: newQuizData.id,
      question: newQuizData.question,
      option_a: newQuizData.option_a,
      option_b: newQuizData.option_b,
      option_c: newQuizData.option_c,
      option_d: newQuizData.option_d,
      // option_e: newQuizData.option_e,
      answer: newQuizData.answer,
      test_id: ids,
    };

    await makeApiCall(
      formdata,
      "post",
      "admin/updateQuestion",
      updateQuizCallBack,
      false
    );
  }

  const updateQuizCallBack = useCallback(async (res) => {
    console.log(res);
    if (res.data.status === "success") {
      setFreeTrialModalVisible(false);
      getQuiz();
    }
  }, []);

  useEffect(() => {
    console.log(quizData);
  }, [quizData]);

  useEffect(() => {
    console.log(value);
  }, [value]);

  const [testList, SetTestList] = useState([]);

  async function getQuiz() {
    await makeApiCall("", "get", "admin/questionList", questionListCallBack);
  }

  useEffect(() => {
    getQuiz();
  }, []);

  const questionListCallBack = useCallback((response) => {
    console.log(response);
    if (response.status === 200) {
      SetTestList(response.data.data);
    } else {
      console.log("error");
    }
  }, []);

  async function deleteCourse(id) {
    await makeApiCall(
      "",
      "get",
      `admin/deleteQuestion/${id}`,
      testDeleteCallBack
    );
  }

  const testDeleteCallBack = useCallback(
    (response) => {
      console.log(response);
      if (response.status === 200) {
        console.log("Course deleted successfully");
        getQuiz();
      } else {
        console.error("Error deleting course");
      }
    },
    [getQuiz]
  );

  useEffect(() => {
    getQuiz();
  }, []);

  const [freeTrialModalVisible, setFreeTrialModalVisible] = useState(false);
  const toggleFreeTrialModal = () => {
    setFreeTrialModalVisible(!freeTrialModalVisible);
  };

  const handleResizeError = (e) => {
    if (e.message.includes("ResizeObserver")) {
      console.error("ResizeObserver error suppressed:", e);
      return;
    }
    throw e;
  };

  window.addEventListener("error", handleResizeError);
  return (
    <div>
      <h2 className=" fs_37 fw-semibold ff_heebo">Questions</h2>
      <div className="border my-4"></div>

      {!addQuizModal ? (
        <>
          <div className=" mb-4">
            <button
              onClick={() => setAddQuizModal(true)}
              className=" bg_yellow py-2 px-4 border-0 ff_heebo text-white fw-bold"
            >
              ADD NEW QUESTION
            </button>
          </div>
          <div className="  border-0 ff_heebo pb-4">
            <div
              className="card rounded-3 rounded-0"
              style={{ maxWidth: "1300px", overflowX: "auto" }}
            >
              <table className="w-100">
                <thead>
                  <tr>
                    <th className="py-3 borderstart_r text-white  ff_heebo  px-3 bg_darkblue">
                      Question
                    </th>
                    <th className="py-3 borderend_r text-white  ff_heebo  px-3 bg_darkblue">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {testList.length < 1 ? (
                    <tr>
                      <td colSpan="2" className="text-start ps-3 py-4">
                        No data found
                      </td>
                    </tr>
                  ) : (
                    testList.map((obj, i) => (
                      <tr key={i}>
                        <td className="px-3 py-3">{obj.question}</td>
                        <td className="px-3 py-3">
                          <div className="d-flex gap-3 bg-white">
                            <img
                              onClick={() => {
                                toggleFreeTrialModal();
                                setCurrentData(obj);
                              }}
                              width={20}
                              className=" cursor_pointer"
                              src={edit_icon}
                              alt="Edit"
                            />
                            <img
                              onClick={() => deleteCourse(obj._id)}
                              width={20}
                              className=" cursor_pointer"
                              src={delete_icon}
                              alt="Delete"
                            />
                          </div>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <div className="card rounded-0 px-5 py-4 position-relative">
          <div className="position-absolute top-0 end-0">
            <img
              onClick={() => setAddQuizModal(false)}
              width={20}
              className="me-3 mt-3 cursor_pointer"
              src={crossblack_icon}
              alt="Close"
            />
          </div>
          <div>
            <form onSubmit={createBlog}>
              <div className="d-flex flex-column w-100 mt-4">
                <label className="fs_16 text_darkblue ff_heebo">Question</label>
                <input
                  className=" border p-2 rounded"
                  type="text"
                  name="question"
                  value={quizData.question}
                  onChange={handleInputChange}
                />
              </div>
              <div className="row">
                <div className="d-flex flex-column col-md-6 mt-4">
                  <label className="fs_16 text_darkblue ff_heebo">
                    Option A
                  </label>
                  <input
                    className=" border p-2 rounded"
                    type="text"
                    name="option_a"
                    value={quizData.option_a}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="d-flex flex-column col-md-6 mt-4">
                  <label className="fs_16 text_darkblue ff_heebo">
                    Option B
                  </label>
                  <input
                    className=" border p-2 rounded"
                    type="text"
                    name="option_b"
                    value={quizData.option_b}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="d-flex flex-column col-md-6 mt-4">
                  <label className="fs_16 text_darkblue ff_heebo">
                    Option C
                  </label>
                  <input
                    className=" border p-2 rounded"
                    type="text"
                    name="option_c"
                    value={quizData.option_c}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="d-flex flex-column col-md-6 mt-4">
                  <label className="fs_16 text_darkblue ff_heebo">
                    Option D
                  </label>
                  <input
                    className=" border p-2 rounded"
                    type="text"
                    name="option_d"
                    value={quizData.option_d}
                    onChange={handleInputChange}
                  />
                </div>
                {/* <div className="d-flex flex-column col-md-6 mt-4">
                  <label className="fs_16 text_darkblue ff_heebo">
                    Option E
                  </label>
                  <input
                    className=" border p-2 rounded"
                    type="text"
                    name="option_e"
                    value={quizData.option_e}
                    onChange={handleInputChange}
                  />
                </div> */}
                <div className="d-flex flex-column col-md-6 mt-4">
                  <label className="fs_16 text_darkblue ff_heebo">Answer</label>
                  <input
                    className=" border p-2 rounded"
                    type="text"
                    name="answer"
                    value={quizData.answer}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="d-flex pe-3 flex-column col-md-6 mt-4">
                <p>Test Select</p>
                <MultiyTestSelectComponent
                  selectedValues={selectedValues}
                  setSelectedValues={setSelectedValues}
                />
              </div>
              <div className="mt-4">
                <button
                  type="submit"
                  className=" bg_yellow py-2 px-4 border-0 ff_heebo text-white fw-bold"
                >
                  ADD QUESTION
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <Modal
        show={freeTrialModalVisible}
        onHide={toggleFreeTrialModal}
        centered
        className="custom-modal"
      >
        <div
          style={{ width: "700px", margin: "0 auto" }}
          className="bg-white rounded-3 "
        >
          <Modal.Body className="p-4">
            <div className="text-end">
              <img
                onClick={() => setFreeTrialModalVisible(false)}
                width={20}
                className=" cursor_pointer"
                src={crossblack_icon}
                alt="Close"
              />
            </div>
            <div>
              <form onSubmit={updateQuiz}>
                <div className="d-flex flex-column col-12 mt-4">
                  <label className="fs_16 text_darkblue ff_heebo">
                    Question
                  </label>
                  <input
                    className=" border p-2 rounded"
                    type="text"
                    name="question"
                    value={newQuizData.question}
                    onChange={handleInputChange2}
                  />
                </div>
                <div className="row">
                  <div className="d-flex flex-column col-md-6 mt-4">
                    <label className="fs_16 text_darkblue ff_heebo">
                      Option A
                    </label>
                    <input
                      className=" border p-2 rounded"
                      type="text"
                      name="option_a"
                      value={newQuizData.option_a}
                      onChange={handleInputChange2}
                    />
                  </div>
                  <div className="d-flex flex-column col-md-6 mt-4">
                    <label className="fs_16 text_darkblue ff_heebo">
                      Option B
                    </label>
                    <input
                      className=" border p-2 rounded"
                      type="text"
                      name="option_b"
                      value={newQuizData.option_b}
                      onChange={handleInputChange2}
                    />
                  </div>
                  <div className="d-flex flex-column col-md-6 mt-4">
                    <label className="fs_16 text_darkblue ff_heebo">
                      Option C
                    </label>
                    <input
                      className=" border p-2 rounded"
                      type="text"
                      name="option_c"
                      value={newQuizData.option_c}
                      onChange={handleInputChange2}
                    />
                  </div>
                  <div className="d-flex flex-column col-md-6 mt-4">
                    <label className="fs_16 text_darkblue ff_heebo">
                      Option D
                    </label>
                    <input
                      className=" border p-2 rounded"
                      type="text"
                      name="option_d"
                      value={newQuizData.option_d}
                      onChange={handleInputChange2}
                    />
                  </div>
                  {/* <div className="d-flex w-100 flex-column col-12 mt-4">
                    <label className="fs_16 text_darkblue ff_heebo">
                      Option E
                    </label>
                    <input
                      className=" border p-2 rounded"
                      type="text"
                      name="option_e"
                      value={newQuizData.option_e}
                      onChange={handleInputChange2}
                    />
                  </div> */}
                  <div className="d-flex w-100 flex-column col-12 mt-4">
                    <label className="fs_16 text_darkblue ff_heebo">
                      Answer
                    </label>
                    <input
                      className=" border p-2 rounded"
                      type="text"
                      name="answer"
                      value={newQuizData.answer}
                      onChange={handleInputChange2}
                    />
                  </div>
                </div>
                <div className="col-md-12 mt-4">
                  <MultiyTestSelectComponent
                    selectedValues={selectedValuesForUpdate}
                    setSelectedValues={setSelectedValuesForUpdate}
                  />
                </div>
                <div className="mt-4">
                  <button
                    type="submit"
                    className=" bg_yellow py-2 px-4 border-0 ff_heebo text-white fw-bold"
                  >
                    UPDATE QUESTION
                  </button>
                </div>
              </form>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default QuizTab;
