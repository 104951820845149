import React, { useEffect } from "react";
import CenterHero from "../centersPage/CenterHero";
import Footer from "../common/Footer";
import Navbar from "../common/Navbar";
import ClassroomBatches from "../centersPage/ClassroomBatches";
import CenterPlacement from "../centersPage/CenterPlacement";
import TopMentor from "../centersPage/TopMentor";
import Faqs from "../centersPage/Faqs";
import OurCenters from "../centersPage/OurCenters";

const CentersPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Navbar />
      <CenterHero />
      <ClassroomBatches />
      <OurCenters/>
      <CenterPlacement/>
      <TopMentor/>
      <Faqs/>
    </div>
  );
};

export default CentersPage;
