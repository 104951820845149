import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Modal, Spinner } from "react-bootstrap";
import coding_tabs from "../../assets/images/png/coding_tabs_imagr.png";
import hero_image from "../../assets/images/png/hero_student_image.png";
import crosswhtie_icon from "../../assets/images/png/crosswhite_icon.png";
import green_bag from "../../assets/images/png/green_bag.png";
import playbutton from "../../assets/images/png/SVGRepo_iconCarrier.png";
import Slider from "react-slick";
import makeApiCall from "../callApi";
import { ToastContainer, toast } from "react-toastify";
import cross_icon from "../../assets/images/png/cross_icon.jpg";

const CodingCourses = ({ courses }) => {
  const [freeTrialModalVisible, setFreeTrialModalVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(
    "Programming Language’s"
  );

  const handleCourseClick = (category) => {
    setSelectedCategory(category);
  };
  const filteredCourses = courses.filter((course) =>
    course.category.includes(selectedCategory)
  );

  console.log(selectedCategory);

  const settings = {
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const toggleFreeTrialModal = () => {
    setFreeTrialModalVisible(!freeTrialModalVisible);
  };

  const [formData, setformData] = useState({
    name: "",
    email: "",
    phone: "",
    topic: "",
    qualification: "",
    course: "",
    nearestCenter: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setformData((prevData) => ({
        ...prevData,
        [name]: files?.length > 0 ? files[0] : value,
      }));
    } else {
      setformData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const validateForm = () => {
    const { name, email, phone, topic, qualification, course, nearestCenter } =
      formData;
    if (
      !name ||
      !email ||
      !phone ||
      !topic ||
      !qualification ||
      !course ||
      !nearestCenter
    ) {
      toast.error("All fields are required");
      return false;
    }
    if (
      qualification === " -- Select Qualification --" ||
      course === " -- Select Course --" ||
      nearestCenter === " -- Select Nearest Center --"
    ) {
      toast.error("Please select valid options for all dropdowns");
      return false;
    }
    return true;
  };

  const createForm = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsLoading(true);

    const formdata = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      topic: formData.topic,
      qualification: formData.qualification,
      course: formData.course,
      nearestCenter: formData.nearestCenter,
    };

    try {
      const res = await makeApiCall(
        formdata,
        "post",
        "new_lead",
        createFormCallBack,
        false
      );
      // createFormCallBack(res);
    } catch (error) {
      toast.error("Failed to create lead. Please try again later.");
      setIsLoading(false);
    }
  };

  const createFormCallBack = useCallback((res) => {
    setIsLoading(false);
    if (res.data.status === "success") {
      toast.success(
        "Congratulations! Your request has been submitted successfully!"
      );
      setformData({
        name: "",
        email: "",
        phone: "",
        topic: "",
        qualification: "",
        course: "",
        nearestCenter: "",
      });
    } else {
      toast.error(res.data.message);
    }
  });

  return (
    <div className="pb-md-5 pb-3 pt-md-5 pt-3">
      <ToastContainer />
      <div className="container coding">
        <h4 className="fs_29 ff_heebo fw-medium text-black text-center">
          Our Coding Courses
        </h4>
        <div className="mt-md-5 mt-2 row position-relative align-items-center">
          <img
            className="position-absolute opacity d-md-block d-none"
            src={coding_tabs}
            alt="coding_tabs"
          />
          {/* Navigation tabs */}
          <div className="col-md-3 col-sm-3 col-6 mt-md-0 mt-4 d-flex justify-content-center">
            <span
              onClick={() => handleCourseClick("Programming Language’s")}
              className={
                selectedCategory === "Programming Language’s"
                  ? "selected-course fs_18 text-white fw-medium text-center"
                  : "cursor_pointer py-3 ff_heebo fs_16"
              }
            >
              Programming Languages
            </span>
          </div>
          <div className="col-md-3 col-sm-3 col-6 mt-md-0 mt-4 d-flex justify-content-center">
            <span
              onClick={() => handleCourseClick("Web Development")}
              className={
                selectedCategory === "Web Development"
                  ? "selected-course fs_18 text-white fw-medium text-center"
                  : "cursor_pointer py-3 ff_heebo fs_16"
              }
            >
              Web Development
            </span>
          </div>
          <div className="col-md-3 col-sm-3 col-6 mt-md-0 mt-4 d-flex justify-content-center">
            <span
              onClick={() => handleCourseClick("Android Development")}
              className={
                selectedCategory === "Android Development"
                  ? "selected-course fs_18 text-white fw-medium text-center"
                  : "cursor_pointer py-3 ff_heebo fs_16"
              }
            >
              Android Development
            </span>
          </div>
          <div className="col-md-3 col-sm-3 col-6 mt-md-0 mt-4 d-flex justify-content-center">
            <span
              onClick={() => handleCourseClick("Assistance Program")}
              className={
                selectedCategory === "Assistance Program"
                  ? "selected-course fs_18 text-white fw-medium text-center"
                  : "cursor_pointer py-3 ff_heebo fs_16"
              }
            >
              Assistance Program
            </span>
          </div>
        </div>

        {filteredCourses.length === 0 ? (
          <p className="fs_15 ff_heebo fw-medium py-5 text-center">
            No data found for {selectedCategory}.
          </p>
        ) : filteredCourses.length <= 3 ? (
          <div className="row mb-5 mt-4 ">
            {filteredCourses.map((course, index) => (
              <div key={index} className={`col-xxl-4 px-5 col-sm-6 pt-4`}>
                <div className="hero_cart h-100 d-flex pb-3 flex-column p-xl-4 p-3 position-relative">
                  <div className=" text-end">
                    {/* <img width={153} src={hero_image} alt="hero_image" />
                     */}
                    <img
                      className="w-100 rounded-2"
                      src={`https://api.koduapp.com/public/images/${course?.image}`}
                      alt="hello"
                    />
                  </div>
                  <div className=" mt-3 h-100 d-flex flex-column justify-content-between">
                    <div>
                      <button className="fs_7 ff_heebo fw-medium color_darkgreen rounded-1 py-1 bg_lightgreen border-0 px-2">
                        <img width={15} src={green_bag} alt="green_bag" />{" "}
                        Guaranteed Placement
                      </button>
                      <h3
                        style={{
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          WebkitLineClamp: 2,
                          cursor: "pointer",
                          position: "relative",
                        }}
                        title={course.title}
                        className="fs_17 ff_heebo fw-bold color_darkgray mt-2 mb-0"
                      >
                        {course.title}
                      </h3>

                      <div className="mt-3">
                        <div
                          style={{ height: "200px" }}
                          className="row overflow-y-auto "
                        >
                          {course?.features?.map((item, index) => {
                            return (
                              <div className="col-6  gap-2" key={index}>
                                <div className="d-flex gap-2">
                                  <div>
                                    <img src={playbutton} />
                                  </div>
                                  <div>
                                    <span className="fs_12 ff_heebo ">
                                      {item}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div className="d-flex w-100 justify-content-between align-items-center mt-2">
                          <div className="d-flex flex-column">
                            <span className="fs_15  ff_heebo gothrow_price">
                              ₹{course?.discount}
                            </span>
                            <span className="fs_29 fw-bolder color_blue ff_heebo">
                              ₹{course?.price}
                            </span>
                            <span className="fs_15 ff_heebo">
                              {course?.validity} Months
                            </span>
                          </div>
                          <div>
                            <Link to={`/course-details/${course._id}`}>
                              <button className="btn bg_darkblue ff_heebo text-white px-5 py-2">
                                Enroll
                              </button>
                            </Link>
                            <p
                              onClick={toggleFreeTrialModal}
                              className="color_blue ff_heebo cursor_pointer fw-medium mt-3 text-center mb-0"
                            >
                              Know More
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <Slider {...settings}>
            {filteredCourses.map((course, index) => (
              <div className="px-4">
                <div key={index} className={`px-4 pt-4 mt-4`}>
                  <div className="hero_cart  h-100 d-flex flex-column p-xl-4 p-3 position-relative">
                    <div className=" text-end">
                      <img
                        className="w-100 rounded-2"
                        src={`https://api.koduapp.com/public/images/${course?.image}`}
                        alt="hello"
                      />
                    </div>
                    <div className=" mt-3 h-100 d-flex flex-column justify-content-between">
                      <div>
                        <button className="fs_7 ff_heebo fw-medium color_darkgreen rounded-1 py-1 bg_lightgreen border-0 px-2">
                          <img width={15} src={green_bag} alt="green_bag" />{" "}
                          Guaranteed Placement
                        </button>
                        <h3
                          style={{
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            WebkitLineClamp: 2,
                            cursor: "pointer",
                            position: "relative",
                          }}
                          title={course.title}
                          className="fs_17 ff_heebo fw-bold color_darkgray mt-2 mb-0"
                        >
                          {course.title}
                        </h3>

                        <div className="mt-3 ">
                          <div
                            style={{ height: "200px" }}
                            className="row overflow-y-auto "
                          >
                            {course?.features?.map((item, index) => {
                              return (
                                <div className="col-6  gap-2" key={index}>
                                  <div className="d-flex align-items-start gap-2">
                                    <div className="mt-2">
                                      <img src={playbutton} />
                                    </div>
                                    <div>
                                      <span className="fs_12 ff_heebo ">
                                        {item}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="d-flex w-100 justify-content-between align-items-center mt-4">
                          <div className="d-flex flex-column">
                            <span className="fs_15  ff_heebo gothrow_price">
                              ₹{course?.price}
                            </span>
                            <span className="fs_29 fw-bolder color_blue ff_heebo">
                              ₹{course?.discount}
                            </span>
                            <span className="fs_15 ff_heebo">
                              {course?.validity} Months
                            </span>
                          </div>
                          <div>
                            <Link to={`/course-details/${course._id}`}>
                              <button className="btn bg_darkblue ff_heebo text-white px-5 py-2">
                                Enroll
                              </button>
                            </Link>
                            <p
                              onClick={toggleFreeTrialModal}
                              className="color_blue cursor_pointer ff_heebo fw-medium mt-2 text-center  mb-0"
                            >
                              Know More
                            </p>
                          </div>
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        )}

        <div>
          <div className="bg_lightblue p-4 rounded-2 mt-5">
            <p className="mt-3 fs_16 ff_heebo color_darkgray mb-0">
              You can also seek career counseling and expert assistance in
              choosing your course or mode of study.
            </p>
            <button
              onClick={toggleFreeTrialModal}
              className="bg_darkblue px-4 py-2 rounded-2 border-0 text-white fw-medium fs_16 mt-3"
            >
              Get Career Advice
            </button>
            <div className="d-lg-flex mt-3 justify-content-between">
              <div>
                <h5 className="fs_22 mb-0 ff_heebo color_blue">
                  You can also explore our courses based on the mode of study
                </h5>

                <Dropdown className="mt-3">
                  <Dropdown.Toggle className="bg_darkblue" id="dropdown-basic">
                    Check Now
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Link to="/centers">
                      <Dropdown.Item href="#/action-1">
                        Explore all Classroom Courses
                      </Dropdown.Item>
                    </Link>
                    <Link to="/course">
                      <Dropdown.Item href="#/action-2">
                        Explore all Live Interactive Courses
                      </Dropdown.Item>
                    </Link>
                    <Dropdown.Item href="#/action-3">
                      Explore all Online Guided Learning Courses
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={freeTrialModalVisible}
        centered
        onHide={toggleFreeTrialModal}
      >
        <div className="rounded-3 px-4 bg_darkblue position-relative pb-3">
          <div className="position-absolute top-0 end-0">
            <img
              onClick={toggleFreeTrialModal}
              width={20}
              className="me-3 mt-3 cursor_pointer"
              src={crosswhtie_icon}
              alt="Close"
            />
          </div>
          <Modal.Header className="border-0 text-white pb-0 pt-5">
            <Modal.Title>
              <h3 className="mb-0 fs_29 ff_heebo fw-bolder text-white">
                Free Trial Registration
              </h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form action="" onSubmit={createForm}>
              <div className="d-flex flex-column mt-4">
                <label className="text-white ff_heebo" htmlFor="Name">
                  Name
                </label>
                <input
                  required
                  type="text"
                  className="py-2 px-3 mt-2"
                  placeholder="Enter Name"
                  value={formData.name}
                  onChange={handleInputChange}
                  name="name"
                />
              </div>
              <div className="d-flex flex-column mt-4">
                <label className="text-white ff_heebo" htmlFor="Name">
                  Enter Your Mobile Number
                </label>
                <input
                  required
                  type="number"
                  className="py-2 px-3 mt-2"
                  placeholder="Enter Mobile Number"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                />
              </div>
              <div className="d-flex flex-column mt-4">
                <label className="text-white ff_heebo" htmlFor="Name">
                  Email
                </label>
                <input
                  required
                  type="email"
                  className="py-2 px-3 mt-2"
                  placeholder="Enter Email"
                  value={formData.email}
                  name="email"
                  onChange={handleInputChange}
                />
              </div>
              <div className="d-flex flex-column mt-4">
                <label className="text-white ff_heebo" htmlFor="Name">
                  City
                </label>
                <input
                  required
                  type="text"
                  className="py-2 px-3 mt-2"
                  placeholder="Enter City"
                  value={formData.topic}
                  name="topic"
                  onChange={handleInputChange}
                />
              </div>
              <div className="d-flex flex-column mt-4">
                <label className="text-white ff_heebo" htmlFor="Name">
                  Nearest Center
                </label>
                <select
                  required
                  value={formData.nearestCenter}
                  name="nearestCenter"
                  onChange={handleInputChange}
                  className="py-2 px-3 mt-2"
                  id="nearestCenter"
                >
                  <option value=" -- Select Nearest Center --">
                    -- Select Nearest Center --
                  </option>
                  <option value="Hisar">Hisar</option>
                  <option value="Rohtak">Rohtak</option>
                  <option value="Sirsa">Sirsa</option>
                  <option value="Sikar">Sikar</option>
                </select>
              </div>
              <div className="d-flex flex-column mt-4">
                <label className="text-white ff_heebo" htmlFor="Name">
                  Qualification:
                </label>
                <select
                  required
                  value={formData.qualification}
                  name="qualification"
                  onChange={handleInputChange}
                  className="py-2 px-3 mt-2"
                  id=""
                >
                  <option value=" -- Select Qualification --">
                    -- Select Qualification --
                  </option>
                  <option value="10th">10th</option>
                  <option value="11th">11th</option>
                  <option value="12th">12th</option>
                  <option value="Graduation">Graduation</option>
                  <option value="Post Graduation">Post Graduation</option>
                </select>
              </div>
              <div className="d-flex flex-column mt-4">
                <label className="text-white ff_heebo" htmlFor="Name">
                  Which course are you looking for?
                </label>
                <select
                  required
                  value={formData.course}
                  name="course"
                  onChange={handleInputChange}
                  className="py-2 px-3 mt-2"
                  id=""
                >
                  <option value=" -- Select Course --">
                    -- Select Course --
                  </option>
                  <option value="Frontend Development">
                    Frontend Development
                  </option>
                  <option value="Backend Development">
                    Backend Development
                  </option>
                  <option value="FullStack Development">
                    FullStack Development
                  </option>
                  <option value="Web Development">Web Development</option>
                  <option value="Mobile App Development">
                    Mobile App Development
                  </option>
                </select>
              </div>
              <button
                type="submit"
                className="mt-5 bg_yellow ff_heebo fs_19 border-0 text-white w-100 py-2"
                disabled={isLoading}
              >
                {isLoading ? <Spinner /> : "Request Callback"}
              </button>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default CodingCourses;
