import React, { useCallback, useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Navbar,
  Nav,
  Offcanvas,
  Container,
  Button,
  Modal,
  Dropdown,
} from "react-bootstrap";
import logo from "../../assets/images/png/logo1.png";
import { Form } from "react-router-dom";
import ReactQuill from "react-quill";
import { AppContext } from "../../context/Context";
import makeApiCall from "../callApi";

import crossblack_icon from "../../assets/images/png/blackcross_icon.png";
import CourseTab from "./CourseTab";
import BlogTab from "./BlogTab";

const AdminPanel = () => {
  const [activeTab, setActiveTab] = useState("courses");
  const [showSidebar, setShowSidebar] = useState(false);
  const context = useContext(AppContext);
  const { setAuth } = context;
  // const [addCourseModal, setAddCourseModal] = useState(false);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    setShowSidebar(false);
  };

  function logout() {
    localStorage.removeItem("admtoken");
    setAuth(false);
  }

  return (
    <div className="container-fluid px-0 overflow-x-hidden min-vh-100">
      {/* Navigation for small screens */}
      <Navbar variant="dark" expand="md" className="d-md-none bg_darkblue">
        <Container>
          <Navbar.Brand href="#">
            <img width={100} src={logo} alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="offcanvas-nav"
            onClick={() => setShowSidebar(!showSidebar)}
          />
          <Navbar.Offcanvas
            className="bg_darkblue"
            id="offcanvas-nav"
            aria-labelledby="offcanvas-nav-label"
            placement="start"
            show={showSidebar}
            onHide={() => setShowSidebar(false)}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvas-nav-label">
                s
                <img width={100} src={logo} alt="logo" />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <ul className="nav flex-column">
                <li
                  className={`nav-item py-1 px-3 mt-3 ${
                    activeTab === "courses" ? "custom_bg" : ""
                  }`}
                  onClick={() => handleTabClick("courses")}
                >
                  <span className="text-white ff_heebo fw-medium cursor_pointer">
                    Courses
                  </span>
                </li>
                <li
                  className={`nav-item py-1 px-3 mt-3 ${
                    activeTab === "blogs" ? "custom_bg" : ""
                  }`}
                  onClick={() => handleTabClick("blogs")}
                >
                  <span className="text-white ff_heebo fw-medium cursor_pointer">
                    Blogs
                  </span>
                </li>
              </ul>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>

      {/* Desktop layout */}
      <div className="row">
        <div
          className="col-xl-2  col-md-3 d-none d-md-block py-4 px-0 bg_darkblue min-vh-100"
          style={{ height: "100vh", overflow: "hidden" }}
        >
          <div className="d-flex flex-column justify-content-between h-100">
            <div>
              <a className="px-4" href="#">
                <img className="mb-1" width={100} src={logo} alt="logo" />
              </a>
              <div className="border mt-4"></div>
              <ul className="nav flex-column mt-2 px-3">
                <li
                  className={`nav-item py-1 px-3 mt-3 ${
                    activeTab === "courses" ? "custom_bg" : ""
                  }`}
                  onClick={() => handleTabClick("courses")}
                >
                  <span className="text-white ff_heebo fw-medium cursor_pointer">
                    Courses
                  </span>
                </li>
                <li
                  className={`nav-item py-1 px-3 mt-3 ${
                    activeTab === "blogs" ? "custom_bg" : ""
                  }`}
                  onClick={() => handleTabClick("blogs")}
                >
                  <span className="text-white ff_heebo fw-medium cursor_pointer">
                    Blogs
                  </span>
                </li>
              </ul>
            </div>
            <div className="ps-4">
              <p
                className="ps-2 cursor_pointer ff_heebo mt-3 fw-semibold py-1 text-white border-0 "
                onClick={logout}
              >
                Logout
              </p>
            </div>
          </div>
        </div>

        <div
          className="col-xl-10 overflow-y-auto col-md-9 p-md-3 px-sm-5 px-4 pt-4 min-vh-100"
          style={{ height: "100vh" }}
        >
          {activeTab === "courses" && (
            <>
              <CourseTab />
            </>
          )}

          {activeTab === "blogs" && (
            <div>
              <BlogTab />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
