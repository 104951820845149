import React from "react";
import icon from "../../assets/images/png/Features_icon.png";

const OurStudents = () => {
  return (
    <div className="py-5">
      <div className="container">
        <h3 className=" fs_37 fw-bold ff_heebo color_blue">
          <span className=" color_yellow">Our students can fit the </span>
          following roles
        </h3>
        <div className="row mt-4">
          <div className="col-lg-3 col-md-4 col-6 mt-4">
            <div className="border border-2 d-flex align-items-center gap-3 h-100 cursor_pointer p-4 rounded-2">
              <img width={45} src={icon} alt="icon" />
              <p className="fs_22 fw-medium ff_heebo color_darkgray mb-0">
                Front-End Developer
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6 mt-4">
            <div className="border border-2 d-flex align-items-center gap-3 h-100 cursor_pointer p-4 rounded-2">
              <img width={45} src={icon} alt="icon" />
              <p className="fs_22 fw-medium ff_heebo color_darkgray mb-0">
                Back-End Developer
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6 mt-4">
            <div className="border border-2 d-flex align-items-center gap-3 h-100 cursor_pointer p-4 rounded-2">
              <img width={45} src={icon} alt="icon" />
              <p className="fs_22 fw-medium ff_heebo color_darkgray mb-0">
                Full-Stack Developer
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6 mt-4">
            <div className="border border-2 d-flex align-items-center gap-3 h-100 cursor_pointer p-4 rounded-2">
              <img width={45} src={icon} alt="icon" />
              <p className="fs_22 fw-medium ff_heebo color_darkgray mb-0">
                Mobile Application Developer
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6 mt-4">
            <div className="border border-2 d-flex align-items-center gap-3 h-100 cursor_pointer p-4 rounded-2">
              <img width={45} src={icon} alt="icon" />
              <p className="fs_22 fw-medium ff_heebo color_darkgray mb-0">
                Software Engineer
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6 mt-4">
            <div className="border border-2 d-flex align-items-center gap-3 h-100 cursor_pointer p-4 rounded-2">
              <img width={45} src={icon} alt="icon" />
              <p className="fs_22 fw-medium ff_heebo color_darkgray mb-0">
                DevOps Engineer
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6 mt-4">
            <div className="border border-2 d-flex align-items-center gap-3 h-100 cursor_pointer p-4 rounded-2">
              <img width={45} src={icon} alt="icon" />
              <p className="fs_22 fw-medium ff_heebo color_darkgray mb-0">
                UI/UX Designer
              </p>
            </div>
          </div>
          {/* <div className="col-lg-3 col-md-4 col-6 mt-4">
            <div className="border border-2 d-flex align-items-center gap-3 h-100 cursor_pointer p-4 rounded-2">
              <img src={icon} alt="icon" />
              <p className="fs_22 fw-medium ff_heebo color_darkgray mb-0">
                SDE
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6 mt-4">
            <div className="border border-2 d-flex align-items-center gap-3 h-100 cursor_pointer p-4 rounded-2">
              <img src={icon} alt="icon" />
              <p className="fs_22 fw-medium ff_heebo color_darkgray mb-0">
                SDE
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6 mt-4">
            <div className="border border-2 d-flex align-items-center gap-3 h-100 cursor_pointer p-4 rounded-2">
              <img src={icon} alt="icon" />
              <p className="fs_22 fw-medium ff_heebo color_darkgray mb-0">
                SDE
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default OurStudents;
