import React, { useCallback, useEffect, useState } from "react";
import makeApiCall from "../callApi";
import ReactQuill from "react-quill";
import crossblack_icon from "../../assets/images/png/blackcross_icon.png";
import edit_icon from "../../assets/images/png/edit_icon.png";
import delete_icon from "../../assets/images/png/delete_icon.png";
import { Modal } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const BlogTab = () => {
  const [addBlogModal, setAddBlogModal] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [blogData, setBlogData] = useState({
    title: "",
    image: "",
    description: "",
  });
  const [newblogData, setNewBlogData] = useState({ title: "" });
  const [value, setValue] = useState("");
  const [newValue, setNewValue] = useState("");

  useEffect(() => {
    setNewBlogData({
      id: currentData?._id,
      title: currentData?.title,
      image: currentData?.image,
      description: currentData?.description,
    });
    setNewValue(currentData?.description);
  }, [currentData]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setBlogData((prevData) => ({
        ...prevData,
        [name]: files?.length > 0 ? files[0] : value,
      }));
    } else {
      setBlogData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const handleInputChange2 = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setNewBlogData((prevData) => ({
        ...prevData,
        [name]: files?.length > 0 ? files[0] : value,
      }));
    } else {
      setNewBlogData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    console.log(newblogData);
  };

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video", "button"],
      ["clean"],
      ["code-block"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  async function createBlog(e) {
    e.preventDefault();
    // const formdata = {
    //   title: blogData.title,
    //   description: value,
    // };
    const formdata = new FormData();
    formdata.append("title", blogData.title);
    formdata.append("image", blogData.image);
    formdata.append("description", value);

    await makeApiCall(
      formdata,
      "post",
      "admin/updateBlog",
      createblogCallBack,
      false
    );
  }

  const createblogCallBack = useCallback(async (res) => {
    console.log(res);
    if (res.data.status == "success") {
      setAddBlogModal(false);
      getBLogs();
    }
  });
  async function updateBlog(e) {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("id", newblogData.id);
    formdata.append("title", newblogData.title);
    formdata.append("image", newblogData.image);
    formdata.append("description", newValue);
    // const formdata = {
    //   id: newblogData.id,
    //   title: newblogData.title,
    //   description: newValue,
    // };
    await makeApiCall(
      formdata,
      "post",
      "admin/updateBlog",
      updateblogCallBack,
      false
    );
  }

  const updateblogCallBack = useCallback(async (res) => {
    console.log(res);
    if (res.data.status == "success") {
      setFreeTrialModalVisible(false);
      getBLogs();
    }
  });

  useEffect(() => {
    console.log(blogData);
  }, [blogData]);

  useEffect(() => {
    console.log(value);
  }, [value]);

  const [blogList, SetblogList] = useState([]);

  async function getBLogs() {
    await makeApiCall("", "get", "admin/blogList", blogListCallBack);
  }
  useEffect(() => {
    getBLogs();
  }, []);

  const blogListCallBack = useCallback((response) => {
    console.log(response);
    // setMainLoading(false);
    if (response.status === 200) {
      SetblogList(response.data.data);
    } else {
      console.log("error");
    }
  });

  async function deleteCourse(id) {
    await makeApiCall(
      "",
      "get",
      `admin/deleteBlog/${id}`,
      coursesDeleteCallBack
    );
  }

  const coursesDeleteCallBack = useCallback(
    (response) => {
      console.log(response);
      if (response.status === 200) {
        console.log("Course deleted successfully");
        getBLogs();
      } else {
        console.error("Error deleting course");
      }
    },
    [getBLogs]
  );
  useEffect(() => {
    getBLogs();
  }, []);

  const [freeTrialModalVisible, setFreeTrialModalVisible] = useState(false);
  const toggleFreeTrialModal = () => {
    setFreeTrialModalVisible(!freeTrialModalVisible);
  };

  const handleResizeError = (e) => {
    if (e.message.includes("ResizeObserver")) {
      console.error("ResizeObserver error suppressed:", e);
      return;
    }
    throw e;
  };

  window.addEventListener("error", handleResizeError);

  return (
    <div>
      <h2 className=" fs_37 fw-semibold ff_heebo">Blogs</h2>
      <div className="border my-4"></div>

      {!addBlogModal ? (
        <>
          <div className=" mb-4">
            <button
              onClick={() => setAddBlogModal(true)}
              className=" bg_yellow py-2 px-4 border-0 ff_heebo text-white fw-bold"
            >
              ADD NEW BLOG
            </button>
          </div>
          <div className="  border-0 ff_heebo pb-4">
            {/* <div
              className="card rounded-0"
              style={{ maxWidth: "1200px", overflowX: "auto" }}
            >
              <table> */}
            <div
              className="card rounded-3"
              style={{ maxWidth: "1300px", overflowX: "auto" }}
            >
              <table className="w-100">
                <tr>
                  <th className="py-3 text-white ff_heebo  px-3 bg_darkblue">
                    Image
                  </th>
                  <th className="py-3 text-white ff_heebo  px-3 bg_darkblue">
                    Title
                  </th>
                  {/* <th className="py-3 text-white ff_heebo  px-3 bg_darkblue">
                    Description
                  </th> */}

                  <th className="py-3 text-white ff_heebo  px-3 bg_darkblue">
                    Action
                  </th>
                </tr>
                {blogList?.length < 1 ? (
                  <div className="text-start ps-3 py-4">No data found</div>
                ) : (
                  <>
                    {/* Iterate over coursesList if it has items */}
                    {blogList?.map((obj, i) => {
                      return (
                        <>
                          <tr key={i}>
                            <td className="px-3 py-3">
                              <img
                                width={70}
                                src={`https://api.koduapp.com/public/images/${obj.image}`}
                                alt="hello"
                              />
                            </td>
                            <td className="px-3 py-3">{obj.title}</td>
                            {/* <td className="px-3 py-3">{obj.description}</td> */}
                            <td className="px-3 py-3">
                              <div className="d-flex gap-3 bg-white">
                                <img
                                  onClick={() => {
                                    toggleFreeTrialModal();
                                    setCurrentData(obj);
                                  }}
                                  width={20}
                                  className=" cursor_pointer"
                                  src={edit_icon}
                                  alt="hello"
                                />
                                <img
                                  onClick={() => deleteCourse(obj._id)}
                                  width={20}
                                  className=" cursor_pointer"
                                  src={delete_icon}
                                  alt="hello"
                                />
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </>
                )}
              </table>
            </div>
            {/* </table>
            </div> */}
          </div>
        </>
      ) : (
        <>
          <div className="card rounded-0 px-5 py-4 position-relative">
            <div className="position-absolute top-0 end-0">
              <img
                onClick={() => false}
                width={20}
                className="me-3 mt-3 cursor_pointer"
                src={crossblack_icon}
                alt="Close"
              />
            </div>
            <div>
              <form onSubmit={createBlog}>
                <div className="d-flex w-100 flex-column col-3 mt-4">
                  <lable>Title</lable>
                  <input
                    className="custom_admin_input mt-2  px-3 py-2 w-100"
                    type="text"
                    name="title"
                    value={blogData.title}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="d-flex flex-column w-100 mt-4">
                  <lable>Image</lable>
                  <input
                    required
                    className="custom_admin_input mt-2  px-3 py-2 w-100"
                    type="file"
                    name="image"
                    onChange={handleInputChange}
                  />
                </div>
                <div className="d-flex w-100 flex-column col-3 mt-4">
                  <lable className="mb-2">Description</lable>
                  {/* <ReactQuill
                    theme="snow"
                    placeholder="Add Description"
                    modules={modules}
                    value={value}
                    onChange={setValue}
                  /> */}
                  <CKEditor
                    editor={ClassicEditor}
                    value={value}
                    onChange={(e, ed) => {
                      const data = ed.getData();
                      setValue(data);
                    }}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      console.log("Editor is ready to use!", editor);
                    }}
                  />
                </div>
                <span>
                  <button
                    className="mt-5 border-0 bg_darkblue px-5 py-2 roundd-2 fs_16 ff_heebo fw-medium text-white"
                    type="submit"
                  >
                    Submit
                  </button>
                </span>
              </form>
            </div>
          </div>
        </>
      )}
      <Modal
        show={freeTrialModalVisible}
        centered
        onHide={toggleFreeTrialModal}
      >
        <div
          style={{ width: "700px", margin: "0 auto" }}
          className="bg-white rounded-3 "
        >
          <div className="position-absolute top-0 end-0">
            <img
              onClick={toggleFreeTrialModal}
              width={20}
              className="me-2 mt-2 cursor_pointer"
              src={crossblack_icon}
              alt="Close"
            />
          </div>

          <Modal.Body>
            <form className="mt-4" onSubmit={updateBlog}>
              <div className="d-flex w-100 flex-column col-3 mt-4">
                <lable>Title</lable>
                <input
                  className="custom_admin_input mt-2  px-3 py-2 w-100"
                  type="text"
                  name="title"
                  value={newblogData?.title}
                  onChange={handleInputChange2}
                />
              </div>
              <div className="d-flex  flex-column  mt-4">
                <lable>Image</lable>
                <input
                  className="custom_admin_input mt-2  px-3 py-2 w-100"
                  type="file"
                  name="image"
                  onChange={handleInputChange2}
                />
              </div>
              <div className="d-flex w-100 flex-column  mt-4">
                <lable className="mb-2">Description</lable>
                {/* <ReactQuill
                  theme="snow"
                  placeholder="Add Description"
                  modules={modules}
                  value={newValue}
                  onChange={setNewValue}
                /> */}
                <CKEditor
                  editor={ClassicEditor}
                  data={newValue}
                  onChange={(e, ed) => {
                    const data = ed.getData();
                    setNewValue(data);
                  }}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                />
              </div>
              <span>
                <button
                  className="mt-5 border-0 bg_darkblue px-5 py-2 roundd-2 fs_16 ff_heebo fw-medium text-white"
                  type="submit"
                >
                  Submit
                </button>
              </span>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default BlogTab;
