import React from "react";
import loction_icon from "../../assets/images/png/loction_icon.png";
import map_img from "../../assets/images/webp/map_image.webp";
import image1 from "../../assets/images/png/image1.png";
import logo from "../../assets/images/png/logo1.png";
import Slider from "react-slick";
import Navbar from "../common/Navbar";

const SikarCenter = () => {
  window.scrollTo(0, 0);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };
  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div className="container py-5">
        <div className="OurCentres  p-md-5 p-3 rounded-2">
          <div className="d-lg-flex align-items-center">
            <div className="bg_yellow py-3 px-4 rounded-3 text-center">
              <img width={40} src={loction_icon} alt="loction_icon" />
              <h5 className="mt-3 text-white fs_40">Sikar</h5>
            </div>

            <div className=" ps-lg-4 mt-4 mt-lg-0">
              <button className=" bg_darkblue text-white ff_heebo py-1 px-5 rorder-2  border-0 rounded-2">
                Our Centres
              </button>
              <h5 className="mt-3 mb-0 ff_hebbo fs_24 color_darkgray">
                📍 2nd Floor, Laxman Plaza Near Rominus Pizza, Piprali Road,
                Sikar, Rajasthan 332001
              </h5>
            </div>
          </div>
          <div className="row custom_bottom_padding_space mt-5">
            <div className="col-md-4 p-4 bg-white custom-border">
              <h5 className="fs_25 ff_heebo color_darkgrayx fw-bold">
                ADDRESS
              </h5>
              <p className=" fs_25 ff_heebo mb-0">
                📍 2nd Floor, Laxman Plaza Near Rominus Pizza, Piprali Road,
                Sikar, Rajasthan 332001
              </p>
              <button className="fs_12 text-white bg_darkblue w-100 border-0 py-2  mt-4 rounded-2">
                Get Direction On Google Map
              </button>
            </div>
            <div className="col-md-8 bg_darkblue p-4">
              <div className="row">
                <div className="col-lg-6 ">
                  <h5 className="fs_25 ff_heebo text-white fw-bold">
                    LOCATION
                  </h5>
                  <div>
                    <iframe
                      height={290}
                      className="w-100 rounded-4"
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14139.667114024682!2d75.1764396!3d27.627094!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396cbbdb6afb21fb%3A0x5bcac0429ab0134d!2sKodu!5e0!3m2!1sen!2sin!4v1717741023143!5m2!1sen!2sin"
                      width="600"
                      allowFullscreen=""
                      loading="lazy"
                      reFerrerPolicy="no-referrer-when-downgrade"
                    />
                  </div>
                  <button className="bg_yellow w-100 border-0 mt-2 fs_12 ff_heebo text-white py-2 rounded-1">
                    Get Direction On Google Map
                  </button>
                </div>
                <div className="col-lg-6 mt-4 mt-lg-0">
                  <h5 className="fs_25 ff_heebo text-white fw-bold">
                    INSTITUTE
                  </h5>
                  <img
                    height={290}
                    className="w-100"
                    src={map_img}
                    alt="map_img"
                  />
                  <div className="d-flex flex-wrap mt-2 gap-3 align-items-center">
                    <img width={55} height={41} src={image1} alt="image1" />
                    <img width={55} height={41} src={image1} alt="image1" />
                    <img width={55} height={41} src={image1} alt="image1" />
                    <img width={55} height={41} src={image1} alt="image1" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=" px-md-5 custom_top_space">
          <div className="py-3 overflow-hidden centers_dotes">
            <Slider {...settings}>
              <>
                <div className="p-4">
                  <div className="slider_box rounded-3 p-4">
                    <div className="d-flex justify-content-between  align-items-center">
                      <h4 className="mb-0 color_blue">Sikar</h4>
                      <img width={100} src={logo} alt="hello" />
                    </div>
                    <img className="w-100 mt-3" src={map_img} alt="map_img" />
                    <div className="d-flex flex-wrap mt-2 gap-3 align-items-center">
                      <img width={55} height={41} src={image1} alt="image1" />
                      <img width={55} height={41} src={image1} alt="image1" />
                      <img width={55} height={41} src={image1} alt="image1" />
                      <p className="mb-0 ff_heebo fw-semibold">7 More</p>
                    </div>

                    <h6 className=" ff_heebo mb-0  color_darkgray mt-4">
                      Full Stack Development
                    </h6>
                    <p className=" ff_heebo color_darkgray mt-2 fs_14">
                      Comprehensive training in Full Stack Development with 100%
                      placement assurance.
                    </p>
                    <button className=" mt-4 w-100 Free_btn border-0 text-white ff_heebo fw-semibold">
                      Explore Now
                    </button>
                  </div>
                </div>{" "}
                <div className=" p-4">
                  <div className="slider_box rounded-3 p-4">
                    <div className="d-flex justify-content-between  align-items-center">
                      <h4 className="mb-0 color_blue">Sikar</h4>
                      <img width={100} src={logo} alt="hello" />
                    </div>{" "}
                    <img className="w-100 mt-3" src={map_img} alt="map_img" />
                    <div className="d-flex flex-wrap mt-2 gap-3 align-items-center">
                      <img width={55} height={41} src={image1} alt="image1" />
                      <img width={55} height={41} src={image1} alt="image1" />
                      <img width={55} height={41} src={image1} alt="image1" />
                      <p className="mb-0 ff_heebo fw-semibold">7 More</p>
                    </div>
                    <h6 className=" ff_heebo mb-0  color_darkgray mt-4">
                      App Development
                    </h6>
                    <p className=" ff_heebo color_darkgray mt-2 fs_14">
                      Master app development with industry-relevant skills and
                      100% placement assurance.
                    </p>
                    <button className=" mt-4 w-100 Free_btn border-0 text-white ff_heebo fw-semibold">
                      Explore Now
                    </button>
                  </div>
                </div>{" "}
                <div className="p-4">
                  <div className="slider_box rounded-3 p-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <h4 className="mb-0 color_blue">Sikar</h4>
                      <img width={100} src={logo} alt="hello" />
                    </div>{" "}
                    <img className="w-100 mt-3" src={map_img} alt="map_img" />
                    <div className="d-flex flex-wrap mt-2 gap-3 align-items-center">
                      <img width={55} height={41} src={image1} alt="image1" />
                      <img width={55} height={41} src={image1} alt="image1" />
                      <img width={55} height={41} src={image1} alt="image1" />
                      <p className="mb-0 ff_heebo fw-semibold">7 More</p>
                    </div>
                    <h6 className=" ff_heebo mb-0  color_darkgray mt-4">
                      Web Development
                    </h6>
                    <p className=" ff_heebo color_darkgray mt-2 fs_14">
                      Learn web development from scratch with 100% placement
                      assurance.
                    </p>
                    <button className=" mt-4 w-100 Free_btn border-0 text-white ff_heebo fw-semibold">
                      Explore Now
                    </button>
                  </div>
                </div>
              </>
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SikarCenter;
