import React, { useEffect } from "react";
import BlogHero from "../blogPage/BlogHero";
import BlogFaq from "../blogPage/BlogFaq";

const BlogPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <BlogHero />
      <BlogFaq />
    </div>
  );
};

export default BlogPage;
